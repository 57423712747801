import { CustomField } from "./MemberCustomFields";
import React from "react";
import { useApi } from "../../hooks/api";
import { useForm } from "react-hook-form";

interface Props {
  customField: CustomField;
  customFieldsUrl: string;
  commitEditing: (customField: CustomField) => void;
  exitEditing: () => void;
  showMessage: (message: string) => void;
}

interface MemberCustomFieldItemParams {
  value: string;
  custom_field_id: number;
  custom_field_value_id: number;
  custom_field_option_id: number;
}

interface MemberCustomFieldItemFormResponse {
  custom_field?: CustomField;
  message?: string;
  response?: Response;
}

export default function MemberCustomFieldItemForm(props: Props): JSX.Element {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<MemberCustomFieldItemParams>({
    defaultValues: {
      value: props.customField.value,
      custom_field_id: props.customField.custom_field_id,
      custom_field_value_id: props.customField.custom_field_value_id,
      custom_field_option_id: props.customField.custom_field_option_id,
    },
    mode: "all",
  });
  const { patch } = useApi();

  const onSubmit = async (data) => {
    const result = await patch<MemberCustomFieldItemFormResponse>(
      props.customFieldsUrl,
      {
        custom_field: data,
      },
    );
    if (result.custom_field) {
      props.commitEditing(result.custom_field);
    }
    props.showMessage(result.message);
  };

  return (
    <>
      <dl className="custom-field__item">
        <dt>{props.customField.name}</dt>
        <dd>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="custom-field__form"
          >
            <input {...register("custom_field_id")} type="hidden" />
            <input {...register("custom_field_value_id")} type="hidden" />
            <input {...register("custom_field_option_id")} type="hidden" />

            {props.customField.field_type === "free_text" && (
              <div className="user__data-description__container">
                <span className="user__data-description__field">
                  <input
                    {...register("value", {
                      maxLength: {
                        value: 255,
                        message: "255文字以内で入力してください",
                      },
                    })}
                    className="_input"
                  />
                </span>
                {errors.value && (
                  <div className="form__error" aria-live="polite">
                    {errors.value.message}
                  </div>
                )}
              </div>
            )}

            {props.customField.field_type === "single_select" && (
              <div className="radiofield --layout-stack">
                {props.customField.options.map((option) => (
                  <div key={option.id}>
                    <label
                      htmlFor={`value-${props.customField.custom_field_id}-${option.option_value}`}
                      className="_item"
                    >
                      <input
                        type="radio"
                        {...register("value", {
                          required: "選択してください",
                          maxLength: {
                            value: 255,
                            message: "255文字以内で入力してください",
                          },
                        })}
                        value={option.option_value}
                        id={`value-${props.customField.custom_field_id}-${option.option_value}`}
                        className="radio"
                        onChange={(event) => {
                          setValue("value", event.target.value);
                          setValue("custom_field_option_id", option.id);
                        }}
                      />
                      <span className="_label">
                        {option.option_value}
                      </span>
                    </label>
                  </div>
                ))}
                {errors.value && (
                  <div className="form__error" aria-live="polite">
                    {errors.value.message}
                  </div>
                )}
              </div>
            )}
            <div className="_control">
              <button
                type="button"
                className="button -size-s"
                onClick={props.exitEditing}
              >
                <div className="_body">
                  キャンセル
                </div>
              </button>
              <button
                type="submit"
                className="button -size-s -color-interactive"
                disabled={isSubmitting}
              >
                <div className="_body">
                  保存
                </div>
              </button>
            </div>
          </form>
        </dd>
      </dl>
    </>
  );
}
