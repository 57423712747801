import React, { useState } from "react";
import Calendar from "components/Schedules/Calendar";
import EventList from "components/Schedules/EventList";
import EventListEmpty from "components/Schedules/EventListEmpty";

import { format, isSameDay } from "date-fns";

export default function Schedules(
  {
    card_links,
    classroom_icons,
    create_event_path,
    events,
    is_management,
    person_avatars,
  },
): JSX.Element {
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(today);

  const activeEvents = events.filter((event) =>
    isSameDay(new Date(event.starts_at), selectedDate)
  );

  const onActiveStartDateChange = ({ action, activeStartDate, value }) => {
    if (action == "onChange") {
      setSelectedDate(new Date(value));
    } else {
      setSelectedDate(new Date(activeStartDate));
    }
  };

  const onClickDay = (value, e) => {
    setSelectedDate(new Date(value));
  };

  const onClickToday = (e) => {
    e.stopPropagation();

    setSelectedDate(today);
  };

  return (
    <div>
      <Calendar
        events={events}
        onActiveStartDateChange={onActiveStartDateChange}
        onClickDay={onClickDay}
        onClickToday={onClickToday}
        selectedDate={selectedDate}
      />

      {activeEvents.length > 0
        ? (
          <EventList
            activeEvents={activeEvents}
            card_links={card_links}
            classroom_icons={classroom_icons}
            is_management={is_management}
            person_avatars={person_avatars}
            selectedDate={selectedDate}
          />
        )
        : <EventListEmpty is_management={is_management} />}

      {is_management > 0
        ? (
          <div className="fab-button">
            <div className="fab-button__wrapper">
              <div className="fab-button__container">
                <a
                  href={create_event_path +
                    `?selected_date=${format(selectedDate, "yyyy-MM-dd")}`}
                  className="fab-button__action"
                  aria-label="予定を作成"
                >
                  <div className="_leading">
                    <span className="icon" data-icon="plus"></span>
                  </div>

                  <div className="_body">予定を作成</div>
                </a>
              </div>
            </div>
          </div>
        )
        : null}
    </div>
  );
}
