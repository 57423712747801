import React from "react";
import { isBefore, parseISO, startOfDay } from "date-fns";
import { UseFormRegister } from "react-hook-form";
import { timeOptions } from "../../utils/selectable-time-options";
import { AnnouncementFormInputs } from "./index";
import { FieldErrors } from "react-hook-form/dist/types/errors";

interface Props {
  isScheduledIssue: boolean;
  currentIssuedDate: string;
  register: UseFormRegister<AnnouncementFormInputs>;
  errors: FieldErrors<AnnouncementFormInputs>;
  onSchedulingChange: (isChecked: boolean) => void;
}

export default function AnnouncementFormIssuedDateSection({
  isScheduledIssue,
  currentIssuedDate,
  register,
  errors,
  onSchedulingChange,
}: Props) {
  return (
    <div className="form__item">
      {isScheduledIssue && (
        <>
          <div className="textfield-group -no-margin">
            <div className="textfield-group__item -flex-grow">
              <span className="simple-textfield -size-l -appearance-filled -width-full">
                <input
                  type="date"
                  id="issued_date"
                  {...register("issued_date", {
                    required: "送信日を入力してください",
                    validate: (value) => {
                      if (
                        isBefore(parseISO(value), startOfDay(new Date()))
                      ) {
                        return "本日以降の日付を入力してください";
                      }
                      return true;
                    },
                  })}
                  className="_input"
                />
              </span>
            </div>
            <div className="textfield-group__item -flex-grow">
              <span className="simple-textfield -size-l -appearance-filled -width-full">
                <input
                  type="time"
                  id="issued_time"
                  {...register("issued_time", {
                    required: "送信時刻を入力してください",
                    validate: (value) => {
                      // currentIssuedDate と value を結合して、Date オブジェクトに変換
                      const date = parseISO(
                        `${currentIssuedDate}T${value}`,
                      );
                      if (
                        isBefore(date, new Date())
                      ) {
                        return "未来の時間を入力してください";
                      }
                      return true;
                    },
                  })}
                  className="_input"
                  list="issued_at-time-list"
                />
                <datalist id="issued_at-time-list">
                  {timeOptions.map((time) => (
                    <option
                      key={time}
                      value={time}
                    />
                  ))}
                </datalist>
              </span>
            </div>
          </div>
          <div className="form__error" aria-live="polite">
            {errors.issued_date?.message}
          </div>
          <div className="form__error" aria-live="polite">
            {errors.issued_time?.message}
          </div>
        </>
      )}
      <div className="form__control">
        <div className="checkfield -align-center">
          <label className="_item" htmlFor="issue-reservation">
            <input
              type="checkbox"
              checked={isScheduledIssue}
              onChange={(e) => onSchedulingChange(e.target.checked)}
              id="issue-reservation"
              className="checkbox"
            />
            <span className="_label">
              日時を指定
            </span>
          </label>
        </div>
      </div>
    </div>
  );
}
