import React from "react";
import { ModalBackButton } from "../ModalBackButton";
import Tabs from "components/ManagementClassroomSettings/Tabs";

export default function Header(
  {
    activeTab,
    classroom,
    setActiveTab,
  },
): JSX.Element {
  return (
    <header className="modal-header" style={{ height: "auto" }}>
      <h1 className="_title">
        教室設定｜{classroom.name}
      </h1>

      <div className="_container box-shadow">
        <div className="_nav">
          <ModalBackButton
            backButtonLinkPath={classroom.backButtonLinkPath}
          />
        </div>
      </div>

      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        classroom={classroom}
      />
    </header>
  );
}
