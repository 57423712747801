import React from "react";
import { useForm } from "react-hook-form";

export type Errors = string[] | null;

interface Props {
  errors: Errors;
}

export default function ErrorBanner({ errors }: Props): JSX.Element {
  if (!errors || errors.length === 0) {
    return <></>;
  }

  return (
    <div className="error-banner">
      <div className="error-banner__leading">
        <span
          className="error-banner__icon icon"
          data-icon="exclamation_on_triangle"
        >
        </span>
      </div>
      <div className="error-banner__body">
        <div className="error-banner__title">
          入力した内容に{errors.length}件の不備がありました
        </div>
        <ul className="error-banner__list">
          {errors.map((error, index) => (
            <li key={index} className="error-banner__item">
              {error}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
