import React from "react";
import { numberFormat } from "utils/number-format";

interface Student {
  id: number;
  name: string;
  name_kana: string;
  url: string;
}

export interface EventGroup {
  id: number;
  name: string;
}

interface BillGroup {
  id: number;
  name: string;
  amount: number;
}

export interface Report {
  student: Student;
  enrolled_event_groups: EventGroup[];
  prev_month: {
    total_billing_amount: number;
    bill_groups: BillGroup[];
  };
  current_month: {
    total_billing_amount: number;
    bill_groups: BillGroup[];
  };
  next_month: {
    total_billing_amount: number;
    bill_groups: BillGroup[];
  };
}

interface Props {
  report: Report;
}

export default function TableRow({ report }: Props): JSX.Element {
  return (
    <tr>
      <td className="-align-start" scope="row">
        <a href={report.student.url} data-turbolinks="false">
          {report.student.name}
        </a>
      </td>
      <td className="-size-s -text-wrap">
        {report.enrolled_event_groups.map((e) => e.name).join("／")}
      </td>
      <td
        className={report.prev_month.total_billing_amount == 0
          ? "-align-end --empty"
          : "-align-end"}
      >
        {numberFormat(report.prev_month.total_billing_amount)}円
      </td>
      <td
        className={report.current_month.total_billing_amount == 0
          ? "-align-end --empty"
          : "-align-end"}
      >
        {numberFormat(report.current_month.total_billing_amount)}円
      </td>
      <td
        className={report.next_month.total_billing_amount == 0
          ? "-align-end --empty"
          : "-align-end"}
      >
        {numberFormat(report.next_month.total_billing_amount)}円
      </td>
    </tr>
  );
}
