// TODO: config/locales/ja.yml から自動生成するようにするか、メリデメを洗い出して検討する
// デメリットの1つとして、フロント側に Rails の設定ファイルが依存することが挙げられる
const translations = {
  custom_fields: {
    field_type: {
      free_text: "自由記述",
      single_select: "選択",
      multi_select: "複数選択",
    },
  },
};

function getValueFromPath(obj: Record<string, unknown>, path: string) {
  let current: Record<string, unknown> | string = obj;
  const keys = path.split(".");
  keys.forEach((key) => {
    if (current[key] === undefined) {
      return "";
    }
    current = current[key];
  });
  return typeof current === "string" ? current : "";
}

type Props = string | {
  key: string;
  value: string;
};

export const localize = (props: Props, trans = translations): string => {
  if (typeof props === "string") {
    return getValueFromPath(trans, props);
  } else {
    return getValueFromPath(trans, `${props.key}.${props.value}`);
  }
};
