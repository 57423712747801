import React, { useCallback, useMemo, useState } from "react";
import TextAvatar from "./TextAvatar";

interface EventGroup {
  id: number;
  name: string;
  root: boolean;
}

interface Enrollment {
  id: number;
  student: {
    id: number;
    name: string;
  };
  event_groups: EventGroup[];
  allowed_to_settle_automatically: boolean;
}

interface Props {
  show_automatic_settlement_enabled: boolean;
  classroom_event_groups: EventGroup[];
  enrollments: Enrollment[];
  onCloseButtonClick: () => void;
  selectedStudentIds: number[];
  setSelectedStudentIds: (selectedEnrollmentIds: number[]) => void;
  selectedEventGroupIds: number[];
  setSelectedEventGroupIds: (selectedEventGroupIds: number[]) => void;
}

export default function AddresseeSelectView(
  {
    show_automatic_settlement_enabled,
    classroom_event_groups,
    enrollments,
    onCloseButtonClick,
    selectedStudentIds,
    setSelectedStudentIds,
    selectedEventGroupIds,
    setSelectedEventGroupIds,
  }: Props,
): JSX.Element {
  const [openDropdown, setOpenDropdown] = useState(false);
  const allStudentSelected = enrollments.length === selectedStudentIds.length;

  const deselectEventGroup = useCallback((eventGroup: EventGroup) => {
    setSelectedEventGroupIds(
      selectedEventGroupIds.filter((id) => {
        return id !== eventGroup.id;
      }),
    );
    const studentIds = enrollments.filter(
      (enrollment) => {
        return enrollment.event_groups.some((g) => {
          return g.id === eventGroup.id;
        });
      },
    ).map((enollment) => {
      return enollment.student.id;
    });
    setSelectedStudentIds(
      selectedStudentIds.filter((id) => {
        return !studentIds.includes(id);
      }),
    );
  }, [
    enrollments,
    selectedStudentIds,
    setSelectedStudentIds,
    selectedEventGroupIds,
    setSelectedEventGroupIds,
  ]);

  return (
    <div className="_container --visible">
      <div className="modal-header">
        <div className="_container">
          <div className="_title">
            対象を選択 <span>({selectedStudentIds.length})</span>
          </div>
          <div className="_nav">
            <button
              type="button"
              className="button -appearance-transparent -color-neutral -shape-circle"
              aria-label="閉じる"
              tabIndex={0}
              onClick={onCloseButtonClick}
            >
              <div className="_leading">
                <span className="icon _icon" data-icon="arrow_left"></span>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="modal-content__container">
        <div className="dropdown">
          <div
            className="dropdown__control _switch --fixed-width"
            role="button"
            aria-expanded="true"
            aria-label="コースをもとに選ぶ"
            tabIndex={0}
            onClick={() => setOpenDropdown(!openDropdown)}
          >
            <span className="_switch-label">
              {selectedEventGroupIds.length > 0
                ? "複数コース"
                : "コースをもとに選ぶ"}
            </span>
            <div className="_switch-trailing">
              <span className="icon" data-icon="arrow_drop_down" />
            </div>
          </div>
          {openDropdown && (
            <ul
              className="_list dropdown__list"
              aria-labelledby="addressee-event-group-dropdown"
            >
              {classroom_event_groups.filter((eventGroup) => !eventGroup.root)
                .map((eventGroup) => (
                  <li key={eventGroup.id} className="_item dropdown__item">
                    <label htmlFor={`event_group-${eventGroup.id}`}>
                      <div className="_leading">
                        <div className="_input">
                          <input
                            id={`event_group-${eventGroup.id}`}
                            type="checkbox"
                            className="checkbox"
                            checked={selectedEventGroupIds.includes(
                              eventGroup.id,
                            )}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setSelectedEventGroupIds(
                                  selectedEventGroupIds.concat(eventGroup.id),
                                );
                                const studentIds = enrollments.filter(
                                  (enrollment) => {
                                    return enrollment.event_groups.some((g) => {
                                      return g.id === eventGroup.id;
                                    });
                                  },
                                ).map((enollment) => {
                                  return enollment.student.id;
                                });
                                setSelectedStudentIds(
                                  Array.from(
                                    new Set([
                                      ...selectedStudentIds,
                                      ...studentIds,
                                    ]),
                                  ),
                                );
                              } else {
                                deselectEventGroup(eventGroup);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="_body">
                        <div className="_title">
                          {eventGroup.name}
                        </div>
                      </div>
                    </label>
                  </li>
                ))}
            </ul>
          )}
        </div>

        <div className="chips-list">
          <ul className="chips-list__list">
            {selectedEventGroupIds.map((id) => {
              const eventGroup = classroom_event_groups.find((eventGroup) => {
                return eventGroup.id === id;
              });

              if (!eventGroup) {
                return;
              }

              const eventGroupEnrollments = enrollments.filter((enrollment) => {
                return enrollment.event_groups.some((g) =>
                  g.id === eventGroup.id
                );
              });
              const total = eventGroupEnrollments.length;
              const count = eventGroupEnrollments.filter((enrollment) => {
                return selectedStudentIds.includes(enrollment.student.id);
              }).length;
              return (
                <li
                  key={id}
                  className="chips-list__item"
                  onClick={() => deselectEventGroup(eventGroup)}
                >
                  <button type="button">
                    <div className="chips-list__body">
                      <span>{eventGroup.name}</span>
                      <b>（{count}/{total}人）</b>
                    </div>
                    <div className="chips-list__leading">
                      <span className="_icon icon" data-icon="cross" />
                    </div>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>

        <ul className="_list addressee-select__list">
          <li className="_item">
            <label htmlFor="select-all-student">
              <div className="_leading">
                <div className="_input">
                  <input
                    id="select-all-student"
                    type="checkbox"
                    className={selectedStudentIds.length > 0 &&
                        !allStudentSelected
                      ? "checkbox --mixed"
                      : "checkbox"}
                    checked={allStudentSelected}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectedStudentIds(
                          enrollments.map((enrollment) =>
                            enrollment.student.id
                          ),
                        );
                      } else {
                        setSelectedStudentIds([]);
                        setSelectedEventGroupIds([]);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="_body">
                <div className="_title">
                  全員を選択
                </div>
              </div>
            </label>
          </li>
          {enrollments.map((enrollment) => (
            <li className="_item" key={enrollment.id}>
              <label htmlFor={`addressee-${enrollment.id}`}>
                <div className="_leading">
                  <div className="_input">
                    <input
                      type="checkbox"
                      className="checkbox"
                      id={`addressee-${enrollment.id}`}
                      checked={selectedStudentIds.includes(
                        enrollment.student.id,
                      )}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSelectedStudentIds(
                            selectedStudentIds.concat(enrollment.student.id),
                          );
                        } else {
                          const studentIds = selectedStudentIds.filter(
                            (id) => {
                              return id !== enrollment.student.id;
                            },
                          );
                          setSelectedStudentIds(studentIds);
                          const eventGroupIds = enrollments.filter(
                            (enrollment) => {
                              return studentIds.includes(enrollment.student.id);
                            },
                          ).flatMap((enrollment) => {
                            return enrollment.event_groups;
                          }).map((eventGroup) => {
                            return eventGroup.id;
                          });
                          setSelectedEventGroupIds(
                            Array.from(new Set(eventGroupIds)),
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="_avatar">
                  <TextAvatar name={enrollment.student.name} size="s" icon />
                </div>
                <div className="_body">
                  <div className="_title">
                    {enrollment.student.name}
                  </div>
                  <div className="_description">
                    {enrollment.event_groups.map((eventGroup) =>
                      eventGroup.name
                    ).join(", ")}
                  </div>
                </div>
                {show_automatic_settlement_enabled &&
                  enrollment.allowed_to_settle_automatically && (
                  <div className="_trailing">
                    <div className="credit-card-status --positive">
                      <span
                        className="icon credit-card-status__icon"
                        data-icon="check_on_circle"
                      >
                      </span>
                      <div className="credit-card-status__body">
                        <span>自動支払い</span>設定済み
                      </div>
                    </div>
                  </div>
                )}
              </label>
            </li>
          ))}
        </ul>
        <div className="form__bottom-action">
          <div className="form__action">
            <button
              type="button"
              className="button -size-l -appearance-filled -width-full -color-interactive"
              onClick={onCloseButtonClick}
            >
              対象者を決定
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
