import React, { ChangeEvent } from "react";

import { useFormContext } from "react-hook-form";
import { Address, ClassroomEnrollmentParams } from "../ClassroomEnrollmentPage";

interface Props {
  addresses: Address[];
}

export default function ClassroomEnrollmentAddressFormSection(
  { addresses }: Props,
): JSX.Element {
  const { register, watch, formState: { errors } } = useFormContext<
    ClassroomEnrollmentParams
  >();

  return (
    <>
      <div className="form__item">
        <fieldset>
          <legend>住所</legend>
          <div className="form__control --has-radiofield">
            <div className="radiofield --layout-stack">
              <label className="_item">
                <input
                  {...register("student_address_id", {
                    required: "選択してください",
                  })}
                  className="radio"
                  type="radio"
                  value=""
                  defaultChecked
                />
                <span className="_label">設定しない</span>
              </label>

              {addresses.map((address) => (
                <label key={address.id} className="_item">
                  <input
                    {...register("student_address_id", {
                      required: "選択してください",
                    })}
                    className="radio"
                    type="radio"
                    value={address.id}
                  />
                  <span className="_label">
                    〒{address.postal_code} {address.address}
                  </span>
                </label>
              ))}

              <label className="_item">
                <input
                  {...register("student_address_id", {
                    required: "選択してください",
                  })}
                  className="radio"
                  type="radio"
                  value="new"
                />
                <span className="_label">新しく登録する</span>
              </label>

              <div className="form__error" aria-live="polite">
                {errors.student_address_id?.message}
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      {watch("student_address_id") === "new" && (
        <div className="form enroll-event-group__student-address">
          <span className="form__label">新しく登録する住所</span>
          <div className="form__item">
            <div className="form__control">
              <span className="textfield -appearance-filled -width-half">
                <input
                  {...register("student_address_postal_code", {
                    required: "入力してください",
                    pattern: {
                      value: /^\d{7}$/,
                      message: "半角数字で入力してください",
                    },
                  })}
                  className="_input"
                  id="student-address-postal-code"
                  placeholder="郵便番号"
                />
                <label
                  htmlFor="student-address-postal-code"
                  className="_label"
                >
                  郵便番号
                </label>
              </span>
              <div className="form__error" aria-live="polite">
                {errors.student_address_postal_code?.message}
              </div>
            </div>
          </div>
          <div className="form__item-note">
            例：1508512
          </div>

          <div className="form__item">
            <div className="form__control">
              <span className="textfield -appearance-filled -width-full">
                <textarea
                  {...register("student_address_address", {
                    required: "入力してください",
                    maxLength: {
                      value: 255,
                      message: "255文字以内で入力してください",
                    },
                  })}
                  className="_input"
                  id="student-address-address"
                  placeholder="住所"
                />
                <label
                  htmlFor="student-address-address"
                  className="_label"
                >
                  住所
                </label>
              </span>
              <div className="form__error" aria-live="polite">
                {errors.student_address_address?.message}
              </div>
            </div>
          </div>
          <div className="form__item-note">
            例：東京都渋谷区桜丘町26-1 桜丘マンション201
          </div>
        </div>
      )}
    </>
  );
}
