import React, { useEffect, useState } from "react";
import { Control, useForm } from "react-hook-form";
import Turbolinks from "turbolinks";
import Snackbar from "../Snackbar";
import Dialog from "../Dialog";
import HelpModal from "../HelpModal";
import ErrorBanner from "../ErrorBanner";
import AnnouncementFormIssuedDateSection from "./IssuedDateSection";
import AnnouncementFormAIAssistant from "./AIAssistant";
import AnnouncementFormAddresseeSelector, {
  Enrollment,
  EventGroup,
} from "./AddresseeSelector";
import AnnouncementFormFilesSection from "./FilesSection";
import { format } from "date-fns";
import { getNextHalfHour } from "../../utils/selectable-time-options";

interface FileObj {
  url: string;
  name: string;
  type: string;
  size: number;
}

export interface AnnouncementFormInputs {
  title: string;
  body: string;
  draft: boolean;
  issued_date: string;
  issued_time: string;
  shared_with: "addressee" | "classroom";
  student_ids: number[];
  files: File[];
}
export type FormController = Control<AnnouncementFormInputs>;

type AnnouncementStatus = "new" | "draft" | "scheduled" | "issued";

interface Props {
  attributes: AnnouncementFormInputs;
  file_objects: FileObj[];
  announcement_status: AnnouncementStatus;
  form_path: string;
  form_method: string;
  generations_path: string;
  errors: string[];
  classroom_event_groups: EventGroup[];
  enrollments: Enrollment[];
}

export default function AnnouncementForm(props: Props) {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>(null);
  const [isScheduledIssue, setIsScheduledIssue] = useState<boolean>(
    props.announcement_status === "scheduled",
  );
  const status: AnnouncementStatus = props.announcement_status === "issued"
    ? "issued"
    : isScheduledIssue
    ? "scheduled"
    : "draft";

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    setError,
    clearErrors,
    watch,
    trigger,
  } = useForm<AnnouncementFormInputs>({
    defaultValues: {
      ...props.attributes,
      draft: false,
    },
    mode: "all",
  });

  const validateStudentIds = () => {
    if (
      watch("shared_with") === "addressee" && watch("student_ids").length === 0
    ) {
      setError("student_ids", {
        type: "empty",
        message: "受講者を選択してください",
      });
    } else {
      clearErrors("student_ids");
    }
    trigger();
  };

  const onFilesChange = (newFiles: File[]) =>
    setValue("files", watch("files").concat(newFiles));
  const onRemoveFile = (file: File) => {
    const newFiles = watch("files").filter((f) => f !== file);
    setValue("files", newFiles);
  };

  const onChangeStudentIds = (selectedStudentIds: number[]) => {
    setValue("student_ids", selectedStudentIds);
    validateStudentIds();
  };

  const onSchedulingChange = (isScheduledIssue: boolean) => {
    setIsScheduledIssue(isScheduledIssue);
    if (isScheduledIssue) {
      setValue("issued_date", format(new Date(), "yyyy-MM-dd"));
      setValue("issued_time", getNextHalfHour());
    } else {
      setValue("issued_date", null);
      setValue("issued_time", null);
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const form = new FormData();
    form.append("announcement[title]", data.title);
    form.append("announcement[body]", data.body);
    form.append("announcement[draft]", data.draft.toString());
    form.append("announcement[issued_date]", data.issued_date);
    form.append("announcement[issued_time]", data.issued_time);
    form.append("announcement[shared_with]", data.shared_with);
    data.student_ids.forEach((student_id) =>
      form.append("announcement[student_ids][]", student_id.toString())
    );
    data.files.forEach((file) => form.append("announcement[files][]", file));
    const response = await fetch(props.form_path, {
      method: props.form_method.toUpperCase(),
      body: form,
      headers: {
        "X-CSRF-Token":
          document.querySelector<HTMLMetaElement>('[name="csrf-token"]')
            .content,
      },
    });
    const { message, redirect_url } = await response.json();
    if (response.ok) {
      Turbolinks.visit(redirect_url);
    } else {
      setMessage(message);
    }
    setLoading(false);
    setOpenConfirmDialog(false);
  });
  const onDraftSubmit = async () => {
    setValue("draft", true);
    await trigger();
    validateStudentIds();
    await onSubmit();
  };
  const onOpenConfirmDialog = async () => {
    setValue("draft", false);
    await trigger();
    validateStudentIds();
    if (isValid) {
      setOpenConfirmDialog(true);
    }
  };

  useEffect(() => {
    (async () => {
      const fetchedFiles = await Promise.all(
        props.file_objects.map(async (fileObj) => {
          const response = await fetch(fileObj.url);
          const blob = await response.blob();
          return new File([blob], fileObj.name, { type: fileObj.type });
        }),
      );
      setValue("files", fetchedFiles);
    })();
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <ErrorBanner
        errors={props.errors}
      />

      <div className="modal-content__container">
        <div className="form">
          <div className="form__section">
            <h2 class="form__title">
              <span class="form__title-trailing">
                <span class="icon" data-icon="megaphone"></span>
              </span>
              <span class="form__title-text">タイトル</span>
              <span class="status-label --over">必須</span>
            </h2>
            <div className="form__item">
              <div className="form__control">
                <span className="textfield -appearance-filled -width-full">
                  <input
                    {...register("title", {
                      required: "タイトルを入力してください",
                      maxLength: {
                        value: 140,
                        message: "タイトルは140文字以内で入力してください",
                      },
                    })}
                    type="text"
                    className="_input"
                    placeholder="お知らせのタイトルを入力してください"
                    id="announcement-title"
                  />
                  <label htmlFor="announcement-title" className="_label">
                    タイトルを入力してください
                  </label>
                </span>
                <div
                  id="announcement-title-validation-message"
                  className="form__error"
                  aria-live="polite"
                >
                  {errors.title?.message}
                </div>
              </div>
            </div>
          </div>
          <div className="form__section">
            <h2 class="form__title">
              <span class="form__title-trailing">
                <span class="icon" data-icon="folder"></span>
              </span>
              <span class="form__title-text">内容</span>
              <span class="status-label --over">必須</span>
            </h2>
            <div className="form__item">
              <div className="form__control">
                <span className="textfield -appearance-filled -width-full --textarea-large">
                  <textarea
                    {...register("body", {
                      required: "内容を入力してください",
                      maxLength: {
                        value: 2000,
                        message: "内容は2000文字以内で入力してください",
                      },
                    })}
                    className="_input"
                    placeholder="内容［必須］"
                    id="announcement-body"
                  >
                  </textarea>
                  <label htmlFor="announcement-body" className="_label">
                    内容を入力してください
                  </label>
                </span>
                <div
                  className="form__error"
                  aria-live="polite"
                >
                  {errors.body?.message}
                </div>
              </div>
            </div>
          </div>
          <div className="form__section">
            <div className="form__item">
              <div className="form__control">
                <AnnouncementFormAIAssistant
                  form_action={props.generations_path}
                  title={watch("title")}
                  body={watch("body")}
                  onReflection={(field: keyof AnnouncementFormInputs, value) =>
                    setValue(field, value)}
                />
              </div>
            </div>
          </div>

          <AnnouncementFormFilesSection
            files={watch("files")}
            onFilesChange={onFilesChange}
            onRemoveFile={onRemoveFile}
          />

          {status !== "issued" && (
            <div className="form__section --has-margin">
              <div className="form__header">
                <h2 className="form__title">
                  <span className="form__title-text">
                    公開範囲
                  </span>
                  <span class="status-label --over">
                    必須
                  </span>
                </h2>
              </div>
              <div className="form__item">
                <div className="form__control --has-radiofield">
                  <div className="radiofield --layout-stack">
                    <label className="_item">
                      <input
                        {...register("shared_with")}
                        type="radio"
                        value="classroom"
                        className="radio"
                      />
                      <div class="form__item-forward">
                        <span class="icon" data-icon="circle"></span>
                      </div>
                      <span className="_label">教室内公開</span>
                    </label>
                    <div class="page__data-body">
                      <ol>
                        <ul>
                          教室の「受講者全員」に表示されます。お知らせを作成後に入会した受講者にも表示されます。
                        </ul>
                      </ol>
                    </div>
                    <label className="_item">
                      <input
                        {...register("shared_with", {
                          onChange: validateStudentIds,
                        })}
                        type="radio"
                        value="addressee"
                        className="radio"
                      />
                      <div class="form__item-forward">
                        <span class="icon" data-icon="people"></span>
                      </div>
                      <span className="_label">対象者限定</span>
                    </label>
                    <div class="page__data-body">
                      <ol>
                        <ul>
                          指定したコースや個別の受講者のみが内容を閲覧できます。
                        </ul>
                      </ol>
                    </div>
                  </div>
                </div>
                {watch("shared_with") === "addressee" && (
                  <div className="form__control">
                    <AnnouncementFormAddresseeSelector
                      selectedStudentIds={watch("student_ids")}
                      onChangeStudentIds={onChangeStudentIds}
                      classroom_event_groups={props.classroom_event_groups}
                      enrollments={props.enrollments}
                    />
                  </div>
                )}
                <div
                  className="form__error"
                  aria-live="polite"
                >
                  {errors.student_ids?.message}
                </div>
              </div>
            </div>
          )}

          <div className="form__bottom-action">
            <div className="form__action">
              <ul className="button-group -direction-column">
                {status !== "issued" && (
                  <li className="_item">
                    <button
                      type="button"
                      onClick={onDraftSubmit}
                      className="button -size-l -appearance-transparent -width-full -color-interactive"
                    >
                      <div className="_body">
                        下書き保存
                      </div>
                    </button>
                  </li>
                )}
                <li className="_item">
                  <button
                    type="button"
                    onClick={onOpenConfirmDialog}
                    disabled={!isValid}
                    className="button -size-l -appearance-filled -width-full -color-interactive"
                  >
                    <div className="_body">
                      {{
                        draft: "送信",
                        scheduled: "送信予約",
                        issued: "保存",
                      }[status]}
                    </div>
                  </button>
                </li>
                {status !== "issued" && (
                  <li>
                    <AnnouncementFormIssuedDateSection
                      currentIssuedDate={watch("issued_date")}
                      isScheduledIssue={isScheduledIssue}
                      register={register}
                      errors={errors}
                      onSchedulingChange={onSchedulingChange}
                    />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {message && <Snackbar>{message}</Snackbar>}

      {openConfirmDialog && isValid && (
        <Dialog
          onCloseButtonClick={() => setOpenConfirmDialog(false)}
          {...{
            draft: {
              title: "お知らせを送信してよろしいですか？",
            },
            scheduled: {
              title: "お知らせを予約してよろしいですか？",
            },
            issued: {
              title: "この内容でお知らせを更新しますか？",
              description:
                "お知らせが更新されたことは受講者に通知されませんが、お知らせに更新日時が表示されるようになります。",
            },
          }[status]}
        >
          <button
            type="submit"
            className="button -appearance-transparent -size-m -color-interactive"
            disabled={loading}
          >
            <div className="_body">
              {{
                draft: "送信",
                scheduled: "送信予約",
                issued: "保存",
              }[status]}
            </div>
          </button>
        </Dialog>
      )}

      {openHelpModal && (
        <HelpModal
          onCloseButtonClick={() => setOpenHelpModal(false)}
          title="公開範囲とは"
        >
          <dl>
            <dt>一部の受講者</dt>
            <dd>「選択した受講者のみ」にお知らせが表示されます。</dd>
            <dt>教室全体</dt>
            <dd>
              教室の「受講者全員」に表示されます。お知らせを作成後に入会した受講者にも表示されます。
            </dd>
          </dl>
        </HelpModal>
      )}
    </form>
  );
}
