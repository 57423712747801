import React, { useEffect, useState } from "react";
import toBase64 from "../../packs/file-to-base64";

type AnnouncementFormFilesSectionProps = {
  files: File[];
  onFilesChange: (newFile: File[]) => void;
  onRemoveFile: (file: File) => void;
};

type FilePreview = {
  name: string;
  type: string;
  content: string | ArrayBuffer;
  file: File;
};

export default function AnnouncementFormFilesSection(
  { files, onFilesChange, onRemoveFile }: AnnouncementFormFilesSectionProps,
) {
  const [previews, setPreviews] = useState<FilePreview[]>([]);

  const onFileChange = async (e) => {
    const newFiles = e.target.files;
    if (files.length + newFiles.length > 5) {
      alert("添付できるファイルは5つまでです。");
      return;
    }
    for (const file of newFiles) {
      if (file.size / 1024 ** 2 > 10) {
        alert("添付するファイルのサイズは10MB以下にしてください。");
        return;
      }
    }

    onFilesChange([...newFiles]);
  };

  useEffect(() => {
    (async () => {
      const newPreviews = await Promise.all(files.map(async (file) => {
        return {
          name: file.name,
          type: file.type,
          content: await toBase64(file),
          file,
        };
      }));
      setPreviews(newPreviews);
    })();
  }, [files]);

  return (
    <div className="form__section --has-margin">
      <div className="form__header">
        <h2 className="form__title">
          <span className="form__title-text">
            ファイルの添付
          </span>
        </h2>
      </div>

      <div className="form__item card-list --no-padding --column-2">
        <ul className="_list" id="js-multi-file-preview-target-container">
          {previews.map((file, index) => (
            <li
              className="_item"
              key={index}
            >
              <div className="thumbnail-card">
                <button
                  type="button"
                  className="_button"
                  aria-label="ファイルを削除"
                  onClick={() => onRemoveFile(file.file)}
                >
                  <div className="_leading">
                    <span className="icon" data-icon="cross"></span>
                  </div>
                </button>
                {file.type.includes("image")
                  ? (
                    <div className="thumbnail-card__media">
                      <img src={file.content as string} alt={file.name} />
                    </div>
                  )
                  : (
                    <div className="thumbnail-card__file">
                      <div className="_body">{file.name}</div>
                      <div className="_trailing">
                        <span className="icon" data-icon="image"></span>
                        PDF
                      </div>
                    </div>
                  )}
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="form__item file-upload">
        <div className="file-upload__item">
          <input
            type="file"
            id="files"
            className="multi-file-preview"
            accept="image/jpeg,image/png,image/gif,image/heif,image/heic,.pdf"
            multiple
            onChange={onFileChange}
          />
          <div className="file-upload__button-group">
            <label
              htmlFor="files"
              className="button -appearance-outlined -size-m"
              aria-label="ファイルを選択してアップロード"
              aria-describedby="hint-file"
            >
              <div className="_leading">
                <span className="icon" data-icon="upload"></span>
              </div>
              <div className="_body">
                アップロード
              </div>
            </label>
          </div>
        </div>
        <div
          id="hint-file"
          className="form__item-note --no-margin"
        >
          ※ 画像またはPDFファイル（10MB以下）を5つまで
        </div>
      </div>
    </div>
  );
}
