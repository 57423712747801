document.addEventListener("turbolinks:load", function () {
  const snackbars = document.querySelectorAll(".js-snackbar");
  const closeButtons = document.querySelectorAll(".js-snackbar-close");

  closeButtons.forEach((closeButton) => {
    closeButton.addEventListener("click", () => {
      closeButton.closest(".js-snackbar").classList.remove("--visible");
    });
  });

  snackbars.forEach((snackbar) => hideAfter7Seconds(snackbar));
});

export default function showingSnackBar(message: string) {
  const notice = document.getElementById("notice");
  const noticeBody = notice.getElementsByClassName("_body")[0];
  if (!(notice && noticeBody)) return;

  notice.removeAttribute("hidden");
  notice.classList.add("--visible");
  noticeBody.textContent = message;

  hideAfter7Seconds(notice);
}

const hideAfter7Seconds = (element) => {
  setTimeout(() => {
    element.classList.remove("--visible");
  }, 7000);
};
