import React, { useCallback, useState } from "react";

import { CustomField } from "../ManagementCustomFieldsPage";
import Dialog from "../Dialog";
import ManagementCustomFieldsItemForm from "./ManagementCustomFieldsItemForm";
import { localize } from "../../utils/localize";
import { useApi } from "../../hooks/api";

interface CustomFieldResponse {
  custom_field?: CustomField;
  message?: string;
}

interface Props {
  customField: CustomField;
  create_custom_field_path: string;
  policy_can_update_and_destroy_management_classrooms_custom_fields: boolean;
  removeItem: () => void;
  showMessage: (message: string) => void;
  showIndex: number;
  openDropdown: boolean;
  onOpenDropdown: () => void;
  onClosedDropdown: () => void;
}

export default function ManagementCustomFieldsItem(props: Props): JSX.Element {
  const [customField, setCustomField] = useState(props.customField);
  const handleBlur = (event) => {
    const isButtonFocused = event.relatedTarget?.tagName === "BUTTON";
    if (!isButtonFocused) {
      props.onClosedDropdown();
    }
  };
  const [deletingDialogVisible, setDeletingDialogVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(props.customField.isEditing);

  const enterEditing = useCallback(() => {
    props.onClosedDropdown();
    setIsEditing(true);
  }, []);
  const commitEditing = useCallback((customField: CustomField) => {
    setCustomField(customField);
    setIsEditing(false);
  }, []);
  const exitEditing = useCallback(() => {
    if (customField.id === undefined) {
      props.removeItem();
    } else {
      setIsEditing(false);
    }
  }, [customField.id, props.removeItem]);

  const { patch, requestWithCsrfToken } = useApi();
  const toggleRequiredOnEnrollment = useCallback(async () => {
    const result = await patch<CustomFieldResponse>(
      customField.update_custom_field_path,
      {
        custom_field: {
          ...customField,
          required_on_enrollment: !customField.required_on_enrollment,
        },
      },
    );
    if (result.custom_field) {
      setCustomField(result.custom_field as CustomField);
    }
    if (typeof result.message === "string") {
      props.showMessage(result.message);
    }
    props.onClosedDropdown();
  }, [
    customField.required_on_enrollment,
    customField.update_custom_field_path,
    props.showMessage,
  ]);
  const deleteCustomField = useCallback(async () => {
    const { message, response } = await requestWithCsrfToken<
      { message: string }
    >({
      form_action: customField.update_custom_field_path,
      form_method: "DELETE",
    });
    if (response.ok) {
      props.removeItem();
    }
    if (typeof message === "string") props.showMessage(message);
  }, [
    customField.update_custom_field_path,
    props.removeItem,
    props.showMessage,
  ]);

  return (
    <>
      {isEditing
        ? (
          <ManagementCustomFieldsItemForm
            customField={customField}
            create_custom_field_path={props.create_custom_field_path}
            commitEditing={commitEditing}
            exitEditing={exitEditing}
            showMessage={props.showMessage}
          />
        )
        : (
          <tr>
            <td>
              {customField.required_on_enrollment && (
                <span className="status-label -color-positive">
                  入会時の設問
                </span>
              )}
              <span className="_custom-field-name">{customField.name}</span>
            </td>
            <td className="_custom-field-option">
              <div className="_custom-field-type">
                {localize({
                  key: "custom_fields.field_type",
                  value: customField.field_type,
                })}式
              </div>
              {customField.field_type !== "free_text" && (
                <>
                  {customField.options.length > 0
                    ? (
                      customField.options.map((option) => (
                        <span key={option.id}>{option.option_value}</span>
                      ))
                    )
                    : (
                      <div
                        className="_custom-field-text -color-notice"
                        aria-live="polite"
                      >
                        <span
                          className="icon"
                          data-icon="exclamation_on_triangle"
                        >
                        </span>
                        <span>
                          選択肢が未作成です
                        </span>
                      </div>
                    )}
                </>
              )}
            </td>
            {props
              .policy_can_update_and_destroy_management_classrooms_custom_fields &&
              (
                <td>
                  <div className="dropdown --position-right">
                    <button
                      onClick={props.onOpenDropdown}
                      onBlur={handleBlur}
                      className="dropdown__control button -appearance-transparent -shape-circle -size-m"
                      aria-label="操作"
                      aria-controls={`edit-${props.showIndex}`}
                      aria-expanded={props.openDropdown ? "true" : "false"}
                    >
                      <div className="_leading">
                        <span
                          className="_icon icon"
                          data-icon="ellipsis_vertical"
                        >
                        </span>
                      </div>
                    </button>
                    {props.openDropdown && (
                      <ul
                        id={`edit-${props.showIndex}`}
                        className="_list dropdown__list"
                      >
                        <li className="_item dropdown__item">
                          <button onClick={enterEditing}>
                            <div className="_body">
                              <div className="_title">
                                編集
                              </div>
                            </div>
                          </button>
                        </li>
                        <li className="_item dropdown__item">
                          <button onClick={toggleRequiredOnEnrollment}>
                            <div className="_body">
                              <div className="_title">
                                {customField.required_on_enrollment
                                  ? "入会時の設問から外す"
                                  : "入会時の設問に追加"}
                              </div>
                            </div>
                          </button>
                        </li>
                        <li className="_item dropdown__item -has-divider">
                          <button
                            onClick={() => setDeletingDialogVisible(true)}
                            className="-color-negative"
                          >
                            <div className="_body">
                              <div className="_title">
                                項目の削除
                              </div>
                            </div>
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>
                  {deletingDialogVisible && (
                    <Dialog
                      title={`本当に項目名「${customField.name}」を削除してもよろしいですか？`}
                      description="すでに回答済みの内容も、項目ごとすべて削除されますのでご注意ください。"
                      onCloseButtonClick={() => {
                        setDeletingDialogVisible(false);
                        props.onClosedDropdown();
                      }}
                    >
                      <button
                        className="button -appearance-transparent -size-m -color-negative"
                        onClick={deleteCustomField}
                      >
                        <div className="_body">
                          削除
                        </div>
                      </button>
                    </Dialog>
                  )}
                </td>
              )}
          </tr>
        )}
    </>
  );
}
