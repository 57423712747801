import { addMinutes, eachMinuteOfInterval, format, startOfDay } from "date-fns";

export const timeOptions = eachMinuteOfInterval(
  {
    start: startOfDay(new Date()),
    end: new Date(startOfDay(new Date()).getTime() + 23.5 * 60 * 60 * 1000),
  },
  { step: 30 },
).map((time) => format(time, "HH:mm"));

// 引数時刻から未来かつ最も近い30分刻みの文字列を返す
// 16:22 => "16:30"
export const getNextHalfHour = (date = new Date()): string => {
  const minutes = date.getMinutes();
  const minutesToAdd = minutes < 30 ? 30 - minutes : 60 - minutes;
  const roundedTime = addMinutes(date, minutesToAdd);
  return format(roundedTime, "HH:mm");
};
