import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import MemberBills from "./MemberBills";
import MemberAnnouncements from "./MemberAnnouncements";
import MemberEvents from "./MemberEvents";
import MemberDetails from "./MemberDetails";
import TextAvatar from "../TextAvatar";
import Dialog from "../Dialog";
import { Enrollment } from "components/ManagementMemberPage/ManagementMemberPageContainer";

interface Props {
  onCloseFloatingView: () => void;
  getEnrolledEventGroups: (url: string) => Promise<{
    form_action: string;
    event_groups: unknown[];
  }>;
  getRecurrenceBills: (url: string) => Promise<{
    form_action: string;
    recurrence_bills: unknown[];
  }>;
  getBills: (url: string) => Promise<{ bills: unknown[] }>;
  getAnnouncements: (url: string) => Promise<{ announcements: unknown[] }>;
  getEvents: (url: string) => Promise<{ events: unknown[] }>;
  handleUpdateEnrolledEventGroups: (
    enrollmentId: number,
    url: string,
    data: unknown,
  ) => Promise<void>;
  handleUpdateRecurredBills: (
    enrollmentId: number,
    url: string,
    data: unknown,
  ) => Promise<void>;
  handleUpdateEnrollment: (
    enrollmentId: number,
    data: { enrollment: { description: string } },
  ) => Promise<void>;
  subscribed: boolean;
  enrollment: Enrollment | null;
  newCoursePath: string;
  newRecurrenceBillPath: string;
  membersDestroyAccess: boolean;
  recurrenceBillsShowAccess: boolean;
  studentsBillsIndexAccess: boolean;
  studentsBulkEnrollmentEventGroupsIndexAccess: boolean;
}

export default function MemberFloatingView(props: Props): JSX.Element {
  const [viewer, setViewer] = useState<string>(
    sessionStorage.getItem("viewer") || "details",
  );
  const [userActionPopupVisible, setUserActionPopupVisible] = useState(false);
  const [deletingPopupVisible, setDeletingPopupVisible] = useState(false);
  const toggleUserActionPopup = () => {
    setUserActionPopupVisible((prev) => !prev);
  };
  const handleBlur = (event) => {
    const isButtonFocused = event.relatedTarget?.tagName === "BUTTON";
    if (!isButtonFocused) {
      setUserActionPopupVisible(false);
    }
  };
  const tabViewer = {
    details: (
      <div className="tab-content__item">
        {props.enrollment && (
          <MemberDetails
            subscribed={props.subscribed}
            enrollment={props.enrollment}
            newCoursePath={props.newCoursePath}
            newRecurrenceBillPath={props.newRecurrenceBillPath}
            getEnrolledEventGroups={props.getEnrolledEventGroups}
            getRecurrenceBills={props.getRecurrenceBills}
            handleUpdateEnrolledEventGroups={props
              .handleUpdateEnrolledEventGroups}
            handleUpdateRecurredBills={props.handleUpdateRecurredBills}
            handleUpdateEnrollment={props.handleUpdateEnrollment}
            recurrenceBillsShowAccess={props.recurrenceBillsShowAccess}
            studentsBulkEnrollmentEventGroupsIndexAccess={props
              .studentsBulkEnrollmentEventGroupsIndexAccess}
          />
        )}
      </div>
    ),
    bills: (
      <div className="tab-content__item">
        {props.enrollment && (
          <MemberBills
            getBills={props.getBills}
            bills_url={props.enrollment.bills_url}
            new_bill_url={props.enrollment.new_bill_url}
          />
        )}
      </div>
    ),
    announcements: (
      <div className="tab-content__item">
        {props.enrollment && (
          <MemberAnnouncements
            getAnnouncements={props.getAnnouncements}
            announcements_url={props.enrollment.announcements_url}
            new_announcement_url={props.enrollment.new_announcement_url}
          />
        )}
      </div>
    ),
    events: (
      <div className="tab-content__item">
        {props.enrollment && (
          <MemberEvents
            getEvents={props.getEvents}
            events_url={props.enrollment.events_url}
            new_event_url={props.enrollment.new_event_url}
          />
        )}
      </div>
    ),
  };
  const ref = useRef<HTMLButtonElement>();

  useEffect(() => {
    if (props.enrollment) {
      ref.current?.focus();
    }
  }, [props.enrollment]);

  return (
    <section
      id="admin-table__single"
      className={classNames(
        "admin-table__single",
        { "_visible": props.enrollment !== null },
      )}
    >
      {props.enrollment && (
        <div className="single-container">
          <div className="single-control">
            <div className="single-control__button">
              <button
                onClick={props.onCloseFloatingView}
                ref={ref}
                aria-label="閉じる"
              >
                <span className="icon --close" data-icon="cross" />
                <span className="icon --back" data-icon="chevron_left" />
              </button>
            </div>

            {props.membersDestroyAccess
              ? (
                <div className="single-control__button single-control__action">
                  <button
                    className="single-control__button"
                    onClick={toggleUserActionPopup}
                    onBlur={handleBlur}
                    aria-label="操作"
                    aria-controls="user-action"
                    aria-expanded={userActionPopupVisible ? "true" : "false"}
                  >
                    <span className="icon" data-icon="ellipsis_horizontal" />
                  </button>
                  {userActionPopupVisible && (
                    <>
                      <ul className="dropdown__list" id="user-action">
                        <li className="dropdown__item">
                          <button
                            onClick={() => setDeletingPopupVisible(true)}
                          >
                            <div className="_body">
                              教室から退会
                            </div>
                          </button>
                        </li>
                      </ul>
                      {deletingPopupVisible && (
                        <Dialog
                          title={`${props.enrollment.student.name}様をこの教室から退会させますか？`}
                          description="この受講者の情報は閲覧できなくなります。また、退会した受講者はあなたの教室に関する情報（お知らせ・予定）の閲覧や、未払いの集金へのお支払いができなくなります。"
                          onCloseButtonClick={() =>
                            setDeletingPopupVisible(false)}
                        >
                          <a
                            className="button -appearance-transparent -size-m -color-negative"
                            rel="nofollow"
                            data-method="delete"
                            href={props.enrollment.delete_url}
                          >
                            <div className="_body">
                              退会
                            </div>
                          </a>
                        </Dialog>
                      )}
                    </>
                  )}
                </div>
              )
              : null}
          </div>
          <div className="page__section">
            <div className="page__container">
              <div className="user">
                <div className="user__cover">
                  <div className="user__avatar">
                    <TextAvatar
                      size={"l"}
                      name={props.enrollment.student.name}
                    />
                  </div>
                  <h1 className="user__name">
                    {props.enrollment.student.name}
                  </h1>
                  <div className="user__name-ruby">
                    {props.enrollment.student.name_kana}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-tab">
            <div role="tablist">
              <button
                id="tab-details"
                className="content-tab__item"
                type="button"
                role="tab"
                aria-selected={viewer === "details" ? "true" : "false"}
                aria-controls={"tabpanel-details"}
                onClick={() => {
                  setViewer("details");
                  sessionStorage.setItem("viewer", "details");
                }}
              >
                <span className="tab__title">
                  登録情報
                </span>
              </button>

              {props.studentsBillsIndexAccess
                ? (
                  <button
                    id="tab-bills"
                    className="content-tab__item"
                    type="button"
                    role="tab"
                    aria-selected={viewer === "bills" ? "true" : "false"}
                    aria-controls={"tabpanel-bills"}
                    onClick={() => {
                      setViewer("bills");
                      sessionStorage.setItem("viewer", "bills");
                    }}
                  >
                    <span className="tab__title">
                      請求
                    </span>
                  </button>
                )
                : null}

              <button
                id="tab-announcements"
                className="content-tab__item"
                type="button"
                role="tab"
                aria-selected={viewer === "announcements" ? "true" : "false"}
                aria-controls={"tabpanel-announcements"}
                onClick={() => {
                  setViewer("announcements");
                  sessionStorage.setItem("viewer", "announcements");
                }}
              >
                <span className="tab__title">
                  お知らせ
                </span>
              </button>

              <button
                id="tab-events"
                className="content-tab__item"
                type="button"
                role="tab"
                aria-selected={viewer === "events" ? "true" : "false"}
                aria-controls={"tabpanel-events"}
                onClick={() => {
                  setViewer("events");
                  sessionStorage.setItem("viewer", "events");
                }}
              >
                <span className="tab__title">
                  予定
                </span>
              </button>
            </div>

            <div
              id={"tabpanel-" + viewer}
              role="tabpanel"
              aria-labelledby={"tab-" + viewer}
            >
              {tabViewer[viewer]}
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
