import React, { useEffect, useState } from "react";
import { localizeDateToYearMonthDayTime } from "../../utils/localize-date";

interface Announcement {
  id: number;
  title: string;
  url: string;
  issued_at: Date;
}

interface Props {
  getAnnouncements: (url: string) => Promise<{ announcements: unknown[] }>;
  announcements_url: string;
  new_announcement_url: string;
}

export default function MemberAnnouncements(props: Props) {
  const [announcements, setAnnouncements] = useState<Announcement[] | null>(
    null,
  );
  const [loading, setLoading] = useState(false);
  const nowDate = new Date();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { announcements } = await props.getAnnouncements(
        props.announcements_url,
      );
      setAnnouncements(announcements as Announcement[]);
      setLoading(false);
    })();
  }, [props.announcements_url]);

  return (
    <div className="page__section">
      <div className="page__container">
        <div className="user">
          <div className="user__data">
            <div className="user__data-table__action">
              <a
                href={props.new_announcement_url}
                className="button -appearance-outlined  -color-interactive -size-s"
              >
                <div className="_leading">
                  <span className="icon" data-icon="plus"></span>
                </div>
                <div className="_body">
                  お知らせ作成
                </div>
              </a>
            </div>
            <div className="user__data-table__wrapper">
              <table
                aria-label="お知らせ一覧"
                className="user__data-table"
              >
                <thead>
                  <tr>
                    <th className="-width-third">
                      送信日
                    </th>
                    <th>
                      タイトル
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {loading || announcements === null
                    ? (
                      <tr>
                        <td>
                          <span className="user__data-table__loading">
                          </span>
                        </td>
                        <td>
                          <span className="user__data-table__loading">
                          </span>
                        </td>
                      </tr>
                    )
                    : announcements.length === 0
                    ? (
                      <tr>
                        <td colSpan={2}>送信済みのお知らせはありません</td>
                      </tr>
                    )
                    : (
                      announcements.map((announcement: Announcement) => (
                        <tr key={announcement.id}>
                          <td>
                            <div>
                              {localizeDateToYearMonthDayTime(
                                announcement.issued_at,
                              )}
                              {nowDate < announcement.issued_at && (
                                <div className="user__data-table__label">
                                  送信予約
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="-overflow-ellipsis">
                            <a href={announcement.url} data-turbolinks="false">
                              {announcement.title}
                            </a>
                          </td>
                        </tr>
                      ))
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
