import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

interface EventGroup {
  id: number;
  name: string;
  enrolled: boolean;
}

interface Props {
  onCloseButtonClick: () => void;
  getEnrolledEventGroups: (url: string) => Promise<{
    form_action: string;
    event_groups: unknown[];
  }>;
  onUpdateEnrolledEventGroups: (
    url: string,
    data: unknown,
  ) => Promise<void>;
  bulkEnrollmentEventGroupsUrl: string;
  newCoursePath: string;
}

interface Inputs {
  bulk_enrollment_event_groups: {
    event_group_ids: string[];
  };
}

export default function MemberEventGroupUpdatingModal({
  onCloseButtonClick,
  getEnrolledEventGroups,
  onUpdateEnrolledEventGroups,
  bulkEnrollmentEventGroupsUrl,
  newCoursePath,
}: Props) {
  const [formAction, setFormAction] = useState("");
  const [eventGroups, setEventGroups] = useState<EventGroup[] | undefined>(
    undefined,
  );
  const handleButtonClick = () => {
    window.location.href = newCoursePath;
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isSubmitted },
  } = useForm<Inputs>({
    defaultValues: {
      bulk_enrollment_event_groups: {
        event_group_ids: (eventGroups || [])
          .filter((eventGroup) => eventGroup.enrolled)
          .map((eventGroup) => eventGroup.id.toString()),
      },
    },
  });
  const onSubmit = async (data: Inputs) => {
    if (!formAction) {
      return;
    }
    await onUpdateEnrolledEventGroups(formAction, data);
    onCloseButtonClick();
  };

  useEffect(() => {
    (async () => {
      const { event_groups, form_action } = await getEnrolledEventGroups(
        bulkEnrollmentEventGroupsUrl,
      );
      setValue(
        "bulk_enrollment_event_groups.event_group_ids",
        (event_groups || []).filter((eventGroup: EventGroup) =>
          eventGroup.enrolled
        )
          .map((eventGroup: EventGroup) => eventGroup.id.toString()),
      );
      setFormAction(form_action);
      setEventGroups(event_groups as EventGroup[]);
    })();
  }, []);

  return (
    <div className="modal-list">
      <div
        id="course-select-modal"
        className="_container modal-list__modal --visible"
        aria-labelledby="modal-title"
        aria-modal="true"
      >
        <div className="modal-header">
          <div className="_container">
            <div id="modal-title" className="_title">
              <span>コースを選択</span>
            </div>
            <div className="_nav">
              <button
                type="button"
                className="button -appearance-transparent -color-neutral -shape-circle"
                aria-label="閉じる"
                onClick={onCloseButtonClick}
              >
                <div className="_leading">
                  <span className="icon _icon" data-icon="cross" />
                </div>
              </button>
            </div>
            {eventGroups?.length > 0 && (
              <div className="_action">
                <button
                  type="button"
                  onClick={() => window.location.href = newCoursePath}
                  className="button -appearance-transparent -size-s -color-interactive"
                  aria-label="コース作成ページへのリンク"
                >
                  <div className="_leading">
                    <span className="icon" data-icon="plus"></span>
                  </div>
                  <div className="_body">
                    コース作成
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="modal-content__container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form">
              {eventGroups?.length > 0 && (
                <>
                  <div className="form__item">
                    <ul className="_list">
                      {eventGroups.map((eventGroup) => (
                        <li className="_item" key={eventGroup.id}>
                          <label htmlFor={`event-group-${eventGroup.id}`}>
                            <div className="_leading">
                              <div className="_input">
                                <input
                                  id={`event-group-${eventGroup.id}`}
                                  className="checkbox"
                                  type="checkbox"
                                  {...register(
                                    "bulk_enrollment_event_groups.event_group_ids",
                                  )}
                                  value={eventGroup.id}
                                />
                              </div>
                            </div>
                            <div className="_body">
                              <div className="_title">
                                {eventGroup.name}
                              </div>
                            </div>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="form__bottom-action">
                    <div className="form__action">
                      <ul className="button-group -column-2">
                        <li className="_item">
                          <button
                            name="button"
                            onClick={onCloseButtonClick}
                            className="button -size-l -appearance-filled -width-full"
                          >
                            <div className="_body">
                              キャンセル
                            </div>
                          </button>
                        </li>
                        <li className="_item">
                          <button
                            name="button"
                            type="submit"
                            className="button -size-l -appearance-filled -width-full -color-interactive"
                            disabled={isSubmitting || isSubmitted}
                          >
                            <div className="_body">
                              保存
                            </div>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
              {eventGroups?.length === 0 && (
                <div className="form__section">
                  <div className="form__item">
                    <p className="form__text">
                      コースがまだ作成されていません。<br />
                      コース作成後に受講者をコースへ所属させることができます。
                    </p>
                  </div>
                  <div className="form__bottom-action">
                    <div className="form__action">
                      <ul className="button-group -column-2">
                        <li className="_item">
                          <button
                            name="button"
                            onClick={onCloseButtonClick}
                            className="button -size-l -appearance-filled -width-full"
                            type="button"
                          >
                            <div className="_body">
                              キャンセル
                            </div>
                          </button>
                        </li>
                        <li className="_item">
                          <button
                            type="button"
                            onClick={() => window.location.href = newCoursePath}
                            className="button -size-l -appearance-filled -width-full -color-interactive"
                            aria-label="コース作成ページへのリンク"
                          >
                            <div className="_body">
                              コース作成
                            </div>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {!eventGroups && (
                <div className="--loading">
                  <div className="in-skeleton-interactive-list">
                    <ul className="_list">
                      <li className="_item">
                        <div>
                          <div className="_leading">
                            <div className="_input">
                              <div className="in-skeleton-checkbox"></div>
                            </div>
                          </div>
                          <div className="_body">
                            <div className="_title">
                              <span className="_skeleton"></span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="_item">
                        <div>
                          <div className="_leading">
                            <div className="_input">
                              <div className="in-skeleton-checkbox"></div>
                            </div>
                          </div>
                          <div className="_body">
                            <div className="_title">
                              <span className="_skeleton"></span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="_item">
                        <div>
                          <div className="_leading">
                            <div className="_input">
                              <div className="in-skeleton-checkbox"></div>
                            </div>
                          </div>
                          <div className="_body">
                            <div className="_title">
                              <span className="_skeleton"></span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
