import React from "react";

export default function EventItemParticipation(
  {
    event,
  },
): JSX.Element {
  return (
    <div className="event-card__participation">
      <span className="event-card__participation-label">
        参加人数：
      </span>

      <span
        className={event.event_participations.filter((
            eventParticipation,
          ) => eventParticipation.status == "going"
          ).length < event.event_participations.length
          ? "event-card__participation-numerator --has-absentee"
          : "event-card__participation-numerator"}
      >
        {event.event_participations.filter((
          eventParticipation,
        ) => eventParticipation.status == "going")
          .length}
      </span>

      <span className="event-card__participation-denominator">
        {event.event_participations.length}
      </span>
    </div>
  );
}
