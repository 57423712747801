import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import ManagementMemberPageContainer, {
  Enrollment,
  Props,
} from "./ManagementMemberPage/ManagementMemberPageContainer";
import {
  get,
  updateEnrolledEventGroups,
  updateEnrollment,
  updateRecurredBills,
} from "./ManagementMemberPage/api";
import { useToast } from "components/Toast/use-toast";
import { Toaster } from "components/Toast/toaster";

const getEnrolledEventGroups = get<{
  event_groups: unknown[];
  form_action: string;
}>;
const getRecurrenceBills = get<{
  recurrence_bills: unknown[];
  form_action: string;
}>;
const getBills = get<{ bills: unknown[] }>;
const getAnnouncements = get<{ announcements: unknown[] }>;
const getEvents = get<{ events: unknown[] }>;

export default function ManagementMemberPage(props: Props): JSX.Element {
  const [enrollmentId, setEnrollmentId] = useState<number>(null);
  const [enrollments, setEnrollments] = useState<Enrollment[]>(
    props.enrollments,
  );
  const { toast } = useToast();

  useEffect(() => {
    const [anchorKey, anchorValue] = location.hash.replace(/^#/, "").split("/");
    const anchorId = parseInt(anchorValue, 10);
    if (!isNaN(anchorId)) {
      if (anchorKey === "enrollments") {
        setEnrollmentId(anchorId);
      } else {
        const enrollment = props.enrollments.find((enrollment) =>
          enrollment.student.id === anchorId
        );
        setEnrollmentId(enrollment.id);
      }
    }

    const listener = (event: PopStateEvent) => {
      if (event.state) {
        setEnrollmentId(parseInt(event.state as string, 10));
      } else {
        setEnrollmentId(null);
      }
    };
    globalThis.addEventListener("popstate", listener);
    return () => {
      globalThis.removeEventListener("popstate", listener);
    };
  }, []);

  const handleUpdateEnrolledEventGroups = async (
    enrollmentId: number,
    url: string,
    data: unknown,
  ): Promise<void> => {
    try {
      const { message, enrolled_event_groups } =
        await updateEnrolledEventGroups(
          url,
          data,
        );
      setEnrollments((enrollments) => {
        const index = enrollments.findIndex((enrollment) =>
          enrollment.id === enrollmentId
        );
        if (index === -1) {
          Sentry.captureMessage("test", "warning");
          return enrollments;
        }

        const before = enrollments.slice(0, index);
        const after = enrollments.slice(index + 1);
        return [
          ...before,
          { ...enrollments[index], enrolled_event_groups },
          ...after,
        ];
      });
      toast({
        title: message,
        description: "",
        children: null,
        variant: "default",
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handleUpdateRecurredBills = async (
    enrollmentId: number,
    url: string,
    data: unknown,
  ): Promise<void> => {
    try {
      const { message, recurrence_bills } = await updateRecurredBills(
        url,
        data,
      );
      setEnrollments((enrollments) => {
        const index = enrollments.findIndex((enrollment) =>
          enrollment.id === enrollmentId
        );
        if (index === -1) {
          Sentry.captureMessage("test", "warning");
          return enrollments;
        }

        const before = enrollments.slice(0, index);
        const after = enrollments.slice(index + 1);
        return [
          ...before,
          { ...enrollments[index], recurrence_bills },
          ...after,
        ];
      });
      toast({
        title: message,
        description: "",
        children: null,
        variant: "default",
      });
    } catch (e) {
      Sentry.captureException(e);

      if (e.response.status === 422) {
        window.location.href = "/422.html";
      }
    }
  };

  const handleUpdateEnrollment = async (
    enrollmentId: number,
    data: { enrollment: { description: string } },
  ): Promise<void> => {
    try {
      const enrollment = enrollments.find((enrollment) =>
        enrollment.id === enrollmentId
      );
      if (typeof enrollment === "undefined") {
        Sentry.captureMessage("test", "warning");
        throw new Error();
      }

      const { message } = await updateEnrollment(enrollment.edit_url, data);
      setEnrollments((enrollments) => {
        const index = enrollments.findIndex((enrollment) =>
          enrollment.id === enrollmentId
        );
        if (index === -1) {
          Sentry.captureMessage("test", "warning");
          return enrollments;
        }

        const before = enrollments.slice(0, index);
        const after = enrollments.slice(index + 1);
        return [
          ...before,
          { ...enrollments[index], ...data.enrollment },
          ...after,
        ];
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <>
      <ManagementMemberPageContainer
        {...props}
        enrollments={enrollments}
        getEnrolledEventGroups={getEnrolledEventGroups}
        getRecurrenceBills={getRecurrenceBills}
        getBills={getBills}
        getAnnouncements={getAnnouncements}
        getEvents={getEvents}
        handleUpdateEnrolledEventGroups={handleUpdateEnrolledEventGroups}
        handleUpdateRecurredBills={handleUpdateRecurredBills}
        handleUpdateEnrollment={handleUpdateEnrollment}
        onFloatingViewShow={(id: string) => {
          history.pushState(id, "", `#enrollments/${id}`);
        }}
        onFloatingViewClose={() => {
          history.pushState(null, "", "#");
        }}
        enrollmentId={enrollmentId}
      />
      <Toaster />
    </>
  );
}
