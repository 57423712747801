import React from "react";
import "./TabHeader.scss";

export default function TabHeader(
  {
    classroom,
  },
): JSX.Element {
  return (
    <div className="page__page-head">
      <h1 className="page__title --level-1">
        {classroom.name}
      </h1>
    </div>
  );
}
