import React from "react";
import PersonStatus from "components/Schedules/PersonStatus";

export default function EventItemFooter(
  {
    event,
    person_avatars,
  },
): JSX.Element {
  return (
    <div className="event-card__footer">
      <ul className="home-event-card__person-list">
        {event.event_participations.map((
          eventParticipation,
        ) => (
          <li
            key={eventParticipation.id}
            className="home-event-card__person-item"
          >
            <div
              className="home-event-card__person-avatar"
              dangerouslySetInnerHTML={{
                __html: person_avatars[eventParticipation.id],
              }}
            />

            <div className="home-event-card__person-name">
              {eventParticipation.student.name}
            </div>

            <div className="home-event-card__person-status">
              <PersonStatus status={eventParticipation.status} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
