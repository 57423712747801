export async function get<T>(url: string): Promise<T> {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await response.json();
}

export async function updateEnrolledEventGroups(url: string, data: unknown) {
  const csrfToken =
    document.querySelector<HTMLMetaElement>('[name="csrf-token"]').content;
  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(data),
    redirect: "manual",
  });
  if (response.ok) {
    const { message, enrolled_event_groups } = await response.json();
    return { message, enrolled_event_groups };
  } else {
    const { message } = await response.json();
    return { message };
  }
}

export async function updateRecurredBills(url: string, data: unknown) {
  const csrfToken =
    document.querySelector<HTMLMetaElement>('[name="csrf-token"]').content;
  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(data),
    redirect: "manual",
  });
  if (response.ok) {
    const { message, recurrence_bills } = await response.json();
    return { message, recurrence_bills };
  } else {
    const errorData = await response.json();
    const error = new Error(errorData.message);
    error.name = "ApiError";
    (error as ApiError).response = response;
    throw error;
  }
}

export async function updateEnrollment(
  url: string,
  data: { enrollment: { description: string } },
) {
  const csrfToken =
    document.querySelector<HTMLMetaElement>('[name="csrf-token"]').content;
  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(data),
    redirect: "manual",
  });
  const { message } = await response.json();
  return { message };
}

interface ApiError extends Error {
  response: Response;
}
