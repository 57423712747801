import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { ModalBackButton } from "components/ModalBackButton";
import { useApi } from "hooks/api";
import * as Sentry from "@sentry/browser";
import showingSnackBar from "packs/snackbar";

interface Props {
  bill_item_templates: BillItemTemplate[];
  management_classroom_bill_item_templates_path: string;
}

interface BillItemTemplate {
  id: number;
  name: string;
  amount: number;
}

export default function BillItemTemplatesPage(props: Props): JSX.Element {
  const { control, register, handleSubmit, setValue, formState: { errors } } =
    useForm({
      defaultValues: {
        bill_item_templates: props.bill_item_templates,
      },
      mode: "all",
    });
  const { patch } = useApi();
  const { fields, append, remove } = useFieldArray(
    {
      control,
      name: "bill_item_templates",
    },
  );
  const addBillItemTemplate = () =>
    append({ id: undefined, name: undefined, amount: undefined }, {
      shouldFocus: true,
    });
  const onSubmit = async (data) => {
    const { bill_item_templates, message, response } = await patch(
      props.management_classroom_bill_item_templates_path,
      {
        bill_item_templates: data,
      },
    );
    if (response.ok) {
      setValue("bill_item_templates", bill_item_templates);
    } else {
      // TODO: この分岐に入ると csrfToken が無効なものになる問題をどうにかする
      Sentry.captureMessage(
        `メンバー別コース選択モーダルのリクエストでエラーが発生しました。form_action: "${props.management_classroom_bill_item_templates_path}" data: "${
          JSON.stringify(data)
        }"`,
      );
    }
    // TODO: SnackBar のReactコンポーネントを用意して VanillaJS 経由でDOM操作するのを辞める
    showingSnackBar(message);
  };

  return (
    <>
      <h1 className="visually-hidden">
        請求項目リスト
      </h1>

      <div className="modal-content">
        <header className="modal-header">
          <div className="_title">
            請求項目リスト
          </div>

          <div className="_container">
            <div className="_nav">
              <ModalBackButton />
            </div>
          </div>
        </header>

        <div className="modal-content__container">
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <div className="form__section">
              <table
                id="bill-item-template-table"
                aria-label="請求項目リスト"
                className="bill-item-template"
              >
                <thead>
                  <tr>
                    <th role="columnheader" scope="col">
                      項目名
                    </th>
                    <th role="columnheader" scope="col" className="-align-end">
                      金額
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((billItemTemplate, index) => (
                    <>
                      <tr
                        key={`${billItemTemplate.id}-${index}`}
                      >
                        <td>
                          <input
                            type="hidden"
                            {...register(`bill_item_templates.${index}.id`)}
                            name="bill_item_templates[][id]"
                            id={`bill-item-template-${index}-id`}
                          />
                          <div className="simple-textfield -appearance-filled -width-full">
                            <input
                              {...register(
                                `bill_item_templates.${index}.name`,
                                {
                                  minLength: {
                                    value: 1,
                                    message:
                                      "項目名は1文字以上で入力してください",
                                  },
                                  maxLength: {
                                    value: 140,
                                    message:
                                      "項目名は140文字以内で入力してください",
                                  },
                                },
                              )}
                              id={`bill-item-template-${index}-name`}
                              className="_input"
                              placeholder="項目名"
                              required
                            />
                          </div>
                        </td>
                        <td className="-width-short">
                          <div className="simple-textfield -appearance-filled">
                            <input
                              {...register(
                                `bill_item_templates.${index}.amount`,
                                {
                                  min: {
                                    value: 300,
                                    message:
                                      "金額は300円以上で入力してください",
                                  },
                                  max: {
                                    value: 300000,
                                    message:
                                      "金額は300,000円以内で入力してください",
                                  },
                                },
                              )}
                              type="number"
                              id={`bill-item-template-${index}-amount`}
                              inputMode="numeric"
                              className="_input -align-end"
                              placeholder="金額"
                              max="300000"
                              required
                            />
                          </div>
                        </td>
                        <td className="-align-end">
                          <button
                            type="button"
                            aria-label="削除"
                            className="button -appearance-transparent -size-m -color-negative"
                            onClick={() => remove(index)}
                          >
                            <div className="_leading">
                              <span className="icon _icon" data-icon="cross">
                              </span>
                            </div>
                          </button>
                        </td>
                      </tr>
                      <tr className="bill-item-template__message">
                        <td className="_message" colSpan={3}>
                          {errors.bill_item_templates?.[index]?.name && (
                            <div className="_text">
                              {errors.bill_item_templates[index].name.message}
                            </div>
                          )}
                          {errors.bill_item_templates?.[index]?.amount && (
                            <div className="_text">
                              {errors.bill_item_templates[index].amount
                                .message}
                            </div>
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
              <div className="bill-item-template__action">
                <button
                  type="button"
                  className="button -appearance-transparent -size-m -width-full -color-interactive"
                  onClick={addBillItemTemplate}
                >
                  <div className="_leading">
                    <span className="icon _icon" data-icon="plus_on_circle">
                    </span>
                  </div>
                  <div className="_body">
                    項目を追加
                  </div>
                </button>
              </div>
            </div>

            <div className="form__bottom-action">
              <div className="form__action">
                <button
                  onClick={handleSubmit(onSubmit)}
                  type="button"
                  className="button -size-l -appearance-filled -width-full -color-interactive"
                >
                  <div className="_body">
                    保存
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
