import React, { useEffect, useState } from "react";
import {
  localizeDateToMiddleString,
  localizeDateToShortString,
} from "../../utils/localize-date";

interface Bill {
  id: number;
  name: string;
  status:
    | "requires_payment"
    | "payment_waiting_for_approval"
    | "payment_approved"
    | "marked_as_paid"
    | "not_issued"
    | "overdue"
    | "disabled";
  url: string;
  amount: number;
  issued_at: string;
  deadline: string;
  recurrence: boolean;
  payment?: {
    tried_at: string;
    approved_at: string;
    marked_as_paid_at: string;
    method_type: "none" | "card" | "konbini" | "direct_debit";
  };
}

interface Props {
  getBills: (url: string) => Promise<{ bills: unknown[] }>;
  bills_url: string;
  new_bill_url: string;
}

export default function MemberBills(props: Props) {
  const [bills, setBills] = useState<Bill[] | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { bills } = await props.getBills(props.bills_url);
      setBills(bills as Bill[]);
      setLoading(false);
    })();
  }, [props.bills_url]);

  return (
    <div className="page__section">
      <div className="page__container">
        <div className="user">
          <div className="user__data">
            <div className="user__data-table__action">
              <a
                href={props.new_bill_url}
                className="button -appearance-outlined  -color-interactive -size-s"
              >
                <div className="_leading">
                  <span className="icon" data-icon="plus"></span>
                </div>
                <div className="_body">
                  請求作成
                </div>
              </a>
            </div>
            <div className="user__data-table__wrapper">
              <table aria-label="請求一覧" className="user__data-table">
                <thead>
                  <tr>
                    <th>
                      項目名
                    </th>
                    <th className="-align-end">
                      請求額
                    </th>
                    <th>
                      請求日
                    </th>
                    <th>
                      期日
                    </th>
                    <th>
                      支払日
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {loading || bills === null
                    ? (
                      <tr>
                        <td>
                          <span className="user__data-table__loading"></span>
                        </td>
                        <td>
                          <span className="user__data-table__loading"></span>
                        </td>
                        <td>
                          <span className="user__data-table__loading"></span>
                        </td>
                        <td>
                          <span className="user__data-table__loading"></span>
                        </td>
                        <td>
                          <span className="user__data-table__loading"></span>
                        </td>
                      </tr>
                    )
                    : bills.length === 0
                    ? (
                      <tr>
                        <td colSpan={5}>送信済みの請求はありません</td>
                      </tr>
                    )
                    : (
                      bills.map((bill) => (
                        <tr key={bill.id}>
                          <td className="-overflow-ellipsis">
                            <a href={bill.url} data-turbolinks="false">
                              {bill.recurrence && "［定期請求］"}
                              {bill.name}
                            </a>
                          </td>
                          <td className="-align-end">
                            {bill.amount}円
                          </td>
                          <td>
                            {localizeDateToMiddleString(bill.issued_at)}
                          </td>
                          <td>
                            {localizeDateToMiddleString(bill.deadline)}
                          </td>
                          <td>
                            {(bill.status === "payment_waiting_for_approval" &&
                              bill.payment?.method_type === "direct_debit") && (
                              <span className="user__data-table__status --notice">
                                <span
                                  className="icon"
                                  data-icon="ellipsis_horizontal_on_circle"
                                />
                                振替待ち
                              </span>
                            )}

                            {bill.status === "requires_payment" && (
                              <span className="user__data-table__status --notice">
                                <span
                                  className="icon"
                                  data-icon="ellipsis_horizontal_on_circle"
                                />
                                未入金
                                {bill.payment?.method_type === "konbini" &&
                                  "（コンビニ払い）"}
                              </span>
                            )}

                            {(bill.status === "payment_waiting_for_approval" &&
                              bill.payment?.method_type !== "direct_debit") && (
                              <>
                                <span className="user__data-table__status --notice">
                                  <span
                                    className="icon"
                                    data-icon="ellipsis_horizontal_on_circle"
                                  />
                                  決済中
                                </span>
                                <span className="_date">
                                  ｜
                                  {localizeDateToShortString(
                                    bill.payment.tried_at,
                                  )}
                                </span>
                              </>
                            )}

                            {bill.status === "payment_approved" && (
                              <>
                                <span className="user__data-table__status --positive">
                                  <span
                                    className="icon"
                                    data-icon="check_on_circle"
                                  >
                                  </span>
                                  {localizeDateToShortString(
                                    bill.payment?.tried_at ||
                                      bill.payment?.approved_at,
                                  )}
                                  {bill.payment?.method_type === "konbini" &&
                                    "（コンビニ払い）"}
                                </span>
                              </>
                            )}

                            {bill.status === "marked_as_paid" && (
                              <>
                                <span className="user__data-table__status --positive">
                                  <span
                                    className="icon"
                                    data-icon="check_on_circle"
                                  />
                                  支払いマーク済み
                                </span>
                                <span className="_date">
                                  ｜
                                  {localizeDateToShortString(
                                    bill.payment?.marked_as_paid_at,
                                  )}
                                </span>
                              </>
                            )}

                            {/* TODO: スタイリング */}
                            {bill.status === "not_issued" && (
                              <span className="user__data-table__status">
                                <span
                                  className="icon"
                                  data-icon="ellipsis_horizontal_on_circle"
                                />
                                集金予約
                              </span>
                            )}

                            {/* TODO: スタイリング */}
                            {bill.status === "disabled" && (
                              <span className="user__data-table__status">
                                <span
                                  className="icon"
                                  data-icon="ellipsis_horizontal_on_circle"
                                />
                                無効
                              </span>
                            )}

                            {bill.status === "overdue" && (
                              <>
                                <span className="user__data-table__status --negative">
                                  <span
                                    className="icon"
                                    data-icon="cross_on_circle"
                                  />
                                  期日超過
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
