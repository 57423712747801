import React, { ReactNode } from "react";

interface Props {
  title?: string;
  description?: string;
  onCloseButtonClick: () => void;
  children?: ReactNode;
}

export default function HelpModal(
  { title, onCloseButtonClick, children }: Props,
) {
  return (
    <div
      className="dialog --visible"
      role="dialog"
      aria-labelledby="help-modal__title"
      aria-modal="true"
    >
      <div className="_shadow" onClick={onCloseButtonClick}></div>
      <div className="_wrapper">
        <div className="_container">
          <div className="_header">
            {title && (
              <h2 id="help-modal__title" className="_title">
                {title}
              </h2>
            )}
            <div className="_switch">
              <button
                type="button"
                className="button -appearance-transparent -color-neutral -shape-circle"
                aria-label="閉じる"
                onClick={onCloseButtonClick}
              >
                <div className="_leading">
                  <span className="icon _icon" data-icon="cross" />
                </div>
              </button>
            </div>
          </div>
          <div className="_body">
            <div className="help-modal">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
