import React, { useState } from "react";
import AnnouncementFormAddresseeSelectorView from "./AddresseeSelectorView";
import TextAvatar from "../TextAvatar";

export interface EventGroup {
  id: number;
  name: string;
  root: boolean;
}

export interface Enrollment {
  id: number;
  student: {
    id: number;
    name: string;
  };
  event_groups: EventGroup[];
  allowed_to_settle_automatically: boolean;
}

interface Props {
  selectedStudentIds: number[];
  onChangeStudentIds: (selectedStudentIds: number[]) => void;
  classroom_event_groups: EventGroup[];
  enrollments: Enrollment[];
}

export default function AnnouncementFormAddresseeSelector(
  {
    selectedStudentIds,
    onChangeStudentIds,
    classroom_event_groups,
    enrollments,
  }: Props,
): JSX.Element {
  const [open, setOpen] = useState(false);
  const [selectedEventGroupIds, setSelectedEventGroupIds] = useState<number[]>(
    [],
  );

  return (
    <div className="addressee-select">
      <div
        className="_switch"
        role="button"
        tabIndex={0}
        aria-label="対象を選択"
        onClick={() => setOpen(true)}
      >
        <div className="_switch-label">
          <div>{selectedStudentIds.length}人</div>
        </div>
        <div className="_switch-trailing">
          <span className="icon" data-icon="arrow_drop_down" />
        </div>
      </div>

      <div className="form__body">
        <div className="form__body-item addressee-list">
          <ul className="addressee-list__list --align-left -size-s">
            {selectedEventGroupIds.map((id) => {
              const eventGroup = classroom_event_groups.find((eventGroup) => {
                return eventGroup.id === id;
              });
              const eventGroupEnrollments = enrollments.filter((enrollment) => {
                return enrollment.event_groups.some((g) =>
                  g.id === eventGroup.id
                );
              });
              const total = eventGroupEnrollments.length;
              const count = eventGroupEnrollments.filter((enrollment) => {
                return selectedStudentIds.includes(enrollment.student.id);
              }).length;
              return (
                <li key={id} className="addressee-list__item">
                  <div className="addressee-list__body">
                    <span>{eventGroup.name}</span>
                    <b>（{count}/{total}人）</b>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <ul className="_list addressee-selected__list">
        {enrollments.filter((enrollment) =>
          selectedStudentIds.includes(enrollment.student.id)
        ).map((enrollment) => (
          <li className="_item" key={enrollment.id}>
            <div>
              <div className="_avatar">
                <TextAvatar name={enrollment.student.name} size="s" icon />
              </div>
              <div className="_body">
                <div className="_title">
                  {enrollment.student.name}
                </div>
                <div className="_description">
                  {enrollment.event_groups.map((eventGroup) => eventGroup.name)
                    .join(", ")}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {open && (
        <AnnouncementFormAddresseeSelectorView
          classroom_event_groups={classroom_event_groups}
          enrollments={enrollments}
          onCloseButtonClick={() => setOpen(false)}
          selectedStudentIds={selectedStudentIds}
          setSelectedStudentIds={onChangeStudentIds}
          selectedEventGroupIds={selectedEventGroupIds}
          setSelectedEventGroupIds={setSelectedEventGroupIds}
        />
      )}
    </div>
  );
}
