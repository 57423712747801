import React from "react";
import CoverStripe from "../CoverStripe";
import { colorClassName } from "utils/color-class-name";

export default function Cover(
  {
    classroom,
    editPath,
  },
): JSX.Element {
  return (
    <div className="cover -align-left">
      <a
        className="_link"
        href={editPath}
      >
        <div className="_media">
          {classroom.coverImage
            ? <img src={classroom.coverImage} />
            : <CoverStripe classroomName={classroom.name[0]} />}
        </div>
      </a>

      <div className="_container">
        <div className="_avatar">
          <a
            className="button -appearance-transparent -size-xl"
            href={editPath}
          >
            <div className="_leading">
              {classroom.icon
                ? (
                  <div className="avatar -size-l">
                    <img src={classroom.icon} />
                  </div>
                )
                : (
                  <div
                    className={`text-avatar -size-l ${
                      colorClassName(classroom.name[0])
                    }`}
                  >
                    {classroom.name[0]}
                  </div>
                )}
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
