import React, { useState } from "react";
import { eachMinuteOfInterval, format, startOfDay } from "date-fns";

import BillAddresseeSelector from "./BillAddresseeSelector";
import Dialog from "./Dialog";
import ErrorBanner from "./ErrorBanner";
import Turbolinks from "turbolinks";
import { numberFormat } from "../utils/number-format";
import { useApi } from "../hooks/api";
import { useForm } from "react-hook-form";
import { timeOptions } from "utils/selectable-time-options";
import { useToast } from "components/Toast/use-toast";
import { Toaster } from "components/Toast/toaster";

interface Props {
  form_path: string;
  form_method: string;
  attributes: Inputs;
  errors: string[];
  classroom_event_groups;
  enrollments;
  bill_item_templates: BillItemTemplate[];
  management_classroom_bill_item_templates_path: string;
}

interface BillItemTemplate {
  id: number;
  name: string;
  amount: number;
}

interface Inputs {
  name: string;
  amount: number;
  save_as_bill_item_template: boolean;
  issued_date: string;
  issued_time: string;
  deadline_date: string;
  deadline_time: string;
  student_ids: number[];
  draft: boolean;
}

export default function BillForm(props: Props): JSX.Element {
  const [comboBoxVisible, setComboBoxVisible] = useState(false);
  const [submitDialogVisible, setSubmitDialogVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm<Inputs>({
    defaultValues: props.attributes,
    mode: "all",
  });
  const { requestWithCsrfToken } = useApi();

  const handleFocus = () => {
    setComboBoxVisible(true);
  };
  const handleBlur = (event) => {
    const isButtonFocused = event.relatedTarget &&
      event.relatedTarget.tagName === "BUTTON";
    // フォーカスが外れたが、請求項目リストのボタンに移動した場合は処理をスキップ
    if (isButtonFocused) return;
    setComboBoxVisible(false);
  };
  const handleClick = () => {
    setComboBoxVisible((prevValue) => !prevValue);
  };

  const handleBillItemTemplateClick = (event) => {
    const targetBillItemTemplate = props.bill_item_templates.find(
      (bill_item_template) =>
        bill_item_template.id === Number(event.target.dataset.id),
    );
    setValue("name", targetBillItemTemplate.name);
    setValue("amount", targetBillItemTemplate.amount);
    setValue("save_as_bill_item_template", false);
    setComboBoxVisible(false);
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const { redirect_url, message, response } = await requestWithCsrfToken({
      form_action: props.form_path,
      form_method: props.form_method,
      data: {
        bill_group_form: data,
      },
    });
    if (response.ok) {
      Turbolinks.visit(redirect_url);
    } else {
      toast({
        title: message,
        description: "",
        children: null,
        variant: "destructive",
      });
      setLoading(false);
    }
  });

  const handleDraftSubmit = async () => {
    await trigger();
    setValue("draft", true);
    await onSubmit();
  };

  const handleToggleSubmitDialog = async () => {
    await trigger();
    setValue("draft", false);
    if (isValid) {
      setSubmitDialogVisible(true);
    }
  };

  return (
    <form
      onSubmit={onSubmit}
      action={props.form_path}
      method={props.form_method}
      id="bill-form"
    >
      <ErrorBanner
        errors={props.errors}
      />

      <div className="modal-content__container">
        <div className="form">
          <div className="form__section">
            <div className="form__item">
              <div className="form__control">
                <span className="textfield -width-full combo-box">
                  <div
                    role="button"
                    className="combo-box__trailing"
                    onClick={handleClick}
                  >
                    <span className="icon" data-icon="arrow_drop_down"></span>
                  </div>
                  <input
                    {...register("name", {
                      required: "項目名を入力してください",
                      maxLength: {
                        value: 140,
                        message: "項目名は140文字以内で入力してください",
                      },
                      onBlur: handleBlur,
                    })}
                    onFocus={handleFocus}
                    className="_input"
                    placeholder="項目名［必須］"
                    id="js-bill-group-name"
                  />
                  <label className="_label" htmlFor="js-bill-group-name">
                    項目名［必須］
                  </label>
                </span>

                {comboBoxVisible && (
                  <>
                    <div
                      className="combo-box__list"
                      role="listbox"
                      aria-hidden="false"
                    >
                      {props.bill_item_templates.map((billItemTemplate) => (
                        <button
                          key={billItemTemplate.id}
                          data-id={billItemTemplate.id}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={handleBillItemTemplateClick}
                          type="button"
                          role="option"
                        >
                          {billItemTemplate.name}
                          <span
                            data-id={billItemTemplate.id}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={handleBillItemTemplateClick}
                          >
                            {numberFormat(billItemTemplate.amount)}円
                          </span>
                        </button>
                      ))}
                    </div>
                  </>
                )}

                <div
                  id="js-bill-group-name-validation-message"
                  className="form__error"
                  aria-live="polite"
                >
                  {errors.name?.message}
                </div>
              </div>
            </div>

            <div className="form__item">
              <div className="form__control">
                <span className="textfield -width-half">
                  <input
                    {...register("amount", {
                      required: "金額を入力してください",
                      min: {
                        value: 300,
                        message: "金額は300円以上で入力してください",
                      },
                      max: {
                        value: 300000,
                        message: "金額は300,000円以内で入力してください",
                      },
                    })}
                    inputMode="numeric"
                    className="_input"
                    placeholder="金額［必須］"
                    id="js-bill-group-amount"
                  />
                  <label
                    className="_label"
                    aria-describedby="hint-amount"
                    htmlFor="js-bill-group-amount"
                  >
                    金額［必須］
                  </label>
                </span>
                <span className="form__unit">
                  円
                </span>
                <div
                  id="js-bill-group-amount-validation-message"
                  className="form__error"
                  aria-live="polite"
                >
                  {errors.amount?.message}
                </div>
              </div>
            </div>
            <div id="hint-amount" className="form__item-note">
              ※ 300円以上30万円以下の金額を半角数字のみで入力（例：3000）
            </div>

            <div className="form__item">
              <div className="form__control --has-checkfield">
                <div className="checkfield">
                  <label className="_item" htmlFor="save-as-bill-item-template">
                    <input
                      {...register("save_as_bill_item_template")}
                      type="checkbox"
                      id="save-as-bill-item-template"
                      className="checkbox"
                    />
                    <span className="_label">
                      項目名と金額の組み合わせを保存する
                    </span>
                  </label>
                  <a
                    className="button -appearance-transparent -color-interactive -size-s"
                    href={props.management_classroom_bill_item_templates_path}
                  >
                    <div className="_body">
                      請求項目リスト
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="form__section">
            <h2 className="form__title">
              <span className="form__title-trailing">
                <span className="icon" data-icon="calendar"></span>
              </span>
              <span className="form__title-text">
                日付
              </span>
            </h2>

            <div className="form__item">
              <label className="form__label" htmlFor="issued_date">
                請求日
              </label>
              <div className="form__control">
                <div className="textfield-group textfield-group--datetime">
                  <div className="textfield-group__item">
                    <span className="simple-textfield -size-l -appearance-filled -width-full">
                      <input
                        type="date"
                        id="issued_date"
                        {...register("issued_date", {
                          required: "請求日を入力してください",
                        })}
                        className="_input"
                        placeholder={format(new Date(), "yyyy/MM/dd")}
                      />
                    </span>
                  </div>
                  <div className="textfield-group__item">
                    <span className="simple-textfield -size-l -appearance-filled -width-full">
                      <input
                        type="time"
                        id="issued_time"
                        {...register("issued_time", {
                          required: "請求時刻を入力してください",
                        })}
                        className="_input"
                        list="issued_at-time-list"
                        placeholder="00:00"
                      />
                      <datalist id="issued_at-time-list">
                        {timeOptions.map((time) => (
                          <option key={time} value={time} />
                        ))}
                      </datalist>
                    </span>
                  </div>
                </div>
                <div
                  className="form__error"
                  aria-live="polite"
                >
                  {errors.issued_date?.message}
                </div>
                <div
                  className="form__error"
                  aria-live="polite"
                >
                  {errors.issued_time?.message}
                </div>
              </div>
            </div>

            <div className="form__item">
              <label htmlFor="deadline_date" className="form__label">
                期日
              </label>
              <div className="form__control">
                <div className="textfield-group textfield-group--datetime">
                  <div className="textfield-group__item">
                    <span className="simple-textfield -size-l -appearance-filled -width-full">
                      <input
                        type="date"
                        id="deadline_date"
                        {...register("deadline_date", {
                          required: "期日を入力してください",
                        })}
                        className="_input"
                        placeholder={format(new Date(), "yyyy/MM/dd")}
                      />
                    </span>
                  </div>
                  <div className="textfield-group__item">
                    <span className="simple-textfield -size-l -appearance-filled -width-full">
                      <input
                        type="time"
                        id="deadline_time"
                        {...register("deadline_time", {
                          required: "期日時刻を入力してください",
                        })}
                        className="_input"
                        list="deadline-time-list"
                        placeholder="00:00"
                      />
                      <datalist id="deadline-time-list">
                        {timeOptions.map((time) => (
                          <option key={time} value={time} />
                        ))}
                      </datalist>
                    </span>
                  </div>
                </div>
                <div
                  className="form__error"
                  aria-live="polite"
                >
                  {errors.deadline_date?.message}
                </div>
                <div
                  className="form__error"
                  aria-live="polite"
                >
                  {errors.deadline_time?.message}
                </div>
              </div>
            </div>
          </div>

          <div className="form__section">
            <h2 className="form__title">
              <span className="form__title-trailing">
                <span className="icon" data-icon="person"></span>
              </span>
              <span className="form__title-text">請求対象</span>
            </h2>

            <div className="form__item">
              <div className="form__control">
                <BillAddresseeSelector
                  inputRegister={() => register(`student_ids`)}
                  setSelectedStudentIdsValue={(selectedStudentIds: number[]) =>
                    setValue("student_ids", selectedStudentIds)}
                  form_name="bill_group_form"
                  attributes_name="bills_attributes"
                  addressee_attribute_name="addressee_id"
                  show_automatic_settlement_enabled={true}
                  classroom_event_groups={props.classroom_event_groups}
                  enrollments={props.enrollments}
                  selected_student_ids={props.attributes.student_ids}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form__bottom-action">
          <div className="form__action">
            <ul className="button-group -direction-column">
              <li className="_item">
                <input
                  {...register("draft")}
                  type="hidden"
                  id="bill_group_draft"
                />
                <button
                  type="button"
                  className="button -size-l -appearance-transparent -width-full -color-interactive js-draft-submit"
                  onClick={handleDraftSubmit}
                  disabled={loading}
                >
                  <div className="_body">下書き保存</div>
                </button>
              </li>
              <li className="_item">
                <button
                  type="button"
                  className="button -size-l -appearance-filled -width-full -color-interactive js-dialog-v2-toggle-switch"
                  onClick={handleToggleSubmitDialog}
                >
                  <div className="_body">送信</div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {submitDialogVisible && isValid && (
        <Dialog
          onCloseButtonClick={() => setSubmitDialogVisible(false)}
          title="請求を送信しますか？"
          description="送信すると教室から請求が届いたことが受講者に通知されます。請求日が未来の日付の場合は、送信予約状態となります。"
        >
          <button
            type="submit"
            className="button -appearance-transparent -size-m -color-interactive js-submit-to-issue"
            data-target-form="bill-form"
            data-target-hidden-field="bill_group_draft"
            disabled={loading}
          >
            <div className="_body">送信</div>
          </button>
        </Dialog>
      )}
      <Toaster />
    </form>
  );
}
