import React, { useEffect, useState } from "react";
import { format } from "date-fns";

const selectableByMonthDay = [
  { day: 1, label: "1日" },
  { day: 5, label: "5日" },
  { day: 10, label: "10日" },
  { day: 15, label: "15日" },
  { day: 20, label: "20日" },
  { day: 25, label: "25日" },
  { day: 31, label: "末日" },
];
const selectableDeadlineAdditionalMonth = [
  { additionalMonth: 0, label: "同月" },
  { additionalMonth: 1, label: "翌月" },
];
const range = (start, end) =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);
const selectableDeadlineMonthDay = range(1, 31).map((i) => {
  return { day: i, label: i < 31 ? `${i}日` : "末日" };
});

interface Props {
  by_month_day: number;
  deadline_additional_month: number;
  deadline_month_day: number;
}

export default function RecurrenceBillDateSelector({
  by_month_day,
  deadline_additional_month,
  deadline_month_day,
}: Props): JSX.Element {
  const [byMonthDay, setByMonthDay] = useState(by_month_day);
  const [deadlineAdditionalMonth, setDeadlineAdditionalMonth] = useState(
    deadline_additional_month,
  );
  const [deadlineMonthDay, setDeadlineMonthDay] = useState(deadline_month_day);
  const [nextIssuedDate, setNextIssuedDate] = useState<string | null>(null);
  const [nextDeadlineDate, setNextDeadlineDate] = useState<string | null>(null);
  const hasDeadlineError = deadlineAdditionalMonth === 0 &&
    deadlineMonthDay < byMonthDay;

  useEffect(() => {
    // TODO: いくつかの画面で必要になったらフロント側で計算できるようにする
    const setDates = async () => {
      const params = new URLSearchParams({
        by_month_day: byMonthDay.toString(),
        deadline_additional_month: deadlineAdditionalMonth.toString(),
        deadline_month_day: deadlineMonthDay.toString(),
      });
      const response = await fetch(
        `/api/v1/recurrence_bills/calculate_dates?${params}`,
        {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      if (response.ok) {
        const data = await response.json();
        setNextIssuedDate(data.next_issued_date);
        setNextDeadlineDate(data.next_deadline_date);
      }
    };
    void setDates();
  }, [byMonthDay, deadlineAdditionalMonth, deadlineMonthDay]);

  return (
    <div>
      <h2 className="form__title">
        <span className="form__title-trailing">
          <span className="icon" data-icon="calendar" />
        </span>
        <span className="form__title-text">
          請求サイクル
        </span>
      </h2>

      <div className="form__item">
        <h3 className="form__label">
          請求日
        </h3>
        <div className="form__control">
          <div className="form__control-select">
            毎月
            <span className="select -appearance-filled -size-s -width-auto">
              <select
                className="_select"
                name="recurrence_bill_form[by_month_day]"
                id="recurrence_bill_form_by_month_day"
                value={byMonthDay}
                onChange={(event) => {
                  setByMonthDay(Number(event.target.value));
                }}
                aria-controls="next-issued-date"
              >
                {selectableByMonthDay.map(({ day, label }) => {
                  return (
                    <option value={day} key={day}>
                      {label}
                    </option>
                  );
                })}
              </select>
              <span className="icon _icon" />
            </span>
          </div>
        </div>
      </div>

      <div className="form__item">
        <h3 className="form__label">
          期日
        </h3>
        <div className="form__control -increase-indent">
          <div className="form__control-select">
            請求日の
            <span className="select -appearance-filled -size-s -width-auto">
              <select
                className="_select"
                name="recurrence_bill_form[deadline_additional_month]"
                id="recurrence_bill_form_deadline_additional_month"
                value={deadlineAdditionalMonth}
                onChange={(event) => {
                  setDeadlineAdditionalMonth(Number(event.target.value));
                }}
                aria-controls="deadline-errors next-deadline-date"
              >
                {selectableDeadlineAdditionalMonth.map(
                  ({ additionalMonth, label }) => {
                    return (
                      <option value={additionalMonth} key={additionalMonth}>
                        {label}
                      </option>
                    );
                  },
                )}
              </select>
              <span className="icon _icon" />
            </span>
            <span className="select -appearance-filled -size-s -width-auto">
              <select
                className="_select"
                name="recurrence_bill_form[deadline_month_day]"
                id="recurrence_bill_form_deadline_month_day"
                value={deadlineMonthDay}
                onChange={(event) => {
                  setDeadlineMonthDay(Number(event.target.value));
                }}
                aria-controls="deadline-errors next-deadline-date"
              >
                {selectableDeadlineMonthDay.map(({ day, label }) => {
                  return (
                    <option value={day} key={day}>
                      {label}
                    </option>
                  );
                })}
              </select>
              <span className="icon _icon" />
            </span>
          </div>
          <div id="deadline-errors" className="form__error" aria-live="polite">
            {hasDeadlineError &&
              ("期日は請求日より後になるように設定してください")}
          </div>
        </div>
      </div>

      <div className="form__item">
        <h3 className="form__label">
          次回請求
        </h3>
        <dl className="form__result">
          <div className="form__result-item">
            <dt>
              請求日：
            </dt>
            <dd id="next-issued-date" aria-live="polite">
              {nextIssuedDate
                ? format(new Date(nextIssuedDate), "yyyy年L月d日 HH:mm")
                : "YYYY年MM月DD日 HH:MM"}
            </dd>
          </div>
          <div className="form__result-item">
            <dt>
              期日：
            </dt>
            <dd id="next-deadline-date" aria-live="polite">
              {nextDeadlineDate
                ? format(new Date(nextDeadlineDate), "yyyy年L月d日 HH:mm")
                : "YYYY年MM月DD日 HH:MM"}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
