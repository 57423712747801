import React, { useEffect, useState } from "react";

import MemberCustomFieldItem from "../ManagementMemberPage/MemberCustomFieldItem";
import Snackbar from "../Snackbar";
import { useApi } from "hooks/api";
import { CustomField } from "components/ManagementMemberPage/MemberDetails";

interface Props {
  customFields: CustomField[];
  customFieldsUrl: string;
}

export default function MemberCustomFields(
  { customFieldsUrl, customFields }: Props,
): JSX.Element {
  const [message, setMessage] = useState<string>(null);

  return (
    <div className="custom-field">
      {customFields
        ? customFields.map((customField) => (
          <MemberCustomFieldItem
            key={`${customField.custom_field_id}-${customField.custom_field_value_id}`}
            customField={customField}
            customFieldsUrl={customFieldsUrl}
            customFieldId={customField.custom_field_id}
            showMessage={(message: string) => setMessage(message)}
          />
        ))
        : (
          <dl>
            <dt>
              <span className="user-bill-table__loading"></span>
            </dt>
            <dd>
              <span className="user-bill-table__loading"></span>
            </dd>
          </dl>
        )}
      {message && <Snackbar>{message}</Snackbar>}
    </div>
  );
}
