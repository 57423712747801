import React from "react";

import {
  useFormContext,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import {
  ClassroomEnrollmentParams,
  EnrollmentCustomField,
} from "../ClassroomEnrollmentPage";
import { FieldErrors } from "react-hook-form/dist/types/errors";

interface Props {
  customFields: EnrollmentCustomField[];
}

export default function ClassroomEnrollmentCustomFieldFormSection(
  { customFields }: Props,
): JSX.Element {
  const { register, setValue, formState: { errors } } = useFormContext<
    ClassroomEnrollmentParams
  >();

  return (
    <>
      {customFields.map((customField, customFieldIndex) => (
        <div className="form__item" key={customField.id}>
          <input
            {...register(`custom_fields.${customFieldIndex}.custom_field_id`)}
            value={customField.id}
            type="hidden"
          />
          {/* 確認画面の表示用に埋め込んでおく */}
          <input
            {...register(`custom_fields.${customFieldIndex}.name`)}
            value={customField.name}
            type="hidden"
          />

          {customField.field_type === "free_text" && (
            <fieldset>
              <legend>
                {customField.name}
                <abbr className="_require" title="必須">*</abbr>
              </legend>
              <div className="form__control">
                <span className="textfield -appearance-filled -width-full">
                  <input
                    {...register(`custom_fields.${customFieldIndex}.value`, {
                      required: "入力してください",
                      maxLength: {
                        value: 255,
                        message: "255文字以内で入力してください",
                      },
                    })}
                    type="text"
                    placeholder={customField.name}
                    id={`enroll_event_group_form_custom_field_${customField.id}`}
                    className="_input"
                    required
                  />
                  <label
                    htmlFor={`enroll_event_group_form_custom_field_${customField.id}`}
                    className="_label"
                  >
                    {customField.name}
                  </label>
                </span>
                <div className="form__error" aria-live="polite">
                  {errors.custom_fields?.[customFieldIndex]?.value?.message}
                </div>
              </div>
            </fieldset>
          )}

          {customField.field_type === "single_select" && (
            <fieldset>
              <legend>
                {customField.name}
                <abbr className="_require" title="必須">*</abbr>
              </legend>
              <div className="form__control --has-radiofield">
                <div className="radiofield --layout-stack">
                  <input
                    {...register(
                      `custom_fields.${customFieldIndex}.custom_field_option_id`,
                    )}
                    type="hidden"
                    defaultValue={customField.options[0]?.id}
                  />

                  {customField.options.map((option, optionIndex) => (
                    <label
                      className="_item"
                      htmlFor={`custom_field_${option.id}`}
                      key={option.option_value}
                    >
                      <input
                        {...register(
                          `custom_fields.${customFieldIndex}.value`,
                          {
                            required: "選択してください",
                          },
                        )}
                        type="radio"
                        value={option.option_value}
                        id={`custom_field_${option.id}`}
                        onChange={() => {
                          setValue(
                            `custom_fields.${customFieldIndex}.value`,
                            option.option_value,
                          );
                          setValue(
                            `custom_fields.${customFieldIndex}.custom_field_option_id`,
                            option.id,
                          );
                        }}
                        defaultChecked={optionIndex === 0}
                        className="radio"
                      />
                      <span className="_label">
                        {option.option_value}
                      </span>
                    </label>
                  ))}
                </div>
                <div className="form__error" aria-live="polite">
                  {errors.custom_fields?.[customField.id]?.value?.message}
                </div>
              </div>
            </fieldset>
          )}
        </div>
      ))}
    </>
  );
}
