import React, { useState } from "react";

import { useFormContext } from "react-hook-form";
import {
  Address,
  ClassroomEnrollmentParams,
  Student,
} from "../ClassroomEnrollmentPage";
import TextAvatar from "../TextAvatar";
import ClassroomEnrollmentAddressFormSection from "./ClassroomEnrollmentAddressFormSection";

interface Props {
  students: Student[];
  addresses: Address[];
}

export default function ClassroomEnrollmentStudentFormSection(
  { students, addresses }: Props,
): JSX.Element {
  const [studentFormType, setStudentFormType] = useState<"form" | "selector">(
    students.length === 0 ? "form" : "selector",
  );
  const { register, setValue, formState: { errors } } = useFormContext<
    ClassroomEnrollmentParams
  >();

  const setStudentAttributes = (student_id: number = null) => {
    const student = students.find((student) => student.id === student_id) || {
      id: null,
      name: "",
      name_kana: "",
      dependent: "true",
      date_of_birth: "2000-01-01",
      gender: "",
    };
    setValue("student_id", student.id?.toString());
    setValue("student_name", student.name);
    setValue("student_name_kana", student.name_kana);
    setValue("student_dependent", student.dependent.toString());
    setValue(
      "student_date_of_birth",
      student.date_of_birth,
    );
    setValue("student_gender", student.gender);
  };
  const onNewStudentFormSwitch = () => {
    setStudentAttributes(null);
    setStudentFormType("form");
  };

  return studentFormType === "form"
    ? (
      <div className={"enroll-event-group__student-form"}>
        <div className="enroll-event-group__student-form-header">
          <h3 className="enroll-event-group__student-form-title">
            {students.length > 0 ? "新しい受講者を追加" : "受講者情報"}
          </h3>

          {students.length > 0 && (
            <div className="enroll-event-group__student-form-action">
              <div
                role="button"
                className="button -appearance-outlined -color-interactive -size-s"
                onClick={() => setStudentFormType("selector")}
              >
                <div className="_leading">
                  <span className="icon _icon" data-icon="people">
                  </span>
                </div>
                <div className="_body">
                  受講者を選択
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="form">
          <div className="form__item">
            <div className="enroll-event-group__role-switch">
              <div className="enroll-event-group__role-switch-title">
                <span>
                  入会するのは<abbr
                    className="_require"
                    title="必須"
                  >
                    *
                  </abbr>
                </span>
              </div>

              <div className="enroll-event-group__role-switch-body">
                <div className="radiofield">
                  <label className="_item">
                    <input
                      type="radio"
                      {...register("student_dependent", {
                        required: "選択してください",
                      })}
                      value="false"
                      className="radio"
                    />
                    <span className="_label">本人です</span>
                  </label>

                  <label className="_item">
                    <input
                      type="radio"
                      {...register("student_dependent", {
                        required: "選択してください",
                      })}
                      value="true"
                      className="radio"
                    />
                    <span className="_label">本人以外です</span>
                  </label>
                  <div className="form__error" aria-live="polite">
                    {errors.student_dependent?.message}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form__item">
            <div className="form__control">
              <span className="textfield -appearance-filled -width-full">
                <input
                  {...register("student_name", {
                    required: "入力してください",
                    maxLength: {
                      value: 50,
                      message: "50文字以内で入力してください",
                    },
                  })}
                  className="_input"
                  id="student-name-form"
                  placeholder="受講者の氏名"
                />
                <label
                  className="_label"
                  htmlFor="student-name-form"
                >
                  受講者の氏名
                  <abbr className="_require" title="必須">*</abbr>
                </label>
              </span>
              <div className="form__error" aria-live="polite">
                {errors.student_name?.message}
              </div>
            </div>
          </div>

          <div className="form__item">
            <div className="form__control">
              <span className="textfield -appearance-filled -width-full">
                <input
                  {...register("student_name_kana", {
                    required: "入力してください",
                    maxLength: {
                      value: 255,
                      message: "255文字以内で入力してください",
                    },
                    pattern: {
                      value: /[ 　ァ-ヴーｱ-ﾝﾞﾟA-Za-z\dｰ−-]+/,
                      message:
                        "カタカナ、半角英数字、スペース、半角ハイフンで入力してください",
                    },
                  })}
                  className="_input"
                  id="student-ruby-form"
                  placeholder="フリガナ"
                />
                <label
                  className="_label"
                  htmlFor="student-ruby-form"
                >
                  フリガナ
                  <abbr className="_require" title="必須">*</abbr>
                </label>
              </span>
              <div className="form__error" aria-live="polite">
                {errors.student_name_kana?.message}
              </div>
            </div>
          </div>

          <div className="form__item">
            <div className="form__control">
              <span className="textfield -appearance-filled -width-half">
                <input
                  {...register("student_date_of_birth")}
                  type="date"
                  className="_input"
                  id="student-date-of-birth-form"
                  placeholder="生年月日"
                  defaultValue="2000-01-01"
                />
                <label
                  className="_label"
                  htmlFor="student-date-of-birth-form"
                >
                  生年月日
                </label>
              </span>
            </div>
          </div>

          <div className="form__item">
            <fieldset>
              <legend>性別</legend>
              <div className="form__control --has-radiofield">
                <div className="radiofield --layout-stack">
                  <label className="_item">
                    <input
                      {...register("student_gender", {
                        required: "選択してください",
                      })}
                      className="radio"
                      type="radio"
                      value=""
                      defaultChecked
                    />
                    <span className="_label">設定しない</span>
                  </label>

                  <label className="_item">
                    <input
                      {...register("student_gender", {
                        required: "選択してください",
                      })}
                      className="radio"
                      type="radio"
                      value="male"
                    />
                    <span className="_label">男性</span>
                  </label>

                  <label className="_item">
                    <input
                      {...register("student_gender", {
                        required: "選択してください",
                      })}
                      className="radio"
                      type="radio"
                      value="female"
                    />
                    <span className="_label">女性</span>
                  </label>

                  <label className="_item">
                    <input
                      {...register("student_gender", {
                        required: "選択してください",
                      })}
                      className="radio"
                      type="radio"
                      value="non_binary"
                    />
                    <span className="_label">別の性</span>
                  </label>

                  <div className="form__error" aria-live="polite">
                    {errors.student_gender?.message}
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <ClassroomEnrollmentAddressFormSection
            addresses={addresses}
          />
        </div>
      </div>
    )
    : (
      <div className="enroll-event-group__student-selector">
        <div className="enroll-event-group__student-selector-header">
          <h3 className="enroll-event-group__student-selector-title">
            受講者を選択
          </h3>

          <div className="enroll-event-group__student-selector-action">
            <div
              role="button"
              className="button -appearance-outlined -color-interactive -size-s"
              onClick={onNewStudentFormSwitch}
            >
              <div className="_leading">
                <span
                  className="icon _icon"
                  data-icon="person_plus"
                >
                </span>
              </div>
              <div className="_body">
                新しい受講者を追加
              </div>
            </div>
          </div>
        </div>

        <div className="enroll-event-group__student-selector-list">
          <ul className="_list">
            {students.map((student, index) => (
              <li key={student.id} className="_item">
                <label>
                  <div className="_leading">
                    <div className="_input">
                      <input
                        type="radio"
                        {...register("student_id", {
                          required: "選択してください",
                        })}
                        value={student.id}
                        className="radio"
                        defaultChecked={index === 0}
                        onChange={(event) => {
                          setStudentAttributes(Number(event.target.value));
                        }}
                      />
                    </div>
                  </div>
                  <div className="_avatar">
                    <TextAvatar
                      name={student.name}
                      size="s"
                      icon={true}
                    />
                  </div>
                  <div className="_body">
                    <div className="_title">
                      {student.name}
                    </div>
                  </div>
                </label>
              </li>
            ))}
          </ul>
          <div className="form__error" aria-live="polite">
            {errors.student_id?.message}
          </div>
        </div>
      </div>
    );
}
