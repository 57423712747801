import {
  patch,
  post,
  requestAsJson,
  requestWithCsrfToken,
} from "utils/request";

export const useApi = () => {
  return { post, patch, requestWithCsrfToken, requestAsJson };
};
