import React, { Fragment, useEffect, useRef, useState } from "react";
import Dialog from "components/Dialog";
import { useApi } from "hooks/api";
import { ModalBackButton } from "./ModalBackButton";
import {
  localizeDateToFullString,
  localizeDateToYmdString,
} from "utils/localize-date";
import TextAvatar from "components/TextAvatar";

interface Addressee {
  name: string;
}

interface Payment {
  tried_at: string;
  approved_at: string;
  marked_as_paid_at: string;
  transfer_return_date: string;
  method_type_konbini: boolean;
}

interface Bill {
  id: number;
  addressee: Addressee;
  payment_waiting_for_debit: boolean;
  payment_waiting_for_approval: boolean;
  payable: boolean;
  overdue: boolean;
  allow_overdue_notification: boolean;
  payments: Payment;
  marked_as_paid: boolean;
  markable_as_paid: boolean;
  payment_approved: boolean;
  overdue_notifications_path: string;
}

interface Classroom {
  slug: string;
  settlement_agent: {
    konbini_payment_enabled: boolean;
  };
}

interface BillGroup {
  id: number;
  name: string;
  amount: number;
  issued_at: string;
  deadline: string;
  size: number;
  bills: Bill[];
  draft: boolean;
  disabled: boolean;
  deletable: boolean;
  updatable_to_be_disabled: boolean;
  not_issued: boolean;
  issued: boolean;
  number_of_paid_students: number;
  back_button_link_path: string;
  classroom: Classroom;
}

interface Paths {
  details_path: string;
  edit_path: string;
  duplicate_path: string;
  bill_group_path: string;
  disable_path: string;
  mark_as_paid_path: string;
  bill_path: string;
  settings_path: string;
}

interface BillGroupDetails {
  bill_group: BillGroup;
  classroom: Classroom;
  paths: Paths;
}

export default function BillGroupDetails(
  { bill_group, classroom, paths }: BillGroupDetails,
): React.JSX.Element {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [deletingPopupVisible, setDeletingPopupVisible] = useState(false);
  const [disabledPopupVisible, setDisabledPopupVisible] = useState(false);
  const [paidDialogTarget, setPaidDialogTarget] = useState<number>(null);
  const [payableDialogTarget, setPayableDialogTarget] = useState<number>(null);
  const [reminderDialogTarget, setNotificationDialogTarget] = useState<number>(
    null,
  );
  const [userActionPopupVisible, setUserActionPopupVisible] = useState(false);
  const [billActionPopupTarget, setBillActionPopupTarget] = useState<number>(
    null,
  );

  const toggleUserActionPopup = () => {
    setUserActionPopupVisible((prev) => !prev);
  };

  const handleBlur = (event) => {
    const isButtonFocused = event.relatedTarget?.tagName === "BUTTON";
    if (!isButtonFocused) {
      setOpenDropdown(false);
    }
  };

  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
          <div className="_title">
            {bill_group.name}
          </div>
          <div className="single-container">
            <div className="single-control">
              <div className="single-control__button">
                <ModalBackButton />
              </div>
              <div className="single-control__button single-control__action">
                <button
                  className="single-control__button"
                  onClick={toggleUserActionPopup}
                  onBlur={handleBlur}
                  aria-label="操作"
                  aria-controls="user-action"
                  aria-expanded={userActionPopupVisible ? "true" : "false"}
                >
                  <span className="_icon icon" data-icon="ellipsis_vertical">
                  </span>
                </button>
                {userActionPopupVisible && (
                  <>
                    <ul className="dropdown__list" id="user-action">
                      {bill_group.draft &&
                        (
                          <li className="dropdown__item">
                            <a className="_body" href={paths.edit_path}>
                              編集
                            </a>
                          </li>
                        )}
                      <li className="dropdown__item">
                        <div className="_body">
                          <a href={paths.duplicate_path}>
                            コピー
                          </a>
                        </div>
                      </li>

                      {bill_group.deletable &&
                        (
                          <>
                            <li className="dropdown__item">
                              <button
                                onClick={() => setDeletingPopupVisible(true)}
                              >
                                <div className="_body">
                                  削除
                                </div>
                              </button>
                            </li>
                            {deletingPopupVisible && (
                              <Dialog
                                title={`この請求を取り消しますか`}
                                description="この請求は削除されます。"
                                onCloseButtonClick={() =>
                                  setDeletingPopupVisible(false)}
                              >
                                <a
                                  className="button -appearance-transparent -size-m -color-negative"
                                  rel="nofollow"
                                  data-method="delete"
                                  href={paths.bill_group_path}
                                >
                                  <div className="_body">
                                    削除
                                  </div>
                                </a>
                              </Dialog>
                            )}
                          </>
                        )}
                      {bill_group.updatable_to_be_disabled &&
                        (
                          <>
                            <li className="dropdown__item">
                              <button
                                onClick={() => setDisabledPopupVisible(true)}
                              >
                                <div className="_body">
                                  無効にする
                                </div>
                              </button>
                            </li>
                            {disabledPopupVisible && (
                              <Dialog
                                title={`この請求を無効化しますか？`}
                                description="この請求を無効化しますか？"
                                onCloseButtonClick={() =>
                                  setDisabledPopupVisible(false)}
                              >
                                <a
                                  className="button -appearance-transparent -size-m -color-negative"
                                  rel="nofollow"
                                  data-method="post"
                                  href={paths.disable_path}
                                >
                                  <div className="_body">
                                    無効化
                                  </div>
                                </a>
                              </Dialog>
                            )}
                          </>
                        )}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="modal-content__container">
          <div className="bill-group">
            <div className="bill-group__section">
              <div className="bill-group__item">
                <div className="bill-group__item-title">
                  <span className="bill-group__item-label">状態</span>
                </div>
                <div className="bill-group__item-body">
                  {bill_group.draft
                    ? <div className="bill-group__item-body">下書き</div>
                    : bill_group.disabled
                    ? <div className="bill-group__item-body">無効</div>
                    : bill_group.not_issued
                    ? <div className="bill-group__item-body">送信前</div>
                    : <div className="bill-group__item-body">送信済み</div>}
                </div>
              </div>

              <div className="bill-group__item">
                <div className="bill-group__item-title">
                  <span className="bill-group__item-label">項目名</span>
                </div>
                <div className="bill-group__item-body">
                  {bill_group.name}
                </div>
              </div>

              <div className="bill-group__item">
                <div className="bill-group__item-title">
                  <span className="bill-group__item-label">金額</span>
                </div>
                <div className="bill-group__item-body">
                  {`${bill_group.amount}円`}
                </div>
              </div>
            </div>

            <div className="bill-group__section">
              <h2 className="bill-group__title">
                <span className="bill-group__title-trailing">
                  <span className="icon" data-icon="calendar"></span>
                </span>
                <span className="bill-group__title-text">
                  日付
                </span>
              </h2>

              <div className="bill-group__item">
                <div className="bill-group__item-title">
                  <span className="bill-group__item-label">請求日</span>
                </div>
                <div className="bill-group__item-body">
                  {localizeDateToFullString(
                    bill_group.issued_at,
                  )}
                </div>
              </div>

              <div className="bill-group__item">
                <div className="bill-group__item-title">
                  <span className="bill-group__item-label">期日</span>
                </div>
                <div className="bill-group__item-body">
                  {localizeDateToFullString(
                    bill_group.deadline,
                  )}
                </div>
              </div>
            </div>

            <div className="bill-group__section">
              <h2 className="bill-group__title">
                <span className="bill-group__title-trailing">
                  <span className="icon" data-icon="person"></span>
                </span>
                <span className="bill-group__title-text">
                  請求対象
                </span>
              </h2>
              <div className="bill-group__item">
                <div className="bill-group__item-title">
                  <span className="bill-group__item-label">人数</span>
                </div>
                <div className="bill-group__item-body">
                  {`${bill_group.size}人`}
                  {(!bill_group.draft || !bill_group.not_issued) &&
                    (
                      <>
                        <br />
                        {`（支払い済み ${bill_group.number_of_paid_students}人）`}
                      </>
                    )}
                </div>
              </div>

              {!classroom.settlement_agent.konbini_payment_enabled && (
                <div className="bill-group__item">
                  <div className="bill-group__note --attention">
                    <div className="_head">
                      <span
                        className="icon _icon"
                        data-icon="exclamation_on_triangle"
                      >
                      </span>
                      <h3>
                        受講者がコンビニ払いを利用できません
                      </h3>
                    </div>
                    <div className="_body">
                      <a href={paths.settings_path}>
                        入金設定
                      </a>が完了するとコンビニ払いが利用可能になります。
                    </div>
                  </div>
                </div>
              )}

              <div className="bill-group__item --no-side-padding">
                <div className="bill-group__item-body">
                  <div className="interactive-list">
                    <ul className="_list">
                      {bill_group.bills.map((bill: Bill, index: number) => (
                        <li className="_item" key={bill.id}>
                          <div>
                            <div className="_leading">
                              <TextAvatar
                                icon={true}
                                size={"m"}
                                name={bill.addressee.name}
                              />
                            </div>
                            <div className="_body">
                              <div className="_title">
                                {bill.addressee.name}
                              </div>
                              <div className="_description">
                                {bill.payment_approved
                                  ? `支払い済み｜${
                                    localizeDateToFullString(
                                      bill.payments.tried_at ||
                                        bill.payments.approved_at,
                                    )
                                  }`
                                  : bill.marked_as_paid
                                  ? `支払いマーク済み｜${
                                    localizeDateToFullString(
                                      bill.payments.marked_as_paid_at,
                                    )
                                  }`
                                  : bill.payment_waiting_for_debit
                                  ? (
                                    <>
                                      振替待ち
                                      {bill.payments.transfer_return_date &&
                                        ` | ${
                                          localizeDateToFullString(
                                            bill.payments
                                              .transfer_return_date,
                                          )
                                        }`}
                                    </>
                                  )
                                  : bill.payment_waiting_for_approval
                                  ? `決済中｜${
                                    localizeDateToFullString(
                                      bill.payments.tried_at,
                                    )
                                  }`
                                  : (bill.payable || !bill_group.issued ||
                                      bill_group.disabled)
                                  ? `未入金`
                                  : `不明な状態`}
                                {bill.payments.method_type_konbini && (
                                  <span>(コンビニ払い選択)</span>
                                )}
                              </div>
                            </div>

                            <div className="_trailing">
                              <div className="dropdown --position-right">
                                {(bill.markable_as_paid || bill.payable ||
                                  bill_group.not_issued) && (
                                  <div className="single-control__button single-control__action">
                                    <button
                                      className="single-control__button"
                                      onClick={() => {
                                        setBillActionPopupTarget(bill.id);
                                      }}
                                      onBlur={(event) => {
                                        // event が マウスダウンかつ特定のタグ以下 (子供) だったら処理を終わる (ブロッキング)
                                        const isButtonFocused =
                                          event.relatedTarget?.tagName ===
                                            "BUTTON";
                                        if (!isButtonFocused) {
                                          setBillActionPopupTarget(null);
                                        }
                                      }}
                                      aria-label="操作"
                                      aria-controls="user-action2"
                                      aria-expanded={billActionPopupTarget ==
                                          bill.id
                                        ? "true"
                                        : "false"}
                                    >
                                      <span
                                        className="_icon icon"
                                        data-icon="ellipsis_vertical"
                                      >
                                      </span>
                                    </button>
                                    {billActionPopupTarget == bill.id && (
                                      <>
                                        <ul
                                          id="user-action"
                                          className="_list dropdown__list custom-dropdown-list"
                                        >
                                          {bill.allow_overdue_notification &&
                                            (
                                              <>
                                                <li
                                                  role="listitem"
                                                  className="_item dropdown__item"
                                                >
                                                  <button
                                                    onClick={() => {
                                                      setNotificationDialogTarget(
                                                        bill.id,
                                                      );
                                                      setBillActionPopupTarget(
                                                        null,
                                                      );
                                                    }}
                                                  >
                                                    <div className="_body">
                                                      支払い依頼を再送する
                                                    </div>
                                                  </button>
                                                </li>
                                              </>
                                            )}
                                          {bill.markable_as_paid &&
                                            (
                                              <>
                                                <li
                                                  role="listitem"
                                                  className="_item dropdown__item"
                                                >
                                                  <button
                                                    onClick={() => {
                                                      setPaidDialogTarget(
                                                        bill.id,
                                                      );
                                                      setBillActionPopupTarget(
                                                        null,
                                                      );
                                                    }}
                                                  >
                                                    <div className="_body">
                                                      支払い済みにする
                                                    </div>
                                                  </button>
                                                </li>
                                              </>
                                            )}
                                          {(bill.payable ||
                                            bill_group.not_issued) &&
                                            (
                                              <>
                                                <li
                                                  role="listitem"
                                                  className="_item dropdown__item"
                                                >
                                                  <button
                                                    onClick={() => {
                                                      setPayableDialogTarget(
                                                        bill.id,
                                                      );
                                                      setBillActionPopupTarget(
                                                        null,
                                                      );
                                                    }}
                                                  >
                                                    <div className="_body">
                                                      請求対象から削除する
                                                    </div>
                                                  </button>
                                                </li>
                                              </>
                                            )}
                                        </ul>
                                      </>
                                    )}
                                  </div>
                                )}
                                {reminderDialogTarget == bill.id && (
                                  <Dialog
                                    title={`${bill.addressee.name}様へ支払い依頼を再送しますか？`}
                                    description={
                                      <>
                                        支払期日の翌日12:00に送信されている支払い依頼と<br />
                                        同じ内容を送信します。
                                      </>
                                    }
                                    onCloseButtonClick={() =>
                                      setNotificationDialogTarget(
                                        null,
                                      )}
                                  >
                                    <a
                                      className="button -appearance-transparent -size-m -color-interactive"
                                      rel="nofollow"
                                      data-method="post"
                                      href={bill.overdue_notifications_path}
                                    >
                                      <div className="_body">
                                        送信する
                                      </div>
                                    </a>
                                  </Dialog>
                                )}
                                {paidDialogTarget == bill.id && (
                                  <Dialog
                                    title={`${bill.addressee.name}様の請求を支払い済みにしますか？`}
                                    description="この操作は取り消せません。"
                                    onCloseButtonClick={() => {
                                      setPaidDialogTarget(null);
                                      setBillActionPopupTarget(null);
                                    }}
                                  >
                                    <a
                                      className="button -appearance-transparent -size-m -color-interactive"
                                      rel="nofollow"
                                      data-method="patch"
                                      href={`./${bill_group.id}/bills/${bill.id}/mark_as_paid`}
                                    >
                                      <div className="_body">
                                        支払い済みにする
                                      </div>
                                    </a>
                                  </Dialog>
                                )}
                                {payableDialogTarget == bill.id && (
                                  <Dialog
                                    title={`${bill.addressee.name}様を請求対象から削除しますか？`}
                                    description={`${bill.addressee.name}様に送られた請求が取り消されます。`}
                                    onCloseButtonClick={() =>
                                      setPayableDialogTarget(
                                        null,
                                      )}
                                  >
                                    <a
                                      className="button -appearance-transparent -size-m -color-negative"
                                      rel="nofollow"
                                      data-method="delete"
                                      href={`./${bill_group.id}/bills/${bill.id}`}
                                    >
                                      <div className="_body">
                                        削除
                                      </div>
                                    </a>
                                  </Dialog>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
