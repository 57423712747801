import React from "react";
import FabButton from "components/FabButton";
import Notice from "components/ManagementClassroomSettings/Notice";
import TabHeader from "components/ManagementClassroomSettings/TabHeader";
import "./Summary.scss";

export default function Summary(
  {
    classroom,
  },
): JSX.Element {
  return (
    <div>
      <div className="page__header">
        <div className="page__container">
          <TabHeader classroom={classroom} />

          {!classroom.description && !classroom.organization.name &&
              !classroom.organization.representative &&
              !classroom.organization.phoneNumber
            ? (
              <section className="page__section">
                <Notice>
                  <p>
                    GMOレンシュへようこそ！<a
                      target="_blank"
                      href="https://help.renshu.com/hc/ja/categories/5345572292115-%E3%82%B9%E3%82%BF%E3%83%BC%E3%83%88%E3%82%AC%E3%82%A4%E3%83%89"
                    >
                      スタートガイド
                    </a>を見ながら進めていき、受講者を招待できるように情報を整えていきましょう
                  </p>
                </Notice>
              </section>
            )
            : null}

          <section className="page__section">
            <div className="page__section-head">
              <h2 className="page__title --level-2">教室の概要</h2>

              {classroom.policyCanEditManagementClassrooms
                ? (
                  <div className="page__action -appearance-transparent">
                    <a
                      className="button -appearance-transparent -color-interactive -size-s"
                      href={classroom.editManagementClassroomPath}
                    >
                      <div className="_leading">
                        <span className="icon _icon" data-icon="pencil"></span>
                      </div>

                      <div className="_body">編集</div>
                    </a>
                  </div>
                )
                : null}
            </div>

            <div className="page__item">
              <div className="page__data-body --description">
                {classroom.description
                  ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: classroom.description,
                      }}
                    />
                  )
                  : "入会する受講者にむけて教室の説明を記述しましょう"}
              </div>
            </div>
          </section>
        </div>
      </div>

      {classroom.currentUserIsClassroomManager &&
          !classroom.managerIsApproved
        ? (
          <section className="page__section">
            <div className="page__container">
              <div className="enrolled-classroom-manager">
                <span className="enrolled-classroom-manager__label">
                  運営メンバーとして承認を待っています
                </span>
              </div>
            </div>
          </section>
        )
        : null}

      {classroom.policyCanShow
        ? (
          <div className="page__section">
            <section className="page__section">
              <div className="page__container">
                <div className="page__section-head">
                  <h2 className="page__title --level-2">運営者情報</h2>

                  {classroom.policyCanEditManagementOrganizations && (
                    <div className="page__action -appearance-transparent">
                      <a
                        className="button -appearance-transparent -color-interactive -size-s"
                        href={classroom.editManagementOrganizationPath}
                      >
                        <div className="_leading">
                          <span className="icon _icon" data-icon="pencil" />
                        </div>

                        <div className="_body">編集</div>
                      </a>
                    </div>
                  )}
                </div>

                <div className="page__item">
                  <div className="page__data-head">
                    <h3 className="page__title --level-3">
                      法人名・団体名・屋号
                    </h3>
                  </div>

                  <div className="page__data-body">
                    {classroom.organization.name || "入力してください"}
                  </div>
                </div>

                <div className="page__item">
                  <div className="page__data-head">
                    <h3 className="page__title --level-3">代表者の氏名</h3>
                  </div>

                  <div className="page__data-body">
                    {classroom.organization.representative ||
                      "入力してください"}
                  </div>
                </div>

                <div className="page__item">
                  <div className="page__data-head">
                    <h3 className="page__title --level-3">電話番号</h3>
                  </div>

                  <div className="page__data-body">
                    {classroom.organization.phoneNumber || "入力してください"}
                  </div>
                </div>
              </div>
            </section>

            <section className="page__section">
              <div className="page__container">
                <div className="page__section-head">
                  <h2 className="page__title --level-2">入会時オプション</h2>
                </div>

                {classroom.optionalBills.length === 0 &&
                    classroom.customFieldsCount === 0
                  ? (
                    <section className="page__section">
                      <Notice>
                        <p>
                          入会する際に入会金をいただくようにしたり、教室独自の設問項目を設けることができます。<br />例：ユニフォームのサイズ、所属学校、アレルギーの有無など
                        </p>
                      </Notice>
                    </section>
                  )
                  : null}

                <div className="page__section-body">
                  <div className="page__item">
                    <div className="page__data-head">
                      <h3 className="page__title --level-3">入会時の支払い</h3>

                      {classroom
                        .policyCanNewManagementClassroomsOptionalBills && (
                        <div className="page__action -appearance-transparent">
                          <a
                            className="button -appearance-transparent -color-interactive -size-s"
                            href={classroom.managementClassroomInvitePath}
                          >
                            <div className="_leading">
                              <span className="icon _icon" data-icon="pencil">
                              </span>
                            </div>

                            <div className="_body">編集</div>
                          </a>
                        </div>
                      )}
                    </div>

                    {classroom.optionalBills.length === 0
                      ? <div className="page__data-body">未設定</div>
                      : (
                        <div className="optional-bill-list">
                          <ul className="_list">
                            {classroom.optionalBills.map((optionalBill) => (
                              <li key={optionalBill.id} className="_item">
                                <label className="no-left-padding">
                                  <div className="_leading">
                                    <div className="_input">
                                      <input
                                        disabled
                                        type="radio"
                                        className="radio js-optional-bill"
                                        name="optionalBill"
                                      />
                                    </div>
                                  </div>

                                  <div className="_body">
                                    <div className="_title">
                                      {optionalBill.name}
                                    </div>

                                    <div className="_amount">
                                      {optionalBill.amount}
                                    </div>
                                  </div>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                  </div>

                  <div className="page__item">
                    <div className="page__data-head">
                      <h3 className="page__title --level-3">入会時の設問</h3>

                      {classroom
                        .policyCanCreateManagementClassroomsCustomFields && (
                        <div className="page__action -appearance-transparent">
                          <a
                            className="button -appearance-transparent -color-interactive -size-s"
                            href={classroom
                              .managementClassroomCustomFieldsPath}
                          >
                            <div className="_leading">
                              <span className="icon _icon" data-icon="pencil">
                              </span>
                            </div>

                            <div className="_body">編集</div>
                          </a>
                        </div>
                      )}
                    </div>

                    <div className="page__data-body">
                      {classroom.customFieldsCount === 0
                        ? "未設定"
                        : "設定済み"}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )
        : null}

      <section className="page__section">
        <div className="page__container" style={{ textAlign: "center" }}>
          <a
            className="button -appearance-transparent -color-interactive -size-s"
            href={classroom.invitationUrl}
            rel="noreferrer noopener"
            target="_blank"
          >
            教室入会ページをプレビュー
          </a>
        </div>
      </section>

      <FabButton
        icon="people"
        label="受講者を招待"
        onClick={() =>
          window.location.href = classroom.managementClassroomInvitePath}
      />
    </div>
  );
}
