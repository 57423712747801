import React, { useCallback, useEffect, useState } from "react";

interface Props {
  pollingUrl: string;
  onSubmit: () => void;
  generatedBody: string;
  setGeneratedBody: (body: string) => void;
  isPolling: boolean;
  setIsPolling: (isPolling: boolean) => void;
}

export default function AIAssistantConfirmation(
  {
    pollingUrl,
    onSubmit,
    generatedBody,
    setGeneratedBody,
    isPolling,
    setIsPolling,
  }: Props,
) {
  const fetchGeneratedBody = useCallback(async () => {
    const response = await fetch(pollingUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { output, progress }: {
      output: string;
      progress: "intended" | "succeeded" | "failed";
    } = await response.json();
    return { output, progress };
  }, [pollingUrl]);

  useEffect(() => {
    const interval = setInterval(async () => {
      setIsPolling(true);
      const { output, progress } = await fetchGeneratedBody();
      if (progress === "succeeded") {
        setGeneratedBody(output);
        setIsPolling(false);
        clearInterval(interval);
      } else if (progress === "failed") {
        alert(
          "AIによる生成に失敗しました。入力内容をお確かめの上、もう一度お試しください。",
        );
        setIsPolling(false);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [pollingUrl]);

  return (
    <>
      {generatedBody &&
        (
          <div className="ai-assistant__content">
            <h3>AIが考えた文章</h3>
            <div className="ai-assistant__text">
              {generatedBody}
            </div>
          </div>
        )}
      {isPolling
        ? <div className="skeleton-box">AIが文章を作成中...</div>
        : (
          <div className="ai-assistant__action">
            <button
              type="button"
              className="button -appearance-outlined -size-s"
              onClick={onSubmit}
            >
              <div className="_body">
                やり直す
              </div>
            </button>
          </div>
        )}
    </>
  );
}
