import React, { useState } from "react";
import Cover from "components/ManagementClassroomSettings/Cover";
import Header from "components/ManagementClassroomSettings/Header";
import Page from "components/ManagementClassroomSettings/Page";

export default function ManagementClassroomSettings(
  {
    classroom,
    formAuthenticityToken,
  },
): JSX.Element {
  const tabAnchor = window.location.href.split("#")[1] || "summary";
  const [activeTab, setActiveTab] = useState(tabAnchor);

  return (
    <div className="modal-content">
      <Header
        activeTab={activeTab}
        classroom={classroom}
        setActiveTab={setActiveTab}
      />

      <Cover
        classroom={classroom}
        editPath={`${classroom.editManagementClassroomPath}?reftab=${activeTab}`}
      />

      <div className="page">
        <Page
          activeTab={activeTab}
          classroom={classroom}
          formAuthenticityToken={formAuthenticityToken}
        />
      </div>
    </div>
  );
}
