import React from "react";

import { useFormContext } from "react-hook-form";
import { ClassroomEnrollmentParams } from "../ClassroomEnrollmentPage";

interface Props {
  initialUserName: string;
  initialUserPhoneNumber: string;
  currentStudentDependent: string;
  settings_path: string;
}

export default function ClassroomEnrollmentUserFormSection(
  props: Props,
): JSX.Element {
  const { register, formState: { errors } } = useFormContext<
    ClassroomEnrollmentParams
  >();

  return props.currentStudentDependent === "true"
    ? (
      <div className="enroll-event-group__account-data">
        <h3 className="enroll-event-group__account-data-title">
          アカウント情報
        </h3>
        <div className="enroll-event-group__account-data-note">
          <ul>
            <li>
              お子様など『本人以外』が入会する場合は連絡先としてアカウント情報が教室へ伝わります
            </li>
            <li>
              <a href={props.settings_path}>アカウント設定</a>
              からいつでも変更できます
            </li>
          </ul>
        </div>

        <div className="enroll-event-group__account-data-body">
          <div className="form">
            <div className="form__item">
              <div className="form__control">
                <span className="textfield -appearance-filled -width-full">
                  <input
                    {...register("user_name", {
                      required: "入力してください",
                      maxLength: {
                        value: 50,
                        message: "50文字以内で入力してください",
                      },
                    })}
                    className="_input"
                    id="account-user-name-form"
                    placeholder="氏名"
                    disabled={Boolean(
                      props.initialUserName,
                    )}
                  />
                  <label
                    htmlFor="account-user-name-form"
                    className="_label"
                  >
                    氏名
                    <abbr className="_require" title="必須">*</abbr>
                  </label>
                </span>
                <div className="form__error" aria-live="polite">
                  {errors.user_name?.message}
                </div>
              </div>
            </div>

            <div className="form__item">
              <div className="form__control">
                <span className="textfield -appearance-filled -width-full">
                  <input
                    {...register("user_phone_number", {
                      required: "入力してください",
                      pattern: {
                        value: /0\d{9,10}/,
                        message: "半角数字で入力してください",
                      },
                    })}
                    className="_input"
                    id="account-user-phone-number-form"
                    placeholder="電話番号"
                    disabled={Boolean(
                      props.initialUserPhoneNumber,
                    )}
                  />
                  <label
                    htmlFor="account-user-phone-number-form"
                    className="_label"
                  >
                    電話番号
                    <abbr className="_require" title="必須">*</abbr>
                  </label>
                </span>
                <div className="form__error" aria-live="polite">
                  {errors.user_phone_number?.message}
                </div>
              </div>
            </div>
            {!props.initialUserPhoneNumber &&
              (
                <div className="form__item-note">
                  ※ ハイフン無し（例：00012345678）
                </div>
              )}
          </div>
        </div>
      </div>
    )
    : (
      <>
        <div className="enroll-event-group__account-data form__item">
          <fieldset>
            <legend>
              電話番号
              <abbr className="_require" title="必須">*</abbr>
            </legend>
            {props.initialUserPhoneNumber &&
              (
                <div className="enroll-event-group__account-data-note">
                  <ul className="-no-margin">
                    <li>
                      <a href={props.settings_path}>アカウント設定</a>
                      から変更できます
                    </li>
                  </ul>
                </div>
              )}
            <div className="form__item">
              <div className="form__control">
                <span className="textfield -appearance-filled -width-full">
                  <input
                    {...register("user_phone_number", {
                      required: "入力してください",
                      pattern: {
                        value: /0\d{9,10}/,
                        message: "半角数字で入力してください",
                      },
                    })}
                    className="_input"
                    id="account-user-phone-number-form"
                    placeholder="電話番号"
                    disabled={Boolean(
                      props.initialUserPhoneNumber,
                    )}
                  />
                  <label
                    htmlFor="account-user-phone-number-form"
                    className="_label"
                  >
                    電話番号
                  </label>
                </span>
                <div className="form__error" aria-live="polite">
                  {errors.user_phone_number?.message}
                </div>
              </div>
            </div>
            {!props.initialUserPhoneNumber &&
              (
                <div className="form__item-note">
                  ※ ハイフン無し（例：00012345678）
                </div>
              )}
          </fieldset>
        </div>
      </>
    );
}
