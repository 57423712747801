import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

interface Props {
  enrollment: {
    id: number;
    description: string;
  };
  handleUpdateEnrollment: (
    enrollmentId: number,
    data: { enrollment: { description: string } },
  ) => Promise<void>;
}

interface Inputs {
  enrollment: {
    description: string;
  };
}

export default function MemberDescriptionForm(props: Props) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<Inputs>({
    defaultValues: {
      enrollment: {
        description: props.enrollment.description,
      },
    },
  });

  useEffect(() => {
    setValue("enrollment.description", props.enrollment.description);
  }, [props.enrollment]);

  const onSubmit = handleSubmit(async (data) => {
    await props.handleUpdateEnrollment(props.enrollment.id, data);
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="user__data-description__container">
        <span className="user__data-description__field">
          <input
            {...register("enrollment.description")}
            className="_input"
            placeholder=""
            id="js-user-description-field"
          />
        </span>
        <button
          className="user__data-description__submit"
          type="submit"
          disabled={isSubmitting}
        >
          <div className="_body">保存</div>
        </button>
      </div>
      <p className="user__data-description__help">
        運営者だけが見られる、受講者には非公開のメモです。
      </p>
    </form>
  );
}
