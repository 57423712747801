import React from "react";
import ReactCalendar from "react-calendar";
import CalendarNavigationLabel from "components/Schedules/CalendarNavigationLabel";
import CalendarTileContent from "components/Schedules/CalendarTileContent";
import CalendarTileDay from "components/Schedules/CalendarTileDay";
import { isBefore, isSameMonth } from "date-fns";
import "react-calendar/dist/Calendar.css";

export default function Calendar(
  {
    events,
    onActiveStartDateChange,
    onClickDay,
    onClickToday,
    selectedDate,
  },
): JSX.Element {
  const tileClassName = ({ activeStartDate, date, view }) => {
    let className = "schedules-calendar-v1__tile";

    if (!isSameMonth(new Date(date), new Date(activeStartDate))) {
      className = className.concat(" gray");
    } else if (date.getDay() === 0) {
      className = className.concat(" red");
    } else if (date.getDay() === 6) {
      className = className.concat(" blue");
    }

    if (isBefore(new Date(date), new Date())) {
      className = className.concat(" past");
    } else {
      className = className.concat(" upcoming");
    }

    return className;
  };

  return (
    <ReactCalendar
      activeStartDate={selectedDate}
      calendarType={"gregory"}
      className={"schedules-calendar-v1"}
      defaultView={"month"}
      next2Label={null}
      onActiveStartDateChange={onActiveStartDateChange}
      onClickDay={onClickDay}
      prev2Label={null}
      tileClassName={tileClassName}
      view={"month"}
      formatDay={(locale, date) => (
        <CalendarTileDay
          date={date}
          events={events}
        />
      )}
      navigationLabel={({ date, label, locale, view }) => (
        <CalendarNavigationLabel
          date={date}
          label={label}
          onClickToday={onClickToday}
        />
      )}
      tileContent={({ activeStartDate, date, view }) => (
        <CalendarTileContent
          date={date}
          events={events}
        />
      )}
    />
  );
}
