import React, { useEffect, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form/dist/types/form";
import TextAvatar from "components/TextAvatar";
import BillAddresseeSelectorView from "components/BillAddresseeSelectorView";

interface Props {
  form_name: string;
  attributes_name: string;
  addressee_attribute_name: string;
  show_automatic_settlement_enabled: boolean;
  classroom_event_groups: EventGroup[];
  enrollments: Enrollment[];
  selected_student_ids: number[];
  inputRegister: () => UseFormRegisterReturn;
  setSelectedStudentIdsValue: (selectedStudentIds: number[]) => void;
}

interface EventGroup {
  id: number;
  name: string;
  root: boolean;
}

interface Enrollment {
  id: number;
  student: {
    id: number;
    name: string;
  };
  event_groups: EventGroup[];
  allowed_to_settle_automatically: boolean;
}

export default function BillAddresseeSelector(props: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  const [selectedStudentIds, setSelectedStudentIds] = useState<number[]>(
    props.selected_student_ids,
  );
  const [selectedEventGroupIds, setSelectedEventGroupIds] = useState<number[]>(
    [],
  );

  const selectStudentIds = (selectedStudentIds: number[]) => {
    props.setSelectedStudentIdsValue(selectedStudentIds);
    setSelectedStudentIds(selectedStudentIds);
  };

  return (
    <div className="addressee-select">
      <div
        className="_switch"
        role="button"
        tabIndex={0}
        aria-label="対象を選択"
        onClick={() => setOpen(true)}
      >
        <div className="_switch-label">
          <div>{selectedStudentIds.length}人</div>
        </div>
        <div className="_switch-trailing">
          <span className="icon" data-icon="arrow_drop_down" />
        </div>
      </div>

      <div className="form__body">
        <div className="form__body-item addressee-list">
          <ul className="addressee-list__list --align-left -size-s">
            {selectedEventGroupIds.map((id) => {
              const eventGroup = props.classroom_event_groups.find(
                (eventGroup) => {
                  return eventGroup.id === id;
                },
              );
              const eventGroupEnrollments = props.enrollments.filter(
                (enrollment) => {
                  return enrollment.event_groups.some((g) =>
                    g.id === eventGroup.id
                  );
                },
              );
              const total = eventGroupEnrollments.length;
              const count = eventGroupEnrollments.filter((enrollment) => {
                return selectedStudentIds.includes(enrollment.student.id);
              }).length;
              return (
                <li key={id} className="addressee-list__item">
                  <div className="addressee-list__body">
                    <span>{eventGroup.name}</span>
                    <b>（{count}/{total}人）</b>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <ul className="_list addressee-selected__list">
        {props.enrollments.filter((enrollment) =>
          selectedStudentIds.includes(enrollment.student.id)
        ).map((enrollment) => (
          <li className="_item" key={enrollment.id}>
            <div>
              <div className="_avatar">
                <TextAvatar name={enrollment.student.name} size="s" icon />
              </div>
              <div className="_body">
                <div className="_title">
                  {enrollment.student.name}
                </div>
                <div className="_description">
                  {enrollment.event_groups.map((eventGroup) => eventGroup.name)
                    .join(", ")}
                </div>
              </div>
              {props.show_automatic_settlement_enabled &&
                enrollment.allowed_to_settle_automatically && (
                <div className="_trailing">
                  <div className="credit-card-status --positive">
                    <span
                      className="icon credit-card-status__icon"
                      data-icon="check_on_circle"
                    />
                    <div className="credit-card-status__body">
                      <span>自動支払い</span>設定済み
                    </div>
                  </div>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>

      {open && (
        <BillAddresseeSelectorView
          inputRegister={props.inputRegister}
          show_automatic_settlement_enabled={props
            .show_automatic_settlement_enabled}
          classroom_event_groups={props.classroom_event_groups}
          enrollments={props.enrollments}
          onCloseButtonClick={() => setOpen(false)}
          selectedStudentIds={selectedStudentIds}
          setSelectedStudentIds={selectStudentIds}
          selectedEventGroupIds={selectedEventGroupIds}
          setSelectedEventGroupIds={setSelectedEventGroupIds}
        />
      )}
    </div>
  );
}
