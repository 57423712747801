import React, { useState } from "react";
import Turbolinks from "turbolinks";
import { FormProvider, useForm } from "react-hook-form";
import { useApi } from "../hooks/api";
import * as Sentry from "@sentry/browser";
import ErrorBanner from "./ErrorBanner";
import Snackbar from "./Snackbar";
import TextAvatar from "./TextAvatar";
import ClassroomEnrollmentCustomFieldFormSection from "./ClassroomEnrollmentPage/ClassroomEnrollmentCustomFieldFormSection";
import ClassroomEnrollmentStudentFormSection from "./ClassroomEnrollmentPage/ClassroomEnrollmentStudentFormSection";
import ClassroomEnrollmentConfirmDialog from "./ClassroomEnrollmentPage/ClassroomEnrollmentConfirmDialog";
import ClassroomEnrollmentUserFormSection from "./ClassroomEnrollmentPage/ClassroomEnrollmentUserFormSection";

interface Props {
  classroom_enrollment_params: ClassroomEnrollmentParams;
  errors: string[];
  classroom: Classroom;
  students: Student[];
  addresses: Address[];
  custom_fields: EnrollmentCustomField[];
  classroom_path: string;
  enrollments_path: string;
  settings_path: string;
}

export interface ClassroomEnrollmentParams {
  student_id: string;
  student_name: string;
  student_name_kana: string;
  student_dependent: string;
  student_address: number;
  student_date_of_birth: string;
  student_gender: string;
  student_address_id: string;
  student_address_postal_code: number;
  student_address_address: string;
  user_name: string;
  user_phone_number: string;
  optional_bill_id: number;
  allowed_to_settle_automatically: boolean;
  custom_fields: {
    custom_field_id: number;
    custom_field_option_id: number;
    name: string;
    value: string;
  }[];
}

export interface Classroom {
  name: string;
  icon: string;
}

export interface Student {
  id: number;
  name: string;
  name_kana: string;
  date_of_birth: string;
  dependent: boolean;
  gender: string;
}

export interface Address {
  id: number;
  postal_code: string;
  address: string;
}

export interface EnrollmentCustomField {
  id: number;
  name: string;
  field_type: "free_text" | "single_select";
  options?: {
    id: number;
    option_value: string;
  }[];
}

export default function ClassroomEnrollmentPage(props: Props): JSX.Element {
  const [message, setMessage] = useState<string>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const methods = useForm<ClassroomEnrollmentParams>({
    defaultValues: {
      ...props.classroom_enrollment_params,
      student_id: props.students?.[0]?.id?.toString(),
      student_name: props.students?.[0]?.name,
      student_name_kana: props.students?.[0]?.name_kana,
    },
    mode: "all",
  });
  const {
    register,
    trigger,
    watch,
    handleSubmit,
    formState: { isValid },
  } = methods;

  const { post } = useApi();
  const onSubmit = async (data) => {
    setLoading(true);
    const { redirect_url, message, response } = await post(
      props.enrollments_path,
      {
        enroll_event_group_form: data,
      },
    );
    if (response.ok) {
      Turbolinks.visit(redirect_url);
    } else {
      Sentry.captureMessage(
        `入会リクエストでエラーが発生しました。form_action: "${props.enrollments_path}"`,
      );
      setMessage(message);
      setLoading(false);
    }
  };

  const onConfirmDialogOpen = async () => {
    await trigger();
    if (isValid) {
      setOpenConfirmDialog(true);
    } else {
      setMessage("入力内容に不備があります");
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="modal-content">
        <header className="modal-header">
          <h1 className="_title">
            受講者登録
          </h1>

          <div className="_container">
            <div className="_nav">
              <a
                href={props.classroom_path}
                className="button -appearance-transparent -color-neutral -shape-circle"
                aria-label="教室の詳細へ戻る"
              >
                <div className="_leading">
                  <span className="icon _icon" data-icon="arrow_left"></span>
                </div>
              </a>
            </div>
          </div>
        </header>

        <form onSubmit={handleSubmit(onSubmit)} id="enrollment-form">
          {props.errors && <ErrorBanner errors={props.errors} />}

          <input type="hidden" {...register("optional_bill_id")} />

          <div className="enroll-event-group">
            <section className="enroll-event-group__course">
              <div className="container">
                <h2 className="enroll-event-group__course-title">
                  教室
                </h2>

                <div className="enroll-event-group__course-body">
                  <div className="container --revert">
                    <div className="course-list">
                      <ul className="_list">
                        <li className="_item">
                          <div>
                            <div className="_leading">
                              {props.classroom.icon
                                ? (
                                  <div className="avatar -size-m">
                                    <img src={props.classroom.icon} />
                                  </div>
                                )
                                : (
                                  <TextAvatar
                                    name={props.classroom.name}
                                    size="m"
                                  />
                                )}
                            </div>

                            <div className="_body">
                              <div className="_title">
                                {props.classroom.name}
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="enroll-event-group__student">
              <div className="container">
                <h2 className="enroll-event-group__student-title">
                  教室に入会する受講者
                </h2>

                <div className="enroll-event-group__student-body">
                  <div className="enroll-event-group__student-note">
                    <ul>
                      <li>
                        <b>『本人』</b>が入会する場合はご本人の情報を入力
                      </li>
                      <li>
                        入会するのは<b>
                          お子様など『本人以外』
                        </b>の場合、実際の受講者の情報を入力
                      </li>
                      <li>
                        <abbr className="_require" title="アスタリスク">*</abbr>
                        は必須項目です
                      </li>
                    </ul>
                  </div>

                  <ClassroomEnrollmentStudentFormSection
                    students={props.students}
                    addresses={props.addresses}
                  />

                  <div className="form form-custom">
                    <ClassroomEnrollmentCustomFieldFormSection
                      customFields={props.custom_fields}
                    />
                  </div>

                  <ClassroomEnrollmentUserFormSection
                    initialUserName={props.classroom_enrollment_params
                      .user_name}
                    initialUserPhoneNumber={props.classroom_enrollment_params
                      .user_phone_number}
                    currentStudentDependent={watch("student_dependent")}
                    settings_path={props.settings_path}
                  />
                </div>
              </div>
            </section>

            <section className="enroll-event-group__automatic-settlement">
              <div className="container">
                <h2 className="enroll-event-group__automatic-settlement-title">
                  教室に対して自動支払いを許可
                </h2>
                <div className="enroll-event-group__automatic-settlement-note">
                  <ul>
                    <li>教室からの請求に自動で支払いできる機能です</li>
                    <li>
                      自動支払いには「自動支払いへの許可」と「クレジットカードの登録」または「口座登録」が必要です
                    </li>
                    <li>ここでは「自動支払いの許可」が行えます</li>
                    <li>
                      自動支払いの設定は、いつでも設定・解除ができるのでご安心ください
                    </li>
                  </ul>
                </div>
                <div className="enroll-event-group__automatic-settlement-body">
                  決済システム『Stripe』へクレジットカード情報を、または決済システム『PGマルチペイメントサービス』へ口座情報を保存することを承諾し、別途「入会済みの教室」への許可を取り消されるまで、当該教室における今後の取引に際し、保存されたクレジットカード情報または口座情報を使用して自動支払いすることを許可します。
                  <br />
                  取引の例：月謝、用具代、雑費、参加費など（詳細は各教室にて請求内容をご確認ください）
                  <div className="form">
                    <div className="form__item">
                      <div className="form__control">
                        <div className="checkfield --layout-inside">
                          <label
                            htmlFor="allowed_to_settle_automatically"
                            className="_item"
                          >
                            <input
                              {...register("allowed_to_settle_automatically")}
                              id="allowed_to_settle_automatically"
                              className="checkbox"
                              type="checkbox"
                            />
                            <span className="_label">
                              {props.classroom.name}
                              に自動支払いを許可する［任意］
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="enroll-event-group__terms">
              <div className="container">
                <h2 className="enroll-event-group__terms-title">
                  個人情報の取り扱いについて
                </h2>

                <div className="enroll-event-group__terms-body">
                  <p>
                    教室に入会すると、受講者情報およびアカウント情報を教室の運営者が確認できるようになります。個人情報の取り扱いについては教室の運営者にお尋ねください。
                  </p>
                </div>
              </div>
            </section>

            <div className="enroll-event-group__action">
              <div className="container">
                {!isValid &&
                  (
                    <div className="form__error -align-center">
                      未入力の項目があります
                    </div>
                  )}
                <button
                  type="button"
                  className="button -appearance-filled -color-interactive -width-full"
                  onClick={onConfirmDialogOpen}
                  disabled={!isValid}
                >
                  <div className="_body">
                    確認画面へ
                  </div>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      {openConfirmDialog && (
        <ClassroomEnrollmentConfirmDialog
          classroom={props.classroom}
          classroomEnrollmentParams={watch()}
          onConfirmDialogClose={() => setOpenConfirmDialog(false)}
          onSubmit={handleSubmit(onSubmit)}
          loading={loading}
        />
      )}

      {message && <Snackbar>{message}</Snackbar>}
    </FormProvider>
  );
}
