import React from "react";
import ParticipantsSelector from "components/EventForm/ParticipantsSelector";

export interface EventGroup {
  id: number;
  name: string;
  root: boolean;
}

export interface Enrollment {
  id: number;
  student: {
    id: number;
    name: string;
  };
  event_groups: EventGroup[];
  allowed_to_settle_automatically: boolean;
}

export interface Props {
  form_name: string;
  attributes_name: string;
  addressee_attribute_name: string;
  show_automatic_settlement_enabled: boolean;
  classroom_event_groups: EventGroup[];
  enrollments: Enrollment[];
  selected_student_ids: number[];
}

export default function EventForm(
  props: Props,
): JSX.Element {
  return <ParticipantsSelector {...props} />;
}
