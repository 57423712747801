import React from "react";
import "./Notice.scss";

export default function Notice(
  {
    children,
  },
): JSX.Element {
  return (
    <div className="notice">
      <img className="icon" src="/images/hint.svg" />
      <div className="message">{children}</div>
    </div>
  );
}
