import React, { useEffect, useState } from "react";
import { localizeDateToYearMonthDayTime } from "../../utils/localize-date";

interface Event {
  id: number;
  name: string;
  url: string;
  starts_at: Date;
  status: string;
  publish_scope: string;
}

interface Props {
  getEvents: (url: string) => Promise<{ events: unknown[] }>;
  events_url: string;
  new_event_url: string;
}

export default function MemberEvents(props: Props) {
  const [events, setEvents] = useState<Event[] | null>(
    null,
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { events } = await props.getEvents(
        props.events_url,
      );
      setEvents(events as Event[]);
      setLoading(false);
    })();
  }, [props.events_url]);

  return (
    <div className="page__section">
      <div className="page__container">
        <div className="user">
          <div className="user__data">
            <div className="user__data-table__action">
              <a
                href={props.new_event_url}
                className="button -appearance-outlined  -color-interactive -size-s"
              >
                <div className="_leading">
                  <span className="icon" data-icon="plus"></span>
                </div>
                <div className="_body">
                  予定作成
                </div>
              </a>
            </div>
            <div className="user__data-table__wrapper">
              <table
                aria-label="予定一覧"
                className="user__data-table"
              >
                <thead>
                  <tr>
                    <th className="-width-third">
                      開始日時
                    </th>
                    <th>
                      タイトル
                    </th>
                    <th>
                      出欠
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {loading || events === null
                    ? (
                      <tr>
                        <td>
                          <span className="user__data-table__loading">
                          </span>
                        </td>
                        <td>
                          <span className="user__data-table__loading">
                          </span>
                        </td>
                        <td>
                          <span className="user__data-table__loading">
                          </span>
                        </td>
                      </tr>
                    )
                    : events.length === 0
                    ? (
                      <tr>
                        <td colSpan={3}>お知らせしている予定はありません</td>
                      </tr>
                    )
                    : (
                      events.map((event: Event) => (
                        <tr key={event.id}>
                          <td>
                            <div>
                              {localizeDateToYearMonthDayTime(
                                event.starts_at,
                              )}
                            </div>
                          </td>
                          <td className="-overflow-ellipsis">
                            <a href={event.url} data-turbolinks="false">
                              {event.name}
                            </a>
                          </td>
                          <td>
                            {event.status === "出席" && (
                              <>
                                <span className="user__data-table__status --positive">
                                  <span
                                    className="icon --positive"
                                    data-icon="check_on_circle"
                                  >
                                  </span>
                                  {event.status}
                                </span>
                              </>
                            )}
                            {event.status === "欠席" && (
                              <>
                                <span className="user__data-table__status --negative">
                                  <span
                                    className="icon"
                                    data-icon="cross_on_circle"
                                  />
                                  {event.status}
                                </span>
                              </>
                            )}
                            {event.status === "未定" && (
                              <>
                                <span className="user__data-table__status --notice">
                                  <span
                                    className="icon"
                                    data-icon="ellipsis_horizontal_on_circle"
                                  />
                                  {event.status}
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
