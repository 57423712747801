import React, { ReactNode } from "react";

interface Props {
  title?: string;
  description?: string | ReactNode;
  onCloseButtonClick: () => void;
  children?: ReactNode;
}

export default function Dialog(
  { title, description, onCloseButtonClick, children }: Props,
) {
  return (
    <div
      className="dialog-v2 --visible"
      role="dialog"
      aria-labelledby="dialog__title"
      aria-describedby="dialog__description"
      aria-modal="true"
    >
      <div
        className="dialog-v2__shadow"
        onClick={onCloseButtonClick}
      />

      <div className="dialog-v2__wrapper">
        <div className="dialog-v2__container">
          {title && (
            <h2 className="dialog-v2__title" id="dialog__title">
              {title}
            </h2>
          )}
          {description && (
            <div className="dialog-v2__description" id="dialog__description">
              {description}
            </div>
          )}

          <div className="dialog-v2__action">
            <ul className="button-group">
              <li className="_item">
                <button
                  type="button"
                  className="button -appearance-transparent -size-m -color-neutral"
                  onClick={onCloseButtonClick}
                >
                  <div className="_body">
                    キャンセル
                  </div>
                </button>
              </li>

              <li className="_item">
                {children}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
