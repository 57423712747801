import React, { useEffect, useState } from "react";
import TableRow, {
  EventGroup,
  Report,
} from "components/BillGroupsReport/TableRow";

const reportToSearchableText = (report: Report): string => {
  const studentName = report.student.name;
  const studentNameKana = report.student.name_kana;
  const eventGroupNames = report.enrolled_event_groups.map((eg) => eg.name);
  return `${studentName} ${studentNameKana} ${eventGroupNames}`;
};

interface Props {
  reports: Report[];
  prev_month: number;
  current_month: number;
  next_month: number;
  all_event_groups: EventGroup[];
}

export default function BillGroupsReport(
  { reports, prev_month, current_month, next_month, all_event_groups }: Props,
): JSX.Element {
  const [filteredReports, setFilteredReports] = useState(reports);
  const [checkedFilters, setCheckedFilters] = useState([]);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  const searchCheckBoxElements = [{
    id: `filter-checkbox-none`,
    name: "コース未参加",
  }].concat(all_event_groups.map((eg) => {
    return { id: `filter-checkbox-${eg.id}`, name: eg.name };
  }));

  const handleKeywordSearch = (keyword: string) => {
    if (keyword == "") {
      setFilteredReports(reports);
      return;
    }

    const searchKeywords = keyword.trim().toLowerCase().match(/[^\s]+/g);
    if (searchKeywords === null) {
      setFilteredReports(reports);
      return;
    }

    const result = reports.filter((report) => {
      const searchableText = reportToSearchableText(report);
      return searchKeywords.every((kw) =>
        searchableText.toLowerCase().indexOf(kw) !== -1
      );
    });

    setFilteredReports(result);
  };

  useEffect(() => {
    if (checkedFilters.length === 0) {
      setFilteredReports(reports);
      return;
    }

    const filteredEventGroupName = checkedFilters.map((id) =>
      searchCheckBoxElements.find((elm) => elm.id === id).name
    );
    const result = reports.filter((report) => {
      const enrolledEventGroupName = report.enrolled_event_groups.map((eg) =>
        eg.name
      );
      return filteredEventGroupName.some((filter) => {
        if (filter === "コース未参加") {
          return enrolledEventGroupName.length === 0;
        } else {
          return enrolledEventGroupName.some((name) => name === filter);
        }
      });
    });

    setFilteredReports(result);
  }, [checkedFilters]);

  const handleChangeFilterCheckbox = (target) => {
    if (target.checked) {
      setCheckedFilters([...checkedFilters, target.id]);
    } else {
      setCheckedFilters(
        checkedFilters.filter((checkBoxId) => checkBoxId !== target.id),
      );
    }
  };

  return (
    <>
      <div className="billgroup-report__header">
        <div className="billgroup-report__action">
          <div className="billgroup-report__search -icon-before">
            <input
              type="search"
              className="_input"
              placeholder="名前,コース名で検索"
              onChange={(e) => handleKeywordSearch(e.target.value)}
              aria-controls="billgroup-report-table"
            />
            <span className="_icon icon" data-icon="magnifying_glass" />
          </div>
          <button
            className="billgroup-report__button"
            type="button"
            aria-controls="enrolled-event-groups__list"
            aria-expanded={isFiltersVisible ? "true" : "false"}
            onClick={() => setIsFiltersVisible(!isFiltersVisible)}
          >
            <div className="_leading">
              <span className="icon" data-icon="funnel" />
            </div>
            <div className="_body">
              コース絞り込み
            </div>
          </button>
        </div>
        {isFiltersVisible && (
          <div
            id="enrolled-event-groups__list"
            className="enrolled-event-groups__list"
            aria-hidden="false"
          >
            <ul className="_list">
              {searchCheckBoxElements.map((elm) => (
                <li className="_item" key={elm.id}>
                  <label htmlFor={elm.id}>
                    <div className="_leading">
                      <input
                        className="checkbox"
                        type="checkbox"
                        id={elm.id}
                        checked={checkedFilters.includes(elm.id)}
                        onChange={(e) =>
                          handleChangeFilterCheckbox(e.target)}
                        aria-controls="billgroup-report-table"
                      />
                    </div>
                    <div className="_body">
                      {elm.name}
                    </div>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="billgroup-report__wrapper">
        <table
          id="billgroup-report-table"
          aria-label="請求作成レポート"
          className="billgroup-report"
        >
          <thead>
            <tr>
              <th scope="col">
                受講者名
              </th>
              <th scope="col">
                コース名
              </th>
              <th className="-align-end" scope="col">
                {Number(prev_month)}月
              </th>
              <th className="-align-end" scope="col">
                {Number(current_month)}月（今月）
              </th>
              <th className="-align-end" scope="col">
                {Number(next_month)}月
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredReports.map((report) => (
              <TableRow key={report.student.id} report={report} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
