export function deadlineAdditionalMonth(additional_month: number): string {
  switch (additional_month) {
    case 0:
      return "当月";
    case 1:
      return "翌月";
  }
}

export function deadlineMonthlyDay(day: number): string {
  switch (day) {
    case 31:
      return "末";
    default:
      return day.toString();
  }
}
