import React from "react";
import ConfigDeleteClassroom from "components/ManagementClassroomSettings/ConfigDeleteClassroom";
import ConfigSubscription from "components/ManagementClassroomSettings/ConfigSubscription";
import TabHeader from "components/ManagementClassroomSettings/TabHeader";

export default function Config(
  {
    classroom,
    formAuthenticityToken,
  },
): JSX.Element {
  if (!classroom.policyCanShow) {
    return null;
  }

  return (
    <div>
      <div className="page__container">
        <TabHeader classroom={classroom} />
      </div>

      <ConfigSubscription
        classroom={classroom}
        formAuthenticityToken={formAuthenticityToken}
      />

      {classroom.policyCanDestroyManagementOrganizations && (
        <ConfigDeleteClassroom
          classroom={classroom}
          formAuthenticityToken={formAuthenticityToken}
        />
      )}
    </div>
  );
}
