import React, { useEffect, useState } from "react";
import { ModalBackButton } from "./ModalBackButton";
import ManagementCustomFieldsItem from "./ManagementCustomFieldsPage/ManagementCustomFieldsItem";
import Snackbar from "./Snackbar";

export type CustomField = FreeTextCustomField | SingleSelectCustomField;
type FreeTextCustomField = CustomFieldBase & {
  field_type: "free_text";
  options?: CustomFieldOption[];
};
type SingleSelectCustomField = CustomFieldBase & {
  field_type: "single_select";
  options: CustomFieldOption[];
};
interface CustomFieldBase {
  id: number;
  name: string;
  required_on_enrollment: boolean;
  isEditing?: boolean;
  update_custom_field_path?: string;
}
interface CustomFieldOption {
  id: number;
  option_value: string;
}
interface Props {
  custom_fields: CustomField[];
  create_custom_field_path: string;
  new_classroom_enrollment_path: string;
  policy_can_create_management_classrooms_custom_fields: boolean;
  policy_can_update_and_destroy_management_classrooms_custom_fields: boolean;
}

const newCustomField: CustomField = {
  id: undefined,
  name: "",
  field_type: "free_text",
  required_on_enrollment: false,
  options: [],
  isEditing: true,
  update_custom_field_path: undefined,
};

export default function ManagementCustomFieldsPage(props: Props): JSX.Element {
  const [dropdownIndex, setDropdownIndex] = useState<number>(null);
  const [customFields, setCustomFields] = useState(props.custom_fields);
  const [message, setMessage] = useState<string>(null);

  return (
    <>
      <h1 className="visually-hidden">
        受講者情報のテンプレート
      </h1>

      <div className="modal-content">
        <header className="modal-header">
          <div className="_title">
            受講者情報のテンプレート
          </div>

          <div className="_container">
            <div className="_nav">
              <ModalBackButton />
            </div>
          </div>
        </header>

        <div className="modal-content__container">
          <div className="custom-field__collection">
            <table
              aria-label="受講者情報のカスタム項目"
              className="custom-field__collection-table"
            >
              <thead>
                <tr>
                  <th role="columnheader" scope="col">
                    項目
                  </th>
                  <th role="columnheader" scope="col">
                    形式
                  </th>
                  <th role="columnheader" />
                </tr>
              </thead>

              <tbody>
                {/* デフォルト項目 */}
                <tr>
                  <td>
                    <span className="status-label -color-positive">
                      入会時の設問
                    </span>
                    <span className="_custom-field-name">性別</span>
                  </td>
                  <td className="_custom-field-option">
                    <div className="_custom-field-type">選択式</div>
                    <span>設定しない</span>
                    <span>男性</span>
                    <span>女性</span>
                    <span>別の性</span>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>
                    <span className="status-label -color-positive">
                      入会時の設問
                    </span>
                    <span className="_custom-field-name">住所</span>
                  </td>
                  <td className="_custom-field-option">
                    <div className="_custom-field-type">自由記述式</div>
                  </td>
                  <td />
                </tr>

                {/* 独自項目 */}
                {customFields.map((customField, index) => (
                  <ManagementCustomFieldsItem
                    key={`${index}-${customField.id}`}
                    customField={customField}
                    create_custom_field_path={props.create_custom_field_path}
                    policy_can_update_and_destroy_management_classrooms_custom_fields={props
                      .policy_can_update_and_destroy_management_classrooms_custom_fields}
                    removeItem={() => {
                      const newCustomFields = customFields.slice(0, index)
                        .concat(customFields.slice(index + 1));
                      setCustomFields(newCustomFields);
                    }}
                    showMessage={(message: string) => setMessage(message)}
                    showIndex={index}
                    onOpenDropdown={() => setDropdownIndex(index)}
                    openDropdown={dropdownIndex === index}
                    onClosedDropdown={() => setDropdownIndex(null)}
                  />
                ))}
              </tbody>
            </table>
            <div className="custom-field__collection-action">
              {props.policy_can_create_management_classrooms_custom_fields && (
                <button
                  type="button"
                  className="button -appearance-transparent -size-m -width-full -color-interactive"
                  onClick={() =>
                    setCustomFields([...customFields, newCustomField])}
                >
                  <div className="_leading">
                    <span className="icon _icon" data-icon="plus_on_circle">
                    </span>
                  </div>
                  <div className="_body">
                    項目を追加
                  </div>
                </button>
              )}
              <a
                href={props.new_classroom_enrollment_path}
                target="_blank"
                rel="noreferrer noopener"
                className="button -appearance-transparent -size-m -color-interactive"
              >
                <div className="_leading">
                  <span className="icon _icon" data-icon="launch"></span>
                </div>
                <div className="_body">
                  入会フォームを表示
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      {message && <Snackbar>{message}</Snackbar>}
    </>
  );
}
