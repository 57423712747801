import React, { useEffect, useState } from "react";
import MemberEventGroupUpdatingModal from "./MemberEventGroupUpdatingModal";
import MemberDescriptionForm from "./MemberDescriptionForm";
import { localizeDateToYmdString } from "utils/localize-date";
import localizeGender from "../../utils/localize-gender";
import MemberCustomFields from "components/ManagementMemberPage/MemberCustomFields";
import MemberFamilies from "components/ManagementMemberPage/MemberFamilies";
import { useApi } from "hooks/api";
import { Enrollment } from "components/ManagementMemberPage/ManagementMemberPageContainer";
import { numberFormat } from "utils/number-format";
import MemberRecurrenceBillsUpdatingModal from "components/ManagementMemberPage/MemberRecurrenceBillsUpdatingModal";
import {
  deadlineAdditionalMonth,
  deadlineMonthlyDay,
} from "utils/recurrence-bill-deadline-convertor";
import { useToast } from "components/Toast/use-toast";
import { Toaster } from "components/Toast/toaster";

interface Props {
  getEnrolledEventGroups: (url: string) => Promise<{
    form_action: string;
    event_groups: unknown[];
  }>;
  getRecurrenceBills: (url: string) => Promise<{
    form_action: string;
    recurrence_bills: unknown[];
  }>;
  handleUpdateEnrolledEventGroups: (
    enrollmentId: number,
    url: string,
    data: unknown,
  ) => Promise<void>;
  handleUpdateRecurredBills: (
    enrollmentId: number,
    url: string,
    data: unknown,
  ) => Promise<void>;
  handleUpdateEnrollment: (
    enrollmentId: number,
    data: { enrollment: { description: string } },
  ) => Promise<void>;
  subscribed: boolean;
  enrollment: Enrollment;
  newCoursePath: string;
  newRecurrenceBillPath: string;
  recurrenceBillsShowAccess: boolean;
  studentsBulkEnrollmentEventGroupsIndexAccess: boolean;
}

export interface CustomField {
  custom_field_id: number;
  custom_field_value_id: number;
  custom_field_option_id: number;
  name: string;
  value: string;
  field_type: string;
  options: {
    id: number;
    option_value: string;
  }[];
  resource_url: string;
}

export interface Family {
  id: number;
  name: string;
}

interface DetailsResponse {
  families?: Family[];
  custom_fields?: CustomField[];
  message?: string;
}

export default (props: Props): JSX.Element => {
  const [families, setFamilies] = useState<Family[]>([]);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const { toast } = useToast();

  const { requestAsJson } = useApi();
  const [updatingCoursesModalVisible, setUpdatingCoursesModalVisible] =
    useState(
      false,
    );
  const [
    updatingRecurrenceBillsModalVisible,
    setUpdatingRecurrenceBillsModalVisible,
  ] = useState(
    false,
  );

  // モーダルのスクロール位置を最上部に設定する
  const scrollToTop = () => {
    const element = document.getElementById("admin-table__single");
    if (element) {
      element.scroll({ top: 0 });
    }
  };

  useEffect(() => {
    (async () => {
      const result = await requestAsJson<DetailsResponse>({
        path: props.enrollment.details_url,
        method: "GET",
      });
      if (result.families) {
        setFamilies(result.families);
      }
      if (result.custom_fields) {
        setCustomFields(result.custom_fields);
      }
      if (result.message) {
        toast({
          title: result.message,
          description: "",
          children: null,
          variant: "default",
        });
      }
    })();
  }, [props.enrollment.details_url]);

  return (
    <>
      <div className="page__container">
        <div className="user">
          <div className="user__data">
            {props.enrollment.student.gender && (
              <dl>
                <dt>
                  性別
                </dt>
                <dd>{localizeGender(props.enrollment.student.gender)}</dd>
              </dl>
            )}
            {props.enrollment.student.date_of_birth && (
              <dl>
                <dt>
                  生年月日
                </dt>
                <dd>
                  {localizeDateToYmdString(
                    props.enrollment.student.date_of_birth,
                  )}
                </dd>
              </dl>
            )}
            {props.enrollment.student.dependent && (
              <dl>
                <dt>
                  保護者
                </dt>
                <dd>
                  {props.enrollment.student.users[0].name} <br />
                  <div>
                    連絡先：
                    <a
                      href={`tel:${
                        props.enrollment.student.users[0].phone_number
                      }`}
                    >
                      {props.enrollment.student.users[0].phone_number}
                    </a>
                  </div>
                </dd>
              </dl>
            )}
            <MemberFamilies
              families={families}
            />
            {props.enrollment.student.address && (
              <dl>
                <dt>
                  住所
                </dt>
                <dd>
                  〒{props.enrollment.student.address.postal_code}
                  <br />
                  {props.enrollment.student.address.address}
                </dd>
              </dl>
            )}
            {!props.enrollment.student.dependent &&
              props.enrollment.student.users[0] &&
              props.enrollment.student.users[0].phone_number && (
              <dl>
                <dt>
                  連絡先
                </dt>
                <dd>
                  <a
                    href={`tel:${
                      props.enrollment.student.users[0].phone_number
                    }`}
                  >
                    {props.enrollment.student.users[0].phone_number}
                  </a>
                </dd>
              </dl>
            )}
            <dl>
              <dt>
                内部メモ
              </dt>
              <dd className="user__data-description__form">
                <MemberDescriptionForm
                  enrollment={props.enrollment}
                  handleUpdateEnrollment={props.handleUpdateEnrollment}
                />
              </dd>
            </dl>
            <MemberCustomFields
              customFields={customFields}
              customFieldsUrl={props.enrollment.custom_fields_url}
            />
          </div>

          <div className="user__data-list__course">
            <div className="page__section-head">
              <h2 className="page__title --level-2">
                所属
              </h2>
              {props.subscribed &&
                props.studentsBulkEnrollmentEventGroupsIndexAccess && (
                <div className="page__action button__openUpdatingCourses -appearance-transparent">
                  <button
                    onClick={() => {
                      // モーダル表示の処理
                      setUpdatingCoursesModalVisible(true);
                      scrollToTop();
                    }}
                  >
                    <div className="_leading">
                      <span className="icon _icon" data-icon="pencil"></span>
                    </div>
                    <div className="_body">
                      編集
                    </div>
                  </button>
                </div>
              )}
            </div>
            <ul className="_list">
              {props.enrollment.enrolled_event_groups.map((event_group) => (
                <li className="_item" key={event_group.id}>
                  <a
                    href={`./courses/${event_group.slug}`}
                    rel=""
                    data-turbolinks="false"
                  >
                    <div className="_body">
                      <div className="_title">{event_group.name}</div>
                      <div className="_description">
                        {event_group.enrolled_at} 登録
                      </div>
                    </div>
                    <div className="_trailing">
                      <div className="_icon">
                        <span className="icon" data-icon="chevron_right" />
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {props.recurrenceBillsShowAccess
            ? (
              <div className="user__data-list__recurrence-bill">
                <div className="page__section-head">
                  <h2 className="page__title --level-2">
                    定期請求
                  </h2>
                  {props.subscribed && (
                    <div className="page__action button__openUpdatingRecurrenceBills -appearance-transparent">
                      <button
                        onClick={() => {
                          // モーダル表示の処理
                          setUpdatingRecurrenceBillsModalVisible(true);
                          scrollToTop();
                        }}
                      >
                        <div className="_leading">
                          <span className="icon _icon" data-icon="pencil">
                          </span>
                        </div>
                        <div className="_body">
                          編集
                        </div>
                      </button>
                    </div>
                  )}
                </div>
                <ul className="_list">
                  {props.enrollment.recurrence_bills.map((recurrence_bill) => (
                    <li className="_item" key={recurrence_bill.id}>
                      <a
                        href={recurrence_bill.path}
                        rel=""
                        data-turbolinks="false"
                      >
                        <div className="_body">
                          <div className="_title">{recurrence_bill.name}</div>
                          <div className="_description">
                            {`${
                              numberFormat(recurrence_bill.amount)
                            }円 毎月${recurrence_bill.by_month_day}日 ${
                              deadlineAdditionalMonth(
                                recurrence_bill.deadline_additional_month,
                              )
                            }${
                              deadlineMonthlyDay(
                                recurrence_bill.deadline_month_day,
                              )
                            }日まで`}
                          </div>
                        </div>
                        <div className="_trailing">
                          <div className="_icon">
                            <span className="icon" data-icon="chevron_right" />
                          </div>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )
            : null}
        </div>
        {updatingCoursesModalVisible && (
          <MemberEventGroupUpdatingModal
            getEnrolledEventGroups={props.getEnrolledEventGroups}
            onUpdateEnrolledEventGroups={async (url, data) => {
              await props.handleUpdateEnrolledEventGroups(
                props.enrollment.id,
                url,
                data,
              );
            }}
            onCloseButtonClick={() => setUpdatingCoursesModalVisible(false)}
            bulkEnrollmentEventGroupsUrl={props.enrollment
              .bulk_enrollment_event_groups_url}
            newCoursePath={props.newCoursePath}
          />
        )}
        {updatingRecurrenceBillsModalVisible && (
          <MemberRecurrenceBillsUpdatingModal
            getRecurrenceBills={props.getRecurrenceBills}
            onUpdateRecurrenceBills={async (url, data) => {
              await props.handleUpdateRecurredBills(
                props.enrollment.id,
                url,
                data,
              );
            }}
            onCloseButtonClick={() =>
              setUpdatingRecurrenceBillsModalVisible(false)}
            recurrenceBillsPath={props.enrollment
              .recurrence_bills_path}
            newRecurrenceBillPath={props.newRecurrenceBillPath}
          />
        )}
      </div>
    </>
  );
};
