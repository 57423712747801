import React from "react";

interface Props {
  backButtonLinkPath?: string;
}
export function ModalBackButton({ backButtonLinkPath }: Props) {
  const handleClick = () => {
    if (backButtonLinkPath) {
      window.location.href = backButtonLinkPath;
    } else {
      window.history.back();
    }
  };

  return (
    <>
      <button
        className="button -appearance-transparent -color-neutral -shape-circle"
        aria-label="前の画面へ戻る"
        type="button"
        onClick={handleClick}
      >
        <div className="_leading">
          <span className="icon _icon" data-icon="arrow_left"></span>
        </div>
      </button>
    </>
  );
}
