import React, { useState } from "react";

import { CustomField } from "../ManagementMemberPage/MemberCustomFields";
import Dialog from "../Dialog";
import { useApi } from "../../hooks/api";
import MemberCustomFieldItemForm from "./MemberCustomFieldItemForm";

interface Props {
  customField: CustomField;
  customFieldsUrl: string;
  customFieldId: number;
  showMessage: (message: string) => void;
}

export default function MemberCustomFieldItem(props: Props): JSX.Element {
  const [customField, setCustomField] = useState(props.customField);
  const [isEditing, setIsEditing] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [deletingPopupVisible, setDeletingPopupVisible] = useState(false);
  const { requestWithCsrfToken } = useApi();
  const handleBlur = (event) => {
    const isButtonFocused = event.relatedTarget?.tagName === "BUTTON";
    if (!isButtonFocused) {
      setOpenDropdown(false);
    }
  };
  const isValidValue = typeof customField.value === "string" &&
    customField.value !== "";

  return (
    <>
      {isEditing
        ? (
          <MemberCustomFieldItemForm
            customField={customField}
            customFieldsUrl={props.customFieldsUrl}
            commitEditing={(customField) => {
              setCustomField(customField);
              setIsEditing(false);
              setOpenDropdown(false);
            }}
            exitEditing={() => {
              setIsEditing(false);
              setOpenDropdown(false);
            }}
            showMessage={props.showMessage}
          />
        )
        : (
          <dl className="custom-field__item">
            <dt>{customField.name}</dt>
            <dd>
              {isValidValue ? customField.value : "（未回答）"}
              <div className="dropdown --position-right">
                <button
                  onClick={() => setOpenDropdown(!openDropdown)}
                  onBlur={handleBlur}
                  className="dropdown__control button -appearance-transparent -shape-circle -size-m"
                  aria-label="操作"
                  aria-controls={`dropdown-item-${props.customFieldId}`}
                  aria-expanded={openDropdown ? "true" : "false"}
                >
                  <div className="_leading">
                    <span className="_icon icon" data-icon="ellipsis_vertical">
                    </span>
                  </div>
                </button>
                {openDropdown && (
                  <ul
                    id={`dropdown-item-${props.customFieldId}`}
                    className="_list dropdown__list"
                  >
                    <li className="_item dropdown__item">
                      <button onClick={() => setIsEditing(true)}>
                        <div className="_body">
                          <div className="_title">
                            編集
                          </div>
                        </div>
                      </button>
                    </li>
                    {customField.custom_field_value_id && (
                      <li className="_item dropdown__item">
                        <button
                          onClick={() => {
                            setDeletingPopupVisible(true);
                            setOpenDropdown(false);
                          }}
                        >
                          <div className="_body">
                            <div className="_title">
                              未回答に戻す
                            </div>
                          </div>
                        </button>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </dd>
          </dl>
        )}
      {deletingPopupVisible && (
        <Dialog
          title={`カスタム項目「${customField.name}」を未回答の状態に戻しますか？`}
          description="この受講者の回答は削除され、未回答の状態になります。"
          onCloseButtonClick={() => setDeletingPopupVisible(false)}
        >
          <button
            className="button -appearance-transparent -size-m -color-negative"
            onClick={async () => {
              const { message } = await requestWithCsrfToken<
                { message: string }
              >({
                form_action: customField.resource_url,
                form_method: "DELETE",
              });
              setCustomField({
                ...customField,
                value: "",
              });
              setDeletingPopupVisible(false);
              props.showMessage(message);
            }}
          >
            <div className="_body">
              未回答に戻す
            </div>
          </button>
        </Dialog>
      )}
    </>
  );
}
