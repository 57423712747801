import React, { useMemo } from "react";
import { colorClassName } from "utils/color-class-name";

export default function CoverStripe(
  {
    classroomName = "1",
  },
): JSX.Element {
  return (
    <svg
      id="cover"
      className={`cover ${colorClassName(classroomName)}`}
      width="350"
      height="350"
      viewBox="0 0 350 350"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin slice"
    >
      <g clip-path="url(#clip0)">
        <rect
          width="350"
          height="350"
          fill={`url(#linear${colorClassName(classroomName)})`}
        />

        <g style={{ mixBlendMode: "soft-light" }} opacity="0.5">
          <path d="M267.55 313.33L204.2 350.08H232.1L274.58 325.44L267.55 313.33Z" />
          <path d="M286.95 318.26L350.08 281.64V265.46L279.92 306.15L286.95 318.26Z" />
          <path d="M302.06 350.08L350.08 322.22V306.04L274.16 350.08H302.06Z" />
          <path d="M350.08 350.08V346.62L344.12 350.08H350.08Z" />
          <path d="M0.0800781 35.5601L61.2401 0.0800781H33.3401L0.0800781 19.3701V35.5601Z" />
          <path d="M0.0800781 60.4601L6.88008 72.1901L123.53 4.53008L120.95 0.0800781H103.3L0.0800781 59.9501V60.4601Z" />
          <path d="M85.7701 297.02L0.0800781 346.73V350.08H22.2101L92.8001 309.14L85.7701 297.02Z" />
          <path d="M104.72 286.43C103.197 286.428 101.707 286.878 100.439 287.723C99.1719 288.568 98.1833 289.77 97.599 291.177C97.0147 292.583 96.8608 294.132 97.1568 295.626C97.4527 297.12 98.1854 298.493 99.2619 299.571C100.338 300.649 101.71 301.383 103.204 301.681C104.698 301.979 106.247 301.827 107.654 301.245C109.062 300.662 110.265 299.675 111.112 298.409C111.958 297.142 112.41 295.653 112.41 294.13C112.41 292.09 111.6 290.133 110.158 288.689C108.716 287.245 106.761 286.433 104.72 286.43V286.43Z" />
          <path d="M276.94 201.92C278.463 201.92 279.952 201.468 281.218 200.622C282.484 199.776 283.471 198.574 284.054 197.167C284.637 195.76 284.789 194.212 284.492 192.718C284.195 191.224 283.462 189.852 282.385 188.775C281.308 187.699 279.936 186.965 278.442 186.668C276.949 186.371 275.4 186.523 273.993 187.106C272.586 187.689 271.384 188.676 270.538 189.942C269.692 191.208 269.24 192.697 269.24 194.22C269.239 195.232 269.437 196.233 269.823 197.168C270.21 198.103 270.777 198.952 271.492 199.668C272.208 200.383 273.057 200.95 273.992 201.337C274.927 201.723 275.929 201.921 276.94 201.92V201.92Z" />
          <path d="M160.51 294.26L64.27 350.08H92.17L167.53 306.37L160.51 294.26Z" />
          <path d="M179.29 295.48C180.354 296.094 181.561 296.418 182.79 296.418C184.019 296.418 185.226 296.094 186.29 295.48L225.1 272.96L244.8 261.44L335.8 208.64L328.8 196.53L237.8 249.34L218.1 260.87L189.82 277.27V242.27H175.82V289.43C175.819 290.654 176.139 291.856 176.748 292.918C177.357 293.98 178.233 294.863 179.29 295.48Z" />
          <path d="M182.89 321.86L134.23 350.08H162.14L189.91 333.97L182.89 321.86Z" />
          <path d="M343.37 204.62L350.08 200.73V184.54L336.34 192.51L343.37 204.62Z" />
          <path d="M236.5 306.94L350.08 241.06V224.87L229.48 294.83L236.5 306.94Z" />
          <path d="M200.5 312.29C198.977 312.29 197.488 312.742 196.222 313.588C194.956 314.434 193.969 315.636 193.386 317.043C192.803 318.45 192.651 319.999 192.948 321.492C193.245 322.986 193.978 324.358 195.055 325.435C196.132 326.512 197.504 327.245 198.998 327.542C200.491 327.839 202.04 327.687 203.447 327.104C204.854 326.521 206.056 325.534 206.902 324.268C207.748 323.002 208.2 321.513 208.2 319.99C208.201 318.979 208.003 317.977 207.617 317.042C207.23 316.107 206.663 315.258 205.948 314.542C205.233 313.827 204.383 313.26 203.448 312.874C202.513 312.487 201.512 312.289 200.5 312.29Z" />
          <path d="M258.33 196.79L193.768 234.245L200.794 246.355L265.356 208.899L258.33 196.79Z" />
          <path d="M115.29 40.28C115.288 41.8034 115.738 43.2931 116.583 44.5607C117.428 45.8282 118.63 46.8167 120.037 47.401C121.443 47.9853 122.992 48.1392 124.486 47.8433C125.98 47.5473 127.353 46.8147 128.431 45.7383C129.509 44.6618 130.243 43.2898 130.541 41.7959C130.839 40.302 130.687 38.7533 130.105 37.3457C129.522 35.9381 128.535 34.735 127.269 33.8884C126.002 33.0419 124.513 32.5901 122.99 32.5901C120.95 32.5901 118.993 33.3999 117.549 34.8418C116.105 36.2837 115.293 38.2396 115.29 40.28V40.28Z" />
          <path d="M6.57008 140.32L0.0800781 144.08V160.26L13.5901 152.43L6.57008 140.32Z" />
          <path d="M162.181 49.8583L77.8457 98.785L84.8711 110.895L169.206 61.968L162.181 49.8583Z" />
          <path d="M223.24 30.7201L276.06 0.0800781H248.16L216.22 18.6101L223.24 30.7201Z" />
          <path d="M21.86 135.43C21.8688 136.656 22.1999 137.859 22.8197 138.917C23.4395 139.976 24.3265 140.852 25.3918 141.46C26.4572 142.068 27.6636 142.385 28.89 142.38C30.1165 142.374 31.3199 142.047 32.38 141.43L77.2201 115.43L70.2201 103.32L35.88 123.24V95.2401C35.8796 94.0115 35.5559 92.8047 34.9414 91.7408C34.3269 90.677 33.4432 89.7937 32.3792 89.1796C31.3151 88.5654 30.1081 88.2421 28.8795 88.2422C27.6509 88.2423 26.444 88.5658 25.38 89.1801L0.100098 103.8V119.97L21.88 107.37L21.86 135.43Z" />
          <path d="M28.9501 167.92L0.0800781 184.66V200.85L35.9701 180.03L28.9501 167.92Z" />
          <path d="M201.62 43.6001L210.3 62.7101L75.53 140.89L82.53 153L310.53 20.7301L303.53 8.62L222.44 55.62L211.14 30.7401C210.736 29.8493 210.149 29.053 209.418 28.4028C208.687 27.7526 207.828 27.263 206.896 26.9652C205.964 26.6675 204.981 26.5683 204.008 26.6742C203.036 26.7801 202.096 27.0887 201.25 27.5801L169.77 45.8401L176.77 57.9501L201.62 43.6001Z" />
          <path d="M323.7 13.1301L346.19 0.0800781H318.28L316.67 1.02008L323.7 13.1301Z" />
          <path d="M46.5602 173.74C48.084 173.742 49.574 173.292 50.8418 172.447C52.1096 171.601 53.0981 170.399 53.6821 168.991C54.2662 167.584 54.4195 166.035 54.1227 164.54C53.8259 163.046 53.0924 161.673 52.0149 160.595C50.9374 159.518 49.5645 158.784 48.07 158.488C46.5754 158.191 45.0263 158.344 43.6189 158.928C42.2115 159.512 41.009 160.501 40.1637 161.768C39.3184 163.036 38.8681 164.526 38.8701 166.05C38.8701 168.09 39.6804 170.046 41.1226 171.488C42.5647 172.93 44.5207 173.74 46.5602 173.74Z" />
          <path d="M104.367 42.9242L39.8047 80.3795L46.8301 92.4892L111.392 55.0339L104.367 42.9242Z" />
          <path d="M142.2 37.2401L206.26 0.0800781H178.36L135.17 25.1301L142.2 37.2401Z" />
          <path d="M288.29 71.1101C288.291 69.8801 287.967 68.6716 287.352 67.6063C286.737 66.5411 285.852 65.6567 284.787 65.0422C283.721 64.4278 282.512 64.1051 281.282 64.1064C280.052 64.1078 278.844 64.4333 277.78 65.0501L276.27 65.9301V65.8401L185.27 118.61L192.27 130.72L250.05 97.2001L274.26 83.2001V111.2C274.261 112.429 274.586 113.636 275.202 114.7C275.817 115.764 276.703 116.647 277.768 117.26C278.833 117.874 280.041 118.195 281.27 118.194C282.499 118.192 283.707 117.866 284.77 117.25L329.61 91.25L322.61 79.14L321.33 79.88L288.33 99.04L288.29 71.1101Z" />
          <path d="M29.3101 209.07L0.0800781 226.03V242.21L36.3401 221.18L29.3101 209.07Z" />
          <path d="M157.778 134.47L40.3137 202.616L47.3391 214.725L164.803 146.579L157.778 134.47Z" />
          <path d="M300.89 51.5401L307.91 63.6501L350.08 39.1901V24.0101L349.64 23.2601L300.89 51.5401Z" />
          <path d="M181.93 132.33C181.932 130.807 181.482 129.317 180.637 128.049C179.792 126.782 178.59 125.793 177.184 125.209C175.777 124.625 174.228 124.471 172.734 124.767C171.24 125.063 169.867 125.795 168.789 126.872C167.711 127.948 166.977 129.32 166.679 130.814C166.381 132.308 166.533 133.857 167.115 135.264C167.698 136.672 168.685 137.875 169.951 138.722C171.218 139.568 172.707 140.02 174.23 140.02C175.241 140.021 176.242 139.823 177.176 139.438C178.11 139.052 178.959 138.485 179.674 137.771C180.389 137.057 180.957 136.209 181.344 135.275C181.731 134.342 181.93 133.341 181.93 132.33V132.33Z" />
          <path d="M339.4 84.65C343.647 84.65 347.09 81.2071 347.09 76.96C347.09 72.7129 343.647 69.27 339.4 69.27C335.153 69.27 331.71 72.7129 331.71 76.96C331.71 81.2071 335.153 84.65 339.4 84.65Z" />
          <path d="M156.39 232.01L350.08 119.66V103.47L149.37 219.9L156.39 232.01Z" />
          <path d="M296.14 191.18L350.08 159.89V143.71L289.11 179.07L296.14 191.18Z" />
          <path d="M119.21 237.43L93.8 252.17L82.5 227.31C82.0967 226.419 81.5109 225.622 80.7806 224.971C80.0503 224.32 79.1917 223.829 78.26 223.53C77.3287 223.234 76.3458 223.136 75.3744 223.243C74.403 223.35 73.4647 223.659 72.62 224.15L53.73 235.15V235.21L0.0400391 266.35V270.7L5.18005 279.55L66.62 243.91V243.85L72.9401 240.18L81.62 259.28L0.0700684 306.59V322.77L126.29 249.5L119.21 237.43Z" />
          <path d="M261.238 114.626L102.264 206.854L109.289 218.964L268.263 126.736L261.238 114.626Z" />
          <path d="M137.69 241.82C141.937 241.82 145.38 238.377 145.38 234.13C145.38 229.883 141.937 226.44 137.69 226.44C133.443 226.44 130 229.883 130 234.13C130 238.377 133.443 241.82 137.69 241.82Z" />
        </g>
      </g>

      <defs>
        <linearGradient
          id={`linear${colorClassName(classroomName)}`}
          x1="0"
          y1="0"
          x2="351"
          y2="350"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0280711" className="gradient-color1" />
          <stop offset="1" className="gradient-color2" />
        </linearGradient>

        <clipPath id="clip0">
          <rect width="350" height="350" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
