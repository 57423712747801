import React from "react";
import { isSameMonth } from "date-fns";

export default function CalendarNavigationLabel(
  {
    date,
    label,
    onClickToday,
  },
): JSX.Element {
  if (isSameMonth(new Date(), new Date(date))) {
    return label;
  } else {
    return (
      <span>
        <span>{label}</span>
        <span className="today" onClick={onClickToday}>今日</span>
      </span>
    );
  }
}
