import React, { useState } from "react";
import FabButton from "components/FabButton";
import Notice from "components/ManagementClassroomSettings/Notice";
import TabHeader from "components/ManagementClassroomSettings/TabHeader";
import "./Courses.scss";

export default function Courses(
  {
    classroom,
  },
): JSX.Element {
  const [showHelpDialog, setShowHelpDialog] = useState(false);

  return (
    <section className="page__section">
      <div className="page__container">
        <TabHeader classroom={classroom} />

        <div className="page__section-head">
          <h2 className="page__title --level-2">
            コース
          </h2>

          <div className="page__action">
            <button
              className="button -appearance-transparent -color-interactive -size-s"
              onClick={() => setShowHelpDialog(true)}
            >
              <div className="_leading">
                <span className="icon _icon" data-icon="question_on_circle">
                </span>
              </div>

              <div className="_body">
                コースとは
              </div>
            </button>
          </div>
        </div>

        {classroom.eventGroups.length > 0
          ? (
            <div className="page__item --no-side-margin">
              <div className="course-list">
                <ul className="_list">
                  {classroom.eventGroups.map((eventGroup) => (
                    <li className="_item">
                      <a
                        href={eventGroup.managementClassroomCoursePath}
                      >
                        <div className="_body">
                          <div className="_title">
                            {eventGroup.name}
                          </div>
                        </div>

                        <div className="_trailing">
                          <div className="_icon">
                            <span className="icon" data-icon="chevron_right">
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )
          : (
            <div>
              <Notice>
                <p>
                  「コース」を作成し受講者と紐づけることが可能です。「コース」を設定しておくと、お知らせや予定、請求の作成時にコースをもとに受講者を選ぶことができるので便利です。

                  <br />

                  <a
                    target="_blank"
                    href="https://help.renshu.com/hc/ja/articles/4408096781715-5-%E5%8F%97%E8%AC%9B%E8%80%85%E3%82%92%E3%82%B3%E3%83%BC%E3%82%B9%E3%81%AB%E7%B4%90%E3%81%A5%E3%81%91%E3%82%8B"
                  >
                    5. 受講者をコースに紐づける
                  </a>
                </p>
              </Notice>

              <div className="no-courses-notice">
                <p>コースはまだありません</p>

                <span className="icon _icon" data-icon="people" />

                <p>
                  受講者を各コースにわけることで<br />管理しやすくなります
                </p>
              </div>
            </div>
          )}
      </div>

      <div
        className={showHelpDialog
          ? "dialog help-dialog --visible"
          : "dialog help-dialog"}
        role="dialog"
        aria-labelledby="help-dialog-title"
      >
        <div className="_shadow" onClick={() => setShowHelpDialog(false)}>
        </div>

        <div className="_wrapper">
          <div className="_container">
            <div className="_header">
              <div className="_title">
                コースとは
              </div>

              <div className="_switch">
                <button
                  className="button -appearance-transparent -color-neutral -shape-circle"
                  onClick={() => setShowHelpDialog(false)}
                  aria-label="閉じる"
                >
                  <div className="_leading">
                    <span className="icon _icon" data-icon="cross"></span>
                  </div>
                </button>
              </div>
            </div>

            <div className="_body">
              <div className="help-modal">
                <div className="help-modal__text">
                  『<strong>
                    受講者の区分
                  </strong>』です。『コース』『チーム』『クラス』など受講者のグループ分けができ、受講者1人で複数コースに所属することもできます。
                </div>

                <div className="help-modal__item">
                  <img
                    src={classroom.courseHintsImageUrl}
                    alt="教室の中にAコースBコースCコースという区分があり、各コースに受講者がそれぞれ存在していることを表す図"
                  />
                </div>

                <div className="help-modal__note">
                  例）サッカー教室『キッズコース』『低学年コース』<br />
                  ダンススタジオ『オープンクラス』『特別クラス』
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {classroom.policyCanNewManagementClassroomsCourses && (
        <FabButton
          label="コースを作成"
          onClick={() =>
            window.location.href = classroom.newManagementClassroomCoursePath}
        />
      )}
    </section>
  );
}
