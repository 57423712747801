import React from "react";
import { Family } from "components/ManagementMemberPage/MemberDetails";

interface Props {
  families: Family[];
}

export default function MemberFamilies(props: Props): JSX.Element {
  return (
    props.families.length >= 2 && (
      <dl>
        <dt>
          家族等<br />
          <span className="_description">
            同一アカウントに紐づく受講者
          </span>
        </dt>
        <dd>
          <ul>
            {props.families.map((family, index) => (
              <li key={index}>
                <a href={`./members#students/${family.id}/`}>
                  {family.name}
                </a>
              </li>
            ))}
          </ul>
        </dd>
      </dl>
    )
  );
}
