import React from "react";
import Notice from "components/ManagementClassroomSettings/Notice";

export default function EventListEmpty(
  {
    is_management,
  },
): JSX.Element {
  if (is_management) {
    return (
      <React.Fragment>
        <div className="event-list__body">
          <div className="event-list__item">
            <Notice>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://help.renshu.com/hc/ja/articles/12065222900755-スケジュール機能で予約を受け付けることはできますか"
                >
                  スケジュールとは
                </a>
              </p>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://help.renshu.com/hc/ja/articles/12065222900755-スケジュール機能で予約を受け付けるには"
                >
                  スケジュール機能で予約を受け付けるには
                </a>
              </p>
            </Notice>
          </div>
        </div>
        <div className="empty-state">
          <div className="_container">
            <div className="_icon">
              <span className="icon" data-icon="calendar"></span>
            </div>
            <div className="_title">
              予定を作成して
              <br />
              受講者に共有しましょう
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
}
