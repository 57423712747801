import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { numberFormat } from "utils/number-format";
import {
  deadlineAdditionalMonth,
  deadlineMonthlyDay,
} from "utils/recurrence-bill-deadline-convertor";

interface RecurrenceBill {
  id: number;
  name: string;
  amount: number;
  by_month_day: number;
  deadline_additional_month: number;
  deadline_month_day: number;
  recurred: boolean;
}

interface Props {
  onCloseButtonClick: () => void;
  getRecurrenceBills: (url: string) => Promise<{
    form_action: string;
    recurrence_bills: unknown[];
  }>;
  onUpdateRecurrenceBills: (
    url: string,
    data: unknown,
  ) => Promise<void>;
  recurrenceBillsPath: string;
  newRecurrenceBillPath: string;
}

interface Inputs {
  recurrence_bills: {
    recurrence_bill_ids: string[];
  };
}

export default function MemberRecurrenceBillsUpdatingModal({
  onCloseButtonClick,
  getRecurrenceBills,
  onUpdateRecurrenceBills,
  recurrenceBillsPath,
  newRecurrenceBillPath,
}: Props) {
  const [formAction, setFormAction] = useState("");
  const [recurrenceBills, setRecurrenceBills] = useState<
    RecurrenceBill[] | undefined
  >(
    undefined,
  );
  const handleButtonClick = () => {
    window.location.href = newRecurrenceBillPath;
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isSubmitted },
  } = useForm<Inputs>({
    defaultValues: {
      recurrence_bills: {
        recurrence_bill_ids: (recurrenceBills || [])
          .filter((recurrenceBill) => recurrenceBill.recurred)
          .map((recurrenceBill) => recurrenceBill.id.toString()),
      },
    },
  });
  const onSubmit = async (data: Inputs) => {
    if (!formAction) {
      return;
    }
    await onUpdateRecurrenceBills(formAction, data);
    onCloseButtonClick();
  };

  useEffect(() => {
    (async () => {
      const { recurrence_bills, form_action } = await getRecurrenceBills(
        recurrenceBillsPath,
      );
      setValue(
        "recurrence_bills.recurrence_bill_ids",
        (recurrence_bills || []).filter((recurrenceBill: RecurrenceBill) =>
          recurrenceBill.recurred
        )
          .map((recurrenceBill: RecurrenceBill) =>
            recurrenceBill.id.toString()
          ),
      );
      setFormAction(form_action);
      setRecurrenceBills(recurrence_bills as RecurrenceBill[]);
    })();
  }, []);

  return (
    <div className="modal-list">
      <div
        id="course-select-modal"
        className="_container modal-list__modal --visible"
        aria-labelledby="modal-title"
        aria-modal="true"
      >
        <div className="modal-header">
          <div className="_container">
            <div id="modal-title" className="_title">
              <span>定期請求を選択</span>
            </div>
            <div className="_nav">
              <button
                type="button"
                className="button -appearance-transparent -color-neutral -shape-circle"
                aria-label="閉じる"
                onClick={onCloseButtonClick}
              >
                <div className="_leading">
                  <span className="icon _icon" data-icon="cross" />
                </div>
              </button>
            </div>
            {recurrenceBills?.length > 0 && (
              <div className="_action">
                <button
                  type="button"
                  onClick={() => window.location.href = newRecurrenceBillPath}
                  className="button -appearance-transparent -size-s -color-interactive"
                  aria-label="定期請求作成ページへのリンク"
                >
                  <div className="_leading">
                    <span className="icon" data-icon="plus"></span>
                  </div>
                  <div className="_body">
                    作成
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="modal-content__container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form">
              {recurrenceBills?.length > 0 && (
                <>
                  <div className="form__item">
                    <ul className="_list">
                      {recurrenceBills.map((recurrenceBill) => (
                        <li className="_item" key={recurrenceBill.id}>
                          <label
                            htmlFor={`recurrence-bill-${recurrenceBill.id}`}
                          >
                            <div className="_leading">
                              <div className="_input">
                                <input
                                  id={`recurrence-bill-${recurrenceBill.id}`}
                                  className="checkbox"
                                  type="checkbox"
                                  {...register(
                                    "recurrence_bills.recurrence_bill_ids",
                                  )}
                                  value={recurrenceBill.id}
                                />
                              </div>
                            </div>
                            <div className="_body">
                              <div className="_title">
                                {recurrenceBill.name}
                              </div>
                              <div className="_description">
                                {`${
                                  numberFormat(recurrenceBill.amount)
                                }円 毎月${recurrenceBill.by_month_day}日 ${
                                  deadlineAdditionalMonth(
                                    recurrenceBill.deadline_additional_month,
                                  )
                                }${
                                  deadlineMonthlyDay(
                                    recurrenceBill.deadline_month_day,
                                  )
                                }日まで`}
                              </div>
                            </div>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="form__bottom-action">
                    <div className="form__action">
                      <ul className="button-group -column-2">
                        <li className="_item">
                          <button
                            name="button"
                            onClick={onCloseButtonClick}
                            className="button -size-l -appearance-filled -width-full"
                          >
                            <div className="_body">
                              キャンセル
                            </div>
                          </button>
                        </li>
                        <li className="_item">
                          <button
                            name="button"
                            type="submit"
                            className="button -size-l -appearance-filled -width-full -color-interactive"
                            disabled={isSubmitting || isSubmitted}
                          >
                            <div className="_body">
                              保存
                            </div>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
              {recurrenceBills?.length === 0 && (
                <div className="form__section">
                  <div className="form__item">
                    <p className="form__text">
                      定期請求がまだ作成されていません。<br />
                      定期請求の作成後、受講者と紐付けることができます。
                    </p>
                  </div>
                  <div className="form__bottom-action">
                    <div className="form__action">
                      <ul className="button-group--scalable -column-2--wide">
                        <li className="_item">
                          <button
                            name="button"
                            onClick={onCloseButtonClick}
                            className="button -size-l -appearance-filled -width-full -color-neutral"
                            type="button"
                          >
                            <div className="_body">
                              キャンセル
                            </div>
                          </button>
                        </li>
                        <li className="_item">
                          <button
                            type="button"
                            onClick={() =>
                              window.location.href = newRecurrenceBillPath}
                            className="button -size-l -appearance-filled -width-full -color-interactive"
                            aria-label="定期請求作成ページへのリンク"
                          >
                            <div className="_body">
                              定期請求の作成
                            </div>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {!recurrenceBills && (
                <div className="--loading">
                  <div className="in-skeleton-interactive-list">
                    <ul className="_list">
                      <li className="_item">
                        <div>
                          <div className="_leading">
                            <div className="_input">
                              <div className="in-skeleton-checkbox"></div>
                            </div>
                          </div>
                          <div className="_body">
                            <div className="_title">
                              <span className="_skeleton"></span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="_item">
                        <div>
                          <div className="_leading">
                            <div className="_input">
                              <div className="in-skeleton-checkbox"></div>
                            </div>
                          </div>
                          <div className="_body">
                            <div className="_title">
                              <span className="_skeleton"></span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="_item">
                        <div>
                          <div className="_leading">
                            <div className="_input">
                              <div className="in-skeleton-checkbox"></div>
                            </div>
                          </div>
                          <div className="_body">
                            <div className="_title">
                              <span className="_skeleton"></span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
