import React from "react";

export default function FabButton(
  {
    label,
    icon = "plus",
    onClick = () => {},
  },
): JSX.Element {
  return (
    <div className="fab-button">
      <div className="fab-button__wrapper">
        <div className="fab-button__container">
          <a
            className="fab-button__action"
            onClick={onClick}
            aria-label={label}
          >
            <div className="_leading">
              <span className="icon" data-icon={icon} />
            </div>

            <div className="_body">
              {label}
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
