import React from "react";
import Config from "components/ManagementClassroomSettings/Config";
import Courses from "components/ManagementClassroomSettings/Courses";
import Summary from "components/ManagementClassroomSettings/Summary";

export default function Page(props): JSX.Element {
  switch (props.activeTab) {
    case "summary":
      return <Summary {...props} />;
    case "courses":
      return <Courses {...props} />;
    case "config":
      return <Config {...props} />;
  }
}
