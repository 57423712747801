import React, { useEffect, useMemo, useRef, useState } from "react";
import MemberFloatingView from "./MemberFloatingView";
import classNames from "classnames";
import compareAsc from "date-fns/compareAsc";
import compareDesc from "date-fns/compareDesc";

interface Student {
  id: number;
  name: string;
  name_kana: string;
  gender: string | null;
  date_of_birth: string | null;
  dependent: boolean;
  address: {
    postal_code: string;
    address: string;
  } | null;
  users: {
    name: string;
    phone_number: string | null;
  }[];
}

interface EventGroup {
  id: number;
  name: string;
  slug: string;
  enrolled_at: string;
}

interface RecurrenceBill {
  id: number;
  name: string;
  amount: number;
  by_month_day: number;
  deadline_additional_month: number;
  deadline_month_day: number;
  path: string;
}

export interface Enrollment {
  id: number;
  student: Student;
  created_at: string;
  description: string | null;
  enrolled_event_groups: EventGroup[];
  recurrence_bills: RecurrenceBill[];
  delete_url: string;
  details_url: string;
  bills_url: string;
  new_bill_url: string;
  announcements_url: string;
  new_announcement_url: string;
  events_url: string;
  new_event_url: string;
  edit_url: string;
  bulk_enrollment_event_groups_url: string;
  recurrence_bills_path: string;
  custom_fields_url: string;
}

export interface Props {
  subscribed: boolean;
  enrollments: Enrollment[];
  classroom_managers_count: number;
  management_classroom_classroom_managers_path: string;
  management_classroom_custom_fields_path: string;
  management_classroom_invite_path: string;
  new_course_path: string;
  new_recurrence_bill_path: string;
  members_csv_download_path: string;
  members_csv_access: boolean;
  members_destroy_access: boolean;
  recurrence_bills_show_access: boolean;
  students_bills_index_access: boolean;
  students_bulk_enrollment_event_groups_index_access: boolean;
}

interface P extends Props {
  enrollmentId?: number;
  getEnrolledEventGroups: (url: string) => Promise<{
    form_action: string;
    event_groups: unknown[];
  }>;
  getRecurrenceBills: (url: string) => Promise<{
    form_action: string;
    recurrence_bills: unknown[];
  }>;
  getBills: (url: string) => Promise<{ bills: unknown[] }>;
  getAnnouncements: (url: string) => Promise<{ announcements: unknown[] }>;
  getEvents: (url: string) => Promise<{ events: unknown[] }>;
  handleUpdateEnrolledEventGroups: (
    enrollmentId: number,
    url: string,
    data: unknown,
  ) => Promise<void>;
  handleUpdateRecurredBills: (
    enrollmentId: number,
    url: string,
    data: unknown,
  ) => Promise<void>;
  handleUpdateEnrollment: (
    enrollmentId: number,
    data: { enrollment: { description: string } },
  ) => Promise<void>;
  onFloatingViewShow?: (id: string) => void;
  onFloatingViewClose?: () => void;
}

type Filter = "name" | "eventGroup" | "recurrenceBill" | "description";

type Sort =
  | "newer"
  | "older"
  | "nameKana"
  | "eventGroup"
  | "eventGroupCountDesc"
  | "eventGroupCountAsc";

const sorters: Record<Sort, (a: Enrollment, b: Enrollment) => number> = {
  newer: (a, b) => compareDesc(new Date(a.created_at), new Date(b.created_at)),
  older: (a, b) => compareAsc(new Date(a.created_at), new Date(b.created_at)),
  nameKana: (a, b) =>
    a.student.name_kana.localeCompare(b.student.name_kana, "ja"),
  eventGroup: (a, b) => {
    if (a.enrolled_event_groups.length === 0) {
      return 1;
    }
    if (b.enrolled_event_groups.length === 0) {
      return -1;
    }
    return a.enrolled_event_groups[0].name.localeCompare(
      b.enrolled_event_groups[0].name,
      "ja",
    );
  },
  eventGroupCountDesc: (a, b) =>
    b.enrolled_event_groups.length - a.enrolled_event_groups.length,
  eventGroupCountAsc: (a, b) =>
    a.enrolled_event_groups.length - b.enrolled_event_groups.length,
};

export default function ManagementMemberPageContainer(props: P) {
  const [enrollmentId, setEnrollmentId] = useState<number>(null);
  const [visibleFilter, setVisibleFilter] = useState<Filter>(null);
  const [sort, setSort] = useState<Sort>("newer");
  const [searchName, setSearchName] = useState("");
  const [searchEventGroupIds, setSearchEventGroupIds] = useState<number[]>([]);
  const [searchRecurrenceBillIds, setSearchRecurrenceBillIds] = useState<
    number[]
  >([]);
  const [searchRecurrenceBillUnset, setSearchRecurrenceBillUnset] = useState(
    false,
  );
  const [searchDescriptionBlank, setSearchDescriptionBlank] = useState(false);
  const [searchDescriptionFilled, setSearchDescriptionFilled] = useState(false);
  const [searchDescription, setSearchDescription] = useState("");

  const [openDropdown, setOpenDropdown] = useState(false);
  const handleBlur = (event) => {
    const isButtonFocused = event.relatedTarget?.tagName === "A";
    if (!isButtonFocused) {
      setOpenDropdown(false);
    }
  };

  const ref = useRef<HTMLButtonElement>();

  const eventGroups = useMemo(() => {
    return props.enrollments.reduce<EventGroup[]>(
      (previousEventGroups, enrollment) => {
        const eventGroupsToAdd = enrollment.enrolled_event_groups.filter((
          eventGroup,
        ) =>
          !previousEventGroups.some((aEventGroup) =>
            aEventGroup.id === eventGroup.id
          )
        );
        return previousEventGroups.concat(eventGroupsToAdd);
      },
      [],
    );
  }, [props.enrollments]);

  const recurrenceBills = useMemo(() => {
    return props.enrollments.reduce<RecurrenceBill[]>(
      (previousRecurrenceBills, enrollment) => {
        const recurrenceBillsToAdd = enrollment.recurrence_bills.filter((
          recurrenceBill,
        ) =>
          !previousRecurrenceBills.some((aRecurrenceBill) =>
            aRecurrenceBill.id === recurrenceBill.id
          )
        );
        return previousRecurrenceBills.concat(recurrenceBillsToAdd);
      },
      [],
    );
  }, [props.enrollments]);

  const filteredData = props.enrollments.filter((enrollment) => {
    if (searchName.length > 0) {
      if (
        !enrollment.student.name.toLowerCase().includes(
          searchName.toLowerCase(),
        )
      ) {
        return false;
      }
    }

    if (searchEventGroupIds.length > 0) {
      if (
        !enrollment.enrolled_event_groups.some((eventGroup) =>
          searchEventGroupIds.includes(eventGroup.id)
        )
      ) return false;
    }

    if (searchRecurrenceBillIds.length > 0) {
      if (
        !enrollment.recurrence_bills.some((recurrenceBill) =>
          searchRecurrenceBillIds.includes(recurrenceBill.id)
        )
      ) return false;
    }

    if (searchRecurrenceBillUnset) {
      if (enrollment.recurrence_bills.length > 0) {
        return false;
      }
    }

    if (searchDescriptionBlank) {
      if (
        enrollment.description !== null && enrollment.description.length > 0
      ) return false;
    }

    if (searchDescriptionFilled) {
      if (enrollment.description === null) return false;
      if (enrollment.description.length === 0) return false;
    }

    if (searchDescription.length > 0) {
      if (enrollment.description === null) return false;
      if (
        !enrollment.description.toLowerCase().includes(
          searchDescription.toLowerCase(),
        )
      ) return false;
    }

    return true;
  }).sort(sorters[sort]).map<[Enrollment, JSX.Element, () => void]>(
    (enrollment) => {
      const buttonRef = React.createRef<HTMLButtonElement>();
      const handleEnrollmentClick = () => {
        setEnrollmentId(enrollment.id);
        ref.current = buttonRef.current;
        if (props.onFloatingViewShow) {
          props.onFloatingViewShow(enrollment.id.toString());
        }
      };
      const element = (
        <tr
          className={enrollmentId === enrollment.id ? "--selected" : ""}
          key={enrollment.id}
        >
          <td className="_student-name">
            {enrollment.student.name}
            <button
              className="_clickable"
              onClick={handleEnrollmentClick}
              aria-label={enrollment.description
                ? `${enrollment.student.name}|${enrollment.description}`
                : enrollment.student.name}
              ref={buttonRef}
            />
          </td>
          <td className="_student-course">
            {enrollment.enrolled_event_groups.map((event_group) => (
              <p key={event_group.id}>
                {event_group.name}
              </p>
            ))}
            <button
              className="_clickable"
              onClick={handleEnrollmentClick}
              tabIndex={-1}
            />
          </td>
          <td className="_student-recurrence-bill">
            {enrollment.recurrence_bills.map((recurrence_bill) => (
              <p key={recurrence_bill.id}>
                {recurrence_bill.name}
              </p>
            ))}
            <button
              className="_clickable"
              onClick={handleEnrollmentClick}
              tabIndex={-1}
            />
          </td>
          <td className="_student-description">
            {enrollment.description}
            <button
              className="_clickable"
              onClick={handleEnrollmentClick}
              tabIndex={-1}
            />
          </td>
        </tr>
      );
      return [enrollment, element, handleEnrollmentClick];
    },
  );

  useEffect(() => {
    if (props.enrollmentId) {
      const data = filteredData.find(([enrollment]) =>
        enrollment.id === props.enrollmentId
      );
      if (data) {
        data[2]();
      }
    }
  }, [props.enrollmentId]);

  const closeFloatingView = () => {
    ref.current?.focus();
    setEnrollmentId(null);
    if (props.onFloatingViewClose) {
      props.onFloatingViewClose();
    }
  };

  return (
    <div
      className={classNames("admin-table", {
        "js-overwrap": enrollmentId !== null,
      })}
    >
      <div className="admin-table__collection">
        <header className="header__member-control">
          <div className="header__member-control__leading">
            <a
              className="member-role__link --hover"
              href=""
              aria-current="true"
            >
              <div className="_body">
                受講者：{props.enrollments.length}人
              </div>
            </a>
            <a
              className="member-role__link"
              href={props.management_classroom_classroom_managers_path}
              aria-current="false"
            >
              <div className="_body">
                運営者：{props.classroom_managers_count}人
              </div>
            </a>
          </div>
          <div className="header__member-control__trailing">
            <div className="-sort">
              <span className="-sort__select">
                <select
                  className="_select"
                  value={sort}
                  onChange={(event) => {
                    setSort(event.target.value as Sort);
                  }}
                >
                  <option className="_option" value="newer">
                    登録が新しい順
                  </option>
                  <option className="_option" value="older">
                    登録が古い順
                  </option>
                  <option className="_option" value="nameKana">
                    ふりがな順
                  </option>
                  <option className="_option" value="eventGroup">
                    所属の名称順
                  </option>
                  <option className="_option" value="eventGroupCountDesc">
                    所属が多い順
                  </option>
                  <option className="_option" value="eventGroupCountAsc">
                    所属が少ない順
                  </option>
                </select>
                <span className="_icon" />
              </span>
            </div>
            <div className="-invite">
              <a
                className="-invite__button"
                href={props.management_classroom_invite_path}
              >
                <div className="_leading">
                  <span className="icon _icon" data-icon="person_plus" />
                </div>
                <div className="_body">
                  招待
                </div>
              </a>
            </div>
            <div className="dropdown --position-right">
              <button
                onClick={() => setOpenDropdown(!openDropdown)}
                onBlur={handleBlur}
                className="dropdown__control button -appearance-transparent -shape-circle -size-m"
                aria-label="操作"
                aria-controls="dropdown-item"
                aria-expanded={openDropdown ? "true" : "false"}
              >
                <div className="_leading">
                  <span className="_icon icon" data-icon="ellipsis_vertical">
                  </span>
                </div>
              </button>
              {openDropdown && (
                <ul
                  id="dropdown-item"
                  className="_list dropdown__list"
                >
                  <li className="_item dropdown__item">
                    <a
                      href={props.management_classroom_custom_fields_path}
                    >
                      <div className="_body">
                        <div className="_title">
                          受講者情報のテンプレート
                        </div>
                      </div>
                    </a>
                  </li>

                  {props.members_csv_access
                    ? (
                      <li className="_item dropdown__item">
                        <a
                          href={props.members_csv_download_path}
                        >
                          <div className="_body">
                            <div className="_title">
                              CSVダウンロード
                            </div>
                          </div>
                        </a>
                      </li>
                    )
                    : null}
                </ul>
              )}
            </div>
          </div>
        </header>
        <table className="admin-table__collection-table">
          <thead>
            <tr>
              <th role="columnheader" scope="col">
                <div className="_wrapper">
                  <div className="_container">氏名</div>
                  <div className="_container">
                    <div className="_control">
                      <button
                        onClick={() => {
                          setVisibleFilter((prev) => {
                            return prev === "name" ? null : "name";
                          });
                        }}
                        aria-label="絞り込み"
                        className="button__filter-open"
                      >
                        <div className="_trailing">
                          <span className="icon _icon" data-icon="funnel" />
                        </div>
                      </button>
                      {visibleFilter === "name" && (
                        <div className="popup">
                          <header className="popup-header">
                            <p>氏名での絞り込み</p>
                            <button
                              onClick={() => setVisibleFilter(null)}
                              aria-label="閉じる"
                              className="button__filter-close"
                            >
                              <div className="_trailing">
                                <span
                                  className="icon _icon"
                                  data-icon="cross"
                                />
                              </div>
                            </button>
                          </header>
                          <div className="_body">
                            <div className="_search -icon-before">
                              <input
                                className="_input"
                                type="search"
                                value={searchName}
                                onChange={(event) =>
                                  setSearchName(event.target.value)}
                              />
                              <span
                                className="_icon icon"
                                data-icon="magnifying_glass"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </th>
              <th role="columnheader" scope="col">
                <div className="_wrapper">
                  <div className="_container">所属</div>
                  <div className="_container">
                    <div className="_control">
                      <button
                        onClick={() => {
                          setVisibleFilter((prev) => {
                            return prev === "eventGroup" ? null : "eventGroup";
                          });
                        }}
                        aria-label="絞り込み"
                        className="button__filter-open"
                      >
                        <div className="_trailing">
                          <span className="icon _icon" data-icon="funnel" />
                        </div>
                      </button>
                      {visibleFilter === "eventGroup" && (
                        <div className="popup">
                          <header className="popup-header">
                            <p>所属での絞り込み</p>
                            <button
                              onClick={() => setVisibleFilter(null)}
                              aria-label="閉じる"
                              className="button__filter-close"
                            >
                              <div className="_trailing">
                                <span
                                  className="icon _icon"
                                  data-icon="cross"
                                />
                              </div>
                            </button>
                          </header>
                          <ul className="_body _checkbox-list">
                            {eventGroups.map((eventGroup) => (
                              <li
                                className="_checkbox-item"
                                key={eventGroup.id}
                              >
                                <label
                                  htmlFor={`recurrenceBillFilter-${eventGroup.id}`}
                                >
                                  <div className="_input">
                                    <input
                                      id={`recurrenceBillFilter-${eventGroup.id}`}
                                      className="_checkbox"
                                      type="checkbox"
                                      checked={searchEventGroupIds.includes(
                                        eventGroup.id,
                                      )}
                                      onChange={(event) => {
                                        setSearchEventGroupIds((prev) => {
                                          if (event.target.checked) {
                                            return [...prev, eventGroup.id];
                                          } else {
                                            return prev.filter((id) =>
                                              id !== eventGroup.id
                                            );
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                  <p className="_title">{eventGroup.name}</p>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </th>
              <th role="columnheader" scope="col">
                <div className="_wrapper">
                  <div className="_container">定期請求</div>
                  <div className="_container">
                    <div className="_control">
                      <button
                        onClick={() => {
                          setVisibleFilter((prev) => {
                            return prev === "recurrenceBill"
                              ? null
                              : "recurrenceBill";
                          });
                        }}
                        aria-label="絞り込み"
                        className="button__filter-open"
                      >
                        <div className="_trailing">
                          <span className="icon _icon" data-icon="funnel" />
                        </div>
                      </button>
                      {visibleFilter === "recurrenceBill" && (
                        <div className="popup">
                          <header className="popup-header">
                            <p>定期請求での絞り込み</p>
                            <button
                              onClick={() => setVisibleFilter(null)}
                              aria-label="閉じる"
                              className="button__filter-close"
                            >
                              <div className="_trailing">
                                <span
                                  className="icon _icon"
                                  data-icon="cross"
                                />
                              </div>
                            </button>
                          </header>
                          <div className="_checkbox-item">
                            <label htmlFor="recurrenceBillFilterUnset">
                              <div className="_input">
                                <input
                                  id="recurrenceBillFilterUnset"
                                  className="_checkbox"
                                  type="checkbox"
                                  checked={searchRecurrenceBillUnset}
                                  onChange={(event) => {
                                    setSearchRecurrenceBillUnset(
                                      event.target.checked,
                                    );
                                  }}
                                />
                              </div>
                              <p className="_title">未設定</p>
                            </label>
                          </div>
                          <ul className="_body _checkbox-list">
                            {recurrenceBills.map((recurrenceBill) => (
                              <li
                                className="_checkbox-item"
                                key={recurrenceBill.id}
                              >
                                <label
                                  htmlFor={`recurrenceBillFilter-${recurrenceBill.id}`}
                                >
                                  <div className="_input">
                                    <input
                                      id={`recurrenceBillFilter-${recurrenceBill.id}`}
                                      className="_checkbox"
                                      type="checkbox"
                                      checked={searchRecurrenceBillIds.includes(
                                        recurrenceBill.id,
                                      )}
                                      onChange={(event) => {
                                        setSearchRecurrenceBillIds((prev) => {
                                          if (event.target.checked) {
                                            return [...prev, recurrenceBill.id];
                                          } else {
                                            return prev.filter((id) =>
                                              id !== recurrenceBill.id
                                            );
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                  <p className="_title">
                                    {recurrenceBill.name}
                                  </p>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </th>

              <th role="columnheader" scope="col">
                <div className="_wrapper">
                  <div className="_container">内部メモ</div>
                  <div className="_container">
                    <div className="_control">
                      <button
                        onClick={() => {
                          setVisibleFilter((prev) => {
                            return prev === "description"
                              ? null
                              : "description";
                          });
                        }}
                        aria-label="絞り込み"
                        className="button__filter-open"
                      >
                        <div className="_trailing">
                          <span className="icon _icon" data-icon="funnel" />
                        </div>
                      </button>
                      {visibleFilter === "description" && (
                        <div className="popup _description">
                          <header className="popup-header">
                            <p>内部メモでの絞り込み</p>
                            <button
                              onClick={() => setVisibleFilter(null)}
                              aria-label="閉じる"
                              className="button__filter-close"
                            >
                              <div className="_trailing">
                                <span
                                  className="icon _icon"
                                  data-icon="cross"
                                />
                              </div>
                            </button>
                          </header>
                          <div className="_body _checkbox-list">
                            <div className="_checkbox-item">
                              <label htmlFor="descriptionFilterBlank">
                                <div className="_input">
                                  <input
                                    id="descriptionFilterBlank"
                                    className="_checkbox"
                                    type="checkbox"
                                    checked={searchDescriptionBlank}
                                    onChange={(event) =>
                                      setSearchDescriptionBlank(
                                        event.target.checked,
                                      )}
                                  />
                                </div>
                                <p className="_title">未記入</p>
                              </label>
                              <label htmlFor="descriptionFilterFilled">
                                <div className="_input">
                                  <input
                                    id="descriptionFilterFilled"
                                    className="_checkbox"
                                    type="checkbox"
                                    checked={searchDescriptionFilled}
                                    onChange={(event) =>
                                      setSearchDescriptionFilled(
                                        event.target.checked,
                                      )}
                                  />
                                </div>
                                <p className="_title">記入済み</p>
                              </label>
                            </div>
                            <div className="_search -icon-before ">
                              <input
                                className="_input"
                                type="search"
                                value={searchDescription}
                                onChange={(event) =>
                                  setSearchDescription(event.target.value)}
                              />
                              <span
                                className="_icon icon"
                                data-icon="magnifying_glass"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map(([_, element]) => element)}
          </tbody>
        </table>
      </div>
      <MemberFloatingView
        key={enrollmentId}
        onCloseFloatingView={closeFloatingView}
        getEnrolledEventGroups={props.getEnrolledEventGroups}
        getRecurrenceBills={props.getRecurrenceBills}
        getBills={props.getBills}
        getAnnouncements={props.getAnnouncements}
        getEvents={props.getEvents}
        handleUpdateEnrolledEventGroups={props.handleUpdateEnrolledEventGroups}
        handleUpdateRecurredBills={props.handleUpdateRecurredBills}
        handleUpdateEnrollment={props.handleUpdateEnrollment}
        subscribed={props.subscribed}
        newCoursePath={props.new_course_path}
        newRecurrenceBillPath={props.new_recurrence_bill_path}
        membersDestroyAccess={props.members_destroy_access}
        recurrenceBillsShowAccess={props.recurrence_bills_show_access}
        studentsBillsIndexAccess={props.students_bills_index_access}
        studentsBulkEnrollmentEventGroupsIndexAccess={props
          .students_bulk_enrollment_event_groups_index_access}
        enrollment={enrollmentId
          ? props.enrollments.find((enrollment) =>
            enrollment.id === enrollmentId
          )
          : null}
      />
    </div>
  );
}
