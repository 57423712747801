import { useMemo } from "react";
import crypto from "crypto";

export const colorClassName = (name: string) => {
  return useMemo(() => {
    const hash = crypto.createHash("md5");

    hash.update(name ? name[0] : "r", "utf8");

    const num = BigInt("0x" + hash.digest("hex"));
    const variant = num % BigInt(10);

    return `-color-${variant}`;
  }, [name]);
};
