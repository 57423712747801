import React from "react";
import { isSameDay } from "date-fns";

export default function CalendarTileContent(
  {
    date,
    events,
  },
): JSX.Element {
  const tileEvents = () => {
    return events.filter((event) =>
      isSameDay(new Date(date), new Date(event.starts_at))
    );
  };

  return (
    <span style={{ width: "100%" }}>
      {tileEvents().slice(0, 2).map((event) => (
        <span key={event.id} className={"event-name"}>
          {event.publish_scope === "classroom" && (
            <span className="icon" data-icon="circle"></span>
          )}
          {event.name}
        </span>
      ))}
    </span>
  );
}
