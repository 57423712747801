import React from "react";
import EventItem from "components/Schedules/EventItem";
import Notice from "components/ManagementClassroomSettings/Notice";
import { format } from "date-fns";
import { ja } from "date-fns/locale";

export default function EventList(
  {
    activeEvents,
    card_links,
    classroom_icons,
    is_management,
    person_avatars,
    selectedDate,
  },
): JSX.Element {
  return (
    <div className="event-list">
      <section className="event-list__day">
        <header className="event-list__header">
          <h2 className="event-list__day-label">
            {format(selectedDate, "M月d日（E）", { locale: ja })}
          </h2>
        </header>

        <div className="event-list__body">
          {activeEvents.map((event, index) => (
            <EventItem
              key={event.id}
              card_links={card_links}
              classroom_icons={classroom_icons}
              event={event}
              is_management={is_management}
              person_avatars={person_avatars}
            />
          ))}
        </div>
      </section>

      <div className="event-list__body">
        {is_management
          ? (
            <div className="event-list__item">
              <Notice>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://help.renshu.com/hc/ja/articles/4415743705619-スケジュールとはなんですか"
                  >
                    スケジュールとは
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://help.renshu.com/hc/ja/articles/12065222900755-スケジュール機能で予約を受け付けるには"
                  >
                    スケジュール機能で予約を受け付けるには
                  </a>
                </p>
              </Notice>
            </div>
          )
          : null}
      </div>
    </div>
  );
}
