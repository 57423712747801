import React from "react";
import { isSameDay } from "date-fns";

export default function CalendarTileDay(
  {
    date,
    events,
  },
): JSX.Element {
  const tileEvents = () => {
    return events.filter((event) =>
      isSameDay(new Date(date), new Date(event.starts_at))
    );
  };

  return (
    <div className={"additional-events-container"}>
      <span>{date.toLocaleDateString("en", { day: "numeric" })}</span>

      {tileEvents().length > 2
        ? (
          <span className={"additional-events"}>
            ほか{tileEvents().length - 2}件
          </span>
        )
        : null}
    </div>
  );
}
