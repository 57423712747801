import React from "react";

export default function PersonStatus(
  {
    status,
  },
): JSX.Element {
  switch (status) {
    case "going":
      return (
        <span className="home-event-card__person-status-text -color-positive">
          <span className="icon" data-icon="check_on_circle"></span>
          出席
        </span>
      );
    case "not_going":
      return (
        <span className="home-event-card__person-status-text -color-negative">
          <span className="icon" data-icon="cross_on_circle"></span>
          欠席
        </span>
      );
    default:
      return (
        <span className="home-event-card__person-status-text -color-notice">
          <span className="icon" data-icon="ellipsis_horizontal_on_circle">
          </span>

          未定
        </span>
      );
  }
}
