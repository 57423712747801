import React from "react";
import {
  Classroom,
  ClassroomEnrollmentParams,
} from "../ClassroomEnrollmentPage";
import TextAvatar from "../TextAvatar";
import localizeGender from "../../utils/localize-gender";

interface Props {
  classroom: Classroom;
  classroomEnrollmentParams: ClassroomEnrollmentParams;
  onConfirmDialogClose: () => void;
  onSubmit: () => Promise<void>;
  loading: boolean;
}

export default function ClassroomEnrollmentConfirmDialog(
  props: Props,
): JSX.Element {
  return (
    <div className="enroll-event-group__confirm --visible">
      <div className="modal-header">
        <div className="_title">
          登録内容の確認
        </div>

        <div className="_container">
          <div className="_nav">
            <div
              role="button"
              className="button -appearance-transparent -color-neutral -shape-circle"
              aria-label="閉じる"
              onClick={props.onConfirmDialogClose}
            >
              <div className="_leading">
                <span className="icon _icon" data-icon="cross" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="enroll-event-group__confirm-content">
        <section className="enroll-event-group__course">
          <div className="container">
            <h2 className="enroll-event-group__course-title">
              教室
            </h2>
            <div className="enroll-event-group__course-body">
              <div className="container --revert">
                <div className="course-list">
                  <ul className="_list">
                    <li className="_item">
                      <div>
                        <div className="_leading">
                          {props.classroom.icon
                            ? (
                              <div className="avatar -size-m">
                                <img src={props.classroom.icon} />
                              </div>
                            )
                            : (
                              <TextAvatar
                                name={props.classroom.name}
                                size="m"
                              />
                            )}
                        </div>

                        <div className="_body">
                          <div className="_title">
                            {props.classroom.name}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="enroll-event-group__student">
          <div className="container">
            <h2 className="enroll-event-group__student-title">
              教室に入会する受講者
            </h2>

            <div className="enroll-event-group__student-body">
              <div className="enroll-event-group__student-confirm">
                <h3 className="enroll-event-group__student-confirm-title">
                  受講者情報
                </h3>
                <div className="enroll-event-group__student-confirm-body">
                  <dl className="enroll-event-group__student-confirm-body">
                    <dt>氏名</dt>
                    <dd>{props.classroomEnrollmentParams.student_name}</dd>
                    <dt>フリガナ</dt>
                    <dd>
                      {props.classroomEnrollmentParams.student_name_kana}
                    </dd>
                    <dt>生年月日</dt>
                    <dd>
                      {props.classroomEnrollmentParams.student_date_of_birth}
                    </dd>
                    {props.classroomEnrollmentParams.student_gender && (
                      <>
                        <dt>性別</dt>
                        <dd>
                          {localizeGender(
                            props.classroomEnrollmentParams.student_gender,
                          )}
                        </dd>
                      </>
                    )}
                    {props.classroomEnrollmentParams
                      .student_address_postal_code && (
                      <>
                        <dt>住所</dt>
                        <dd>
                          {props.classroomEnrollmentParams
                            .student_address_postal_code}
                          <br />
                          {props.classroomEnrollmentParams
                            .student_address_address}
                        </dd>
                      </>
                    )}
                    {props.classroomEnrollmentParams.custom_fields?.map((
                      customField,
                    ) => (
                      <>
                        {/* メモ：カスタム項目の「設問名」が表示されず */}
                        <dt>{customField.name}</dt>
                        <dd key={customField.custom_field_id}>
                          {customField.value}
                        </dd>
                      </>
                    ))}
                  </dl>
                </div>
              </div>

              <div className="enroll-event-group__student-confirm">
                <h3 className="enroll-event-group__student-confirm-title">
                  アカウント情報
                </h3>
                <dl className="enroll-event-group__student-confirm-body">
                  <dt>氏名</dt>
                  <dd>{props.classroomEnrollmentParams.user_name}</dd>
                  <dt>電話番号</dt>
                  <dd>
                    {props.classroomEnrollmentParams.user_phone_number}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </section>

        <div className="enroll-event-group__action">
          <div className="container">
            <ul className="button-group -column-2">
              <li className="_item">
                <div
                  role="button"
                  className="button -appearance-outlined -color-interactive -width-full"
                  onClick={props.onConfirmDialogClose}
                >
                  <div className="_body">
                    修正
                  </div>
                </div>
              </li>
              <li className="_item">
                <button
                  type="submit"
                  className="button -appearance-filled -width-full -color-interactive"
                  onClick={props.onSubmit}
                  disabled={props.loading}
                >
                  <div className="_body">
                    入会
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
