export default function localizeGender(gender: string) {
  switch (gender) {
    case "male":
      return "男性";
    case "female":
      return "女性";
    case "non_binary":
      return "別の性";
  }
}
