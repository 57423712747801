import classNames from "classnames";
import React, { useEffect, useState } from "react";

interface Props {
  children: React.ReactNode;
}

export default function Snackbar(props: Props): JSX.Element {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(true);
    const id = setTimeout(() => {
      setVisible(false);
    }, 7000);
    return () => clearTimeout(id);
  }, [props.children]);

  return (
    <div className={classNames("snackbar", { "--visible": visible })}>
      <div className="_container">
        <div className="_body">
          {props.children}
        </div>
        <div className="_trailing">
          <button
            className="button -appearance-transparent -color-neutral -shape-circle -size-s"
            aria-label="閉じる"
            onClick={() => setVisible(false)}
          >
            <div className="_leading">
              <span className="icon _icon" data-icon="cross" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
