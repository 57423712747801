import React, { useState } from "react";
import Dialog from "components/Dialog";

export default function ConfigDeleteClassroom(
  {
    classroom,
    formAuthenticityToken,
  },
): JSX.Element {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <section className="page__section">
      <div className="page__container">
        <div className="page__section-head">
          <h2 className="page__title --level-2">
            教室の削除
          </h2>
        </div>

        <div className="page__item">
          {classroom.destroyable
            ? (
              <div>
                <div className="page__data-body">
                  <p>
                    すでに請求が支払われた教室の削除は、お問い合わせをもとに行っております。お手数ですが、お問い合わせフォームより「作成した教室の削除を希望」の旨ご連絡ください。
                  </p>

                  <button
                    className="button -appearance-outlined -size-s -color-negative"
                    type="button"
                    style={{ padding: "0 3rem" }}
                    onClick={() => setShowDeleteDialog(true)}
                  >
                    <div>
                      教室の削除
                    </div>
                  </button>
                </div>

                {showDeleteDialog
                  ? (
                    <Dialog
                      title={`教室「${classroom.name}」を削除します。よろしいですか？`}
                      description="教室の全てのデータが削除されます。教室に受講者がいる場合、教室から自動で退会となるため同意のうえで操作をお願いします。削除されたデータの復旧はできかねますのでご了承ください。"
                      onCloseButtonClick={() => setShowDeleteDialog(false)}
                    >
                      <form
                        id="classroom-delete-form"
                        action={classroom.managementOrganizationPath}
                        method="post"
                      >
                        <input
                          type="hidden"
                          name="_method"
                          value="delete"
                          autoComplete="off"
                        />

                        <input
                          type="hidden"
                          name="authenticity_token"
                          value={formAuthenticityToken}
                          autoComplete="off"
                        />

                        <button
                          className="button -appearance-transparent -size-m -color-negative"
                          type="submit"
                        >
                          <div className="_body">
                            削除
                          </div>
                        </button>
                      </form>
                    </Dialog>
                  )
                  : null}
              </div>
            )
            : (
              <div>
                <div className="page__data-body">
                  <a
                    className="button -appearance-outlined -color-neutral -size-s -color-negative"
                    href="https://help.renshu.com/hc/ja/requests/new?ticket_form_id=1500002937061"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className="_body">
                      お問い合わせフォーム
                    </div>
                  </a>
                </div>

                <div className="page__data-note">
                  すでに請求が支払われた教室の削除は、お問い合わせをもとに行っております。お手数ですが、お問い合わせフォームより「作成した教室の削除を希望」の旨ご連絡ください。
                </div>
              </div>
            )}
        </div>
      </div>
    </section>
  );
}
