import React, { useEffect, useState } from "react";

interface Props {
  selectedStudentsCount: number;
}

export default function RemainingParticipantsCount(
  {
    selectedStudentsCount,
  }: Props,
) {
  const [attendeeLimit, setAttendeeLimit] = useState<number | null>(
    null,
  );

  const remainingParticipantsCount = attendeeLimit - selectedStudentsCount;

  // #TODO:このuseEffectは「予定の作成」ページreact化までの暫定対応です。
  // ページ全体をreact化される際はこちらの機能についてもreact化をお願いいたします。
  useEffect(() => {
    const attendeeLimitInput = document.getElementById("js-attendee-limit");
    if (
      !(attendeeLimitInput && attendeeLimitInput instanceof HTMLInputElement)
    ) {
      return;
    }
    attendeeLimitInput.addEventListener("input", (event) => {
      setAttendeeLimit(Math.max(parseInt(attendeeLimitInput.value, 10), 0));
    });
  }, []);

  const [defaultParticipationStatus, setDefaultParticipationStatus] = useState(
    "going",
  );

  useEffect(() => {
    const handleStatusChange = (event) => {
      setDefaultParticipationStatus(event.target.value);
    };

    const currentStatus = document.querySelectorAll(
      ".js-default-participation-status",
    );

    currentStatus.forEach((input) => {
      input.addEventListener("change", handleStatusChange);
    });

    return () => {
      currentStatus.forEach((input) => {
        input.removeEventListener("change", handleStatusChange);
      });
    };
  }, []);

  if (attendeeLimit === null || defaultParticipationStatus !== "going") {
    return null;
  }

  return (
    <div>
      {remainingParticipantsCount === 0 && (
        <span className="js-max-participants event__participation --seats_full">
          満席
        </span>
      )}
      {remainingParticipantsCount > 0 && (
        <span className="js-remaining-participants event__participation --seats_available">
          あと {remainingParticipantsCount}席
        </span>
      )}
      {remainingParticipantsCount < 0 && (
        <span className="js-over-participants form__item over_attendeelimit">
          定員オーバー
        </span>
      )}
    </div>
  );
}
