import React, { useState } from "react";
import AnnouncementFormAIAssistantSection from "./AIAssistantSection";

interface Props {
  form_action: string;
  title: string;
  body: string;
  onReflection: (field: string, value: string) => void;
}

export default function AnnouncementFormAIAssistant(
  { form_action, onReflection, title, body }: Props,
) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <button
        type="button"
        className="button -appearance-outlined -size-s -color-interactive"
        tabIndex={0}
        onClick={() => setOpenModal(true)}
      >
        <div className="_leading">
          <div className="ai-assistant__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 96 960 960"
              width="48"
            >
              <path d="m384 679 50.079-108.921L543 520l-108.921-50.079L384 361l-50.079 108.921L225 520l108.921 50.079L384 679Zm0 145-95-209-209-95 209-95 95-209 95 209 209 95-209 95-95 209Zm344 112-47-105-105-47 105-48 47-104 48 104 104 48-104 47-48 105ZM384 520Z" />
            </svg>
          </div>
        </div>
        <div className="_body">
          AIに書いてもらう
        </div>
      </button>

      {openModal && (
        <>
          <div
            className="dialog-v2 ai-assistant --visible"
            role="dialog"
            aria-labelledby="dialog__title"
            aria-describedby="dialog__description"
            aria-modal="true"
          >
            <div className="dialog-v2__shadow" />
            <div className="dialog-v2__wrapper">
              <div className="dialog-v2__container --align-center">
                <div className="dialog-v2__header">
                  <h2 className="dialog-v2__title" id="dialog__title">
                    レンシュAIアシスタント(β)
                  </h2>
                  <div className="_switch">
                    <button
                      type="button"
                      className="button -appearance-transparent -color-neutral -shape-circle"
                      aria-label="閉じる"
                      onClick={() => setOpenModal(false)}
                    >
                      <div className="_leading">
                        <span className="icon _icon" data-icon="cross" />
                      </div>
                    </button>
                  </div>
                </div>
                <AnnouncementFormAIAssistantSection
                  form_action={form_action}
                  closeModal={() => setOpenModal(false)}
                  title={title}
                  body={body}
                  onReflection={onReflection}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
