const getCsrfToken: () => string = () => {
  return document.querySelector<HTMLMetaElement>('[name="csrf-token"]').content;
};

interface RequestAsJson {
  path: string;
  method: string;
}

interface RequestWithCsrfTokenParams {
  form_action: string;
  form_method: string;
  data?: Record<string, unknown>;
}

type ResponseAsJson = {
  response: Response;
};
export const requestAsJson = async <T>(
  { path, method }: RequestAsJson,
): Promise<T & ResponseAsJson> => {
  const response = await fetch(path, {
    method: method.toUpperCase(),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return { ...json, response };
};

export const requestWithCsrfToken = async <T>(
  { form_action, form_method, data = {} }: RequestWithCsrfTokenParams,
): Promise<T & ResponseAsJson> => {
  const csrfToken = getCsrfToken();
  const response = await fetch(form_action, {
    method: form_method.toUpperCase(),
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return { ...json, response };
};

export const post = async <T>(
  form_action: string,
  data: Record<string, unknown>,
) => {
  return await requestWithCsrfToken<T>({
    form_action,
    form_method: "POST",
    data,
  });
};

export const patch = async <T>(
  form_action: string,
  data: Record<string, unknown>,
) => {
  return await requestWithCsrfToken<T>({
    form_action,
    form_method: "PATCH",
    data,
  });
};
