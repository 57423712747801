import { format } from "date-fns";
import { ja } from "date-fns/locale";

type localizeDataValue = Date | string | number;
const localizeData = (dateOrString: localizeDataValue, fmt: string) =>
  format(new Date(dateOrString), fmt, { locale: ja });

export const localizeDateToYmdString = (dateOrString: localizeDataValue) =>
  localizeData(dateOrString, "yyyy年M月d日");
export const localizeDateToFullString = (dateOrString: localizeDataValue) =>
  localizeData(dateOrString, "yyyy年M月d日（E） HH:mm");
export const localizeDateToShortString = (dateOrString: localizeDataValue) =>
  localizeData(dateOrString, "M/d");
export const localizeDateToMiddleString = (dateOrString: localizeDataValue) =>
  localizeData(dateOrString, "Y-M-d");
export const localizeDateToYearMonthDayTime = (
  dateOrString: localizeDataValue,
) => localizeData(dateOrString, "Y-M-d kk:mm");
