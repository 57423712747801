import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as Sentry from "@sentry/browser";
import AIAssistantConfirmation from "./AIAssistantConfirmation";
import { useApi } from "../../hooks/api";

interface Inputs {
  title: string;
  input: string;
}

interface Props {
  form_action: string;
  closeModal: () => void;
  title: string;
  body: string;
  onReflection: (field: string, value: string) => void;
}

export default function AnnouncementFormAIAssistantSection(
  { form_action, closeModal, onReflection, title, body }: Props,
) {
  const [pollingUrl, setPollingUrl] = useState("");
  const [generatedBody, setGeneratedBody] = useState("");
  const [isPolling, setIsPolling] = useState(false);
  const { post } = useApi();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<Inputs>({
    defaultValues: {
      title: title,
      input: body,
    },
  });
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { polling_url, error, response } = await post(form_action, {
      ...data,
    });
    if (response.ok) {
      setPollingUrl(polling_url);
    } else {
      alert(error);
      Sentry.captureMessage(
        `お知らせのAIアシスタントのリクエストでエラーが発生しました。form_action: "${form_action}"`,
      );
    }
  };

  const onConfirm = (data) => {
    onReflection("title", data.title);
    onReflection("body", generatedBody);
    closeModal();
  };

  return (
    <form>
      <div className="form">
        <div className="form__section">
          <div className="form__item">
            <div className="form__control">
              <span className="textfield -appearance-filled -width-full">
                <input
                  {...register("title", { required: true })}
                  id="announcement-generator-title"
                  className="_input"
                  placeholder="タイトル"
                  required
                />
                <label
                  className="_label"
                  htmlFor="announcement-generator-title"
                >
                  タイトル
                </label>
              </span>
              {errors.title && (
                <div
                  className="form__error"
                  aria-live="polite"
                >
                  {errors.title.message}
                </div>
              )}
            </div>
          </div>

          <div className="form__item">
            <div className="form__control">
              <span className="textfield -appearance-filled -width-full --textarea-middle">
                <textarea
                  {...register("input", { required: true })}
                  id="announcement-generator-input"
                  className="_input"
                  rows={5}
                  placeholder="内容の要点"
                  required
                  maxLength={2000}
                />
                <label
                  className="_label"
                  htmlFor="announcement-generator-input"
                >
                  内容の要点
                </label>
              </span>
              <div
                className="form__error"
                aria-live="polite"
              />
            </div>
          </div>
        </div>
      </div>

      {pollingUrl && (
        <AIAssistantConfirmation
          pollingUrl={pollingUrl}
          onSubmit={handleSubmit(onSubmit)}
          generatedBody={generatedBody}
          setGeneratedBody={(body: string) => setGeneratedBody(body)}
          isPolling={isPolling}
          setIsPolling={(isPolling: boolean) => setIsPolling(isPolling)}
        />
      )}

      <div className="dialog-v2__description" id="dialog__description">
        {!pollingUrl && (
          <>
            <div className="call-out">
              <div className="call-out__body">
                <ul>
                  <li className="call-out__description -size-xs">
                    タイトルと内容を入力するとお知らせ文が自動生成されます
                  </li>
                  <li className="call-out__description -size-xs">
                    内容は箇条書きやキーワードを3つほど書くだけでも生成できますが、情報量が多いほどAIが具体的な文章を作りやすくなります
                  </li>
                </ul>
                <div className="call-out__description -size-xs">
                  ※この機能はβ版のため、事前予告なしに機能の変更や提供を中止することがあります。
                </div>
              </div>
            </div>
            <div className="dialog-v2__link">
              <a
                href="https://renshu.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                GMOレンシュ利用規約 第9章［第21条の2〜第21条の5］
              </a>
            </div>
          </>
        )}
      </div>

      <div className="dialog-v2__action --border-top">
        <ul className="button-group">
          <li className="_item">
            <button
              type="button"
              className="button -appearance-transparent -size-m -color-neutral"
              onClick={closeModal}
            >
              <div className="_body">
                キャンセル
              </div>
            </button>
          </li>

          <li className="_item">
            <button
              type="submit"
              className="button -size-m -color-interactive"
              disabled={isSubmitting || isPolling}
              onClick={handleSubmit(generatedBody ? onConfirm : onSubmit)}
            >
              <div className="_body">
                {pollingUrl ? "下書きに反映" : "規約に同意して作成"}
              </div>
            </button>
          </li>
        </ul>
      </div>
    </form>
  );
}
