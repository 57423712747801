import React, { useMemo } from "react";
import crypto from "crypto";

type Props = IconAvatar | InitialAvatar;

type IconAvatar = BaseAvatar & {
  name?: string;
  icon: true;
};

type InitialAvatar = BaseAvatar & {
  icon?: false;
  name: string;
};

interface BaseAvatar {
  size: "s" | "m" | "l";
}

export default function TextAvatar({ icon, name, size }: Props): JSX.Element {
  const colorClassName = useMemo(() => {
    const hash = crypto.createHash("md5");
    hash.update(name ? name[0] : "r", "utf8");
    const num = BigInt("0x" + hash.digest("hex"));
    const variant = num % BigInt(10);
    return `-color-${variant}`;
  }, [name]);

  return (
    <div
      className={`text-avatar -size-${size} ${colorClassName}`}
    >
      {icon
        ? (
          <div className="_icon">
            <span className="icon" data-icon="person" />
          </div>
        )
        : name[0]}
    </div>
  );
}
