import React from "react";

export default function Tabs(
  {
    activeTab,
    setActiveTab,
    classroom,
  },
): JSX.Element {
  const handleTabClick = (e, tabName) => {
    e.stopPropagation();

    setActiveTab(tabName);
  };

  return (
    <nav className="tab tab-nav-contents">
      <ul className="_list">
        <li
          className={activeTab == "summary" ? "_item --current" : "_item"}
          onClick={(e) => handleTabClick(e, "summary")}
        >
          <a href="#summary">概要</a>
        </li>

        <li
          className={activeTab == "courses" ? "_item --current" : "_item"}
          onClick={(e) => handleTabClick(e, "courses")}
        >
          <a href="#courses">コース</a>
        </li>

        {classroom.policyCanNewManagementClassroomsSettingsSubscriptions && (
          <li
            className={activeTab == "config" ? "_item --current" : "_item"}
            onClick={(e) => handleTabClick(e, "config")}
          >
            <a href="#config">設定</a>
          </li>
        )}
      </ul>
    </nav>
  );
}
