import React from "react";
import { CustomField } from "../ManagementCustomFieldsPage";
import { useFieldArray, useForm } from "react-hook-form";
import { useApi } from "../../hooks/api";

interface Props {
  customField: CustomField;
  create_custom_field_path: string;
  commitEditing: (customField: CustomField) => void;
  exitEditing: () => void;
  showMessage: (message: string) => void;
}

export default function ManagementCustomFieldsItem(props: Props): JSX.Element {
  const { name, field_type, options: defaultOptions } = props.customField;
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<CustomField>({
    defaultValues: { name, field_type, options: defaultOptions },
    mode: "all",
  });
  const { fields: options, append, remove } = useFieldArray({
    control,
    name: "options",
  });
  const { post, patch } = useApi();

  const onSubmit = async (data) => {
    let result: {
      custom_field?: CustomField;
      message?: string;
      response?: Response;
    };
    if (props.customField.id) {
      result = await patch(
        props.customField.update_custom_field_path,
        {
          custom_field: data,
        },
      );
    } else {
      result = await post(
        props.create_custom_field_path,
        {
          custom_field: data,
        },
      );
    }
    if (result.response.ok) {
      props.commitEditing(result.custom_field);
    }
    props.showMessage(result.message);
  };

  return (
    <>
      <tr className="--edit">
        <td>
          <form onSubmit={handleSubmit(onSubmit)} className="form _form">
            <div className="simple-textfield -appearance-filled -width-full">
              <input
                {...register("name", {
                  required: "項目名を入力してください",
                  maxLength: {
                    value: 140,
                    message: "項目名は140文字以内で入力してください",
                  },
                })}
                className="_input"
                placeholder="項目名"
              />
            </div>
            {errors.name && (
              <div className="form__error" aria-live="polite">
                {errors.name.message}
              </div>
            )}
          </form>
        </td>
        <td>
          <form onSubmit={handleSubmit(onSubmit)} className="form _form">
            <div className="-type">
              <span className="-type__select">
                <select
                  className="_select"
                  {...register("field_type", {
                    pattern: {
                      value: /^(free_text|single_select|multi_select)$/,
                      message: "不正な形式です",
                    },
                  })}
                >
                  <option value="free_text">自由記述</option>
                  <option value="single_select">選択</option>
                </select>
                <span className="_icon" />
              </span>
            </div>
            {errors.field_type && (
              <div className="form__error" aria-live="polite">
                {errors.field_type.message}
              </div>
            )}
            {["single_select", "multi_select"].includes(watch("field_type")) &&
              (
                <div className="custom-select__list">
                  {options.map((option, index) => (
                    <div
                      key={index}
                      className="simple-textfield -appearance-filled -width-full _item"
                    >
                      <input
                        {...register(`options.${index}.id`)}
                        type="hidden"
                      />
                      <div className="custom-select__item">
                        <input
                          {...register(`options.${index}.option_value`, {
                            required: "選択肢を入力してください",
                            maxLength: {
                              value: 255,
                              message: "255文字以内で入力してください",
                            },
                          })}
                          className="_input"
                          placeholder="選択肢"
                        />
                        <button
                          type="button"
                          aria-label="選択肢の削除"
                          className="button -appearance-transparent -size-m -color-negative"
                          onClick={() => remove(index)}
                        >
                          <div className="_leading">
                            <span className="icon _icon" data-icon="cross" />
                          </div>
                        </button>
                      </div>
                      {errors.options?.[index] && (
                        <div className="form__error" aria-live="polite">
                          {errors.options[index].option_value.message}
                        </div>
                      )}
                    </div>
                  ))}
                  <button
                    type="button"
                    className="button -appearance-transparent -size-m -color-interactive"
                    onClick={() => append({ id: undefined, option_value: "" })}
                  >
                    <div className="_leading">
                      <span className="icon" data-icon="plus"></span>
                    </div>
                    <div className="_body">
                      選択肢を追加
                    </div>
                  </button>
                </div>
              )}
            <div className="_control">
              <button
                type="button"
                className="button -size-s"
                onClick={props.exitEditing}
              >
                <div className="_body">
                  キャンセル
                </div>
              </button>
              <button
                type="submit"
                className="button -size-s -color-interactive"
                disabled={isSubmitting}
              >
                <div className="_body">
                  保存
                </div>
              </button>
            </div>
          </form>
        </td>
        <td />
      </tr>
    </>
  );
}
